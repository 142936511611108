import React from 'react';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import DisplayAsset from '../../components/assets/display_asset';
import { NHAssetType, IPortalPropertyAsset, IPortalPropertyAssetObj} from '../../interfaces/asset';
import { getClientFormattedDate } from '../../utils/date';
import cx from 'classnames';
import ProfileCompletenessText from '../../components/profile_completeness/profile_completeness_text';
import { ProfileScoreType } from '../../interfaces/property';

interface IProps {
    handleSelNHID: (NHID: number | null) => void;
    orgNHID: number | null;    
    profileCompletenessFilterValue: boolean | null;
    propertyVideos: IPortalPropertyAsset[];
    busyLoadingAsset: boolean;
}

const MultiOrgPropertyVideos = ({
    handleSelNHID,
    orgNHID,
    propertyVideos,
    busyLoadingAsset
}: IProps) => {

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const isOrgVideoExists: boolean = (propertyVideos && propertyVideos[0]?.Asset || []).length > 0 ? true : false;

    const renderMultiPropertiesVideos = () => {
        return (
            <>

            <div className="widget">
                <div className="widget-body">
                {
                    propertyVideos?.map((property: IPortalPropertyAsset) => {

                        const { WebsiteURL, StateSlug, CitySlug, Slug } = property.Property;
                        const propertyIsAnOrg = orgNHID && orgNHID === property.NHID;
                        const url = propertyIsAnOrg ? `${WebsiteURL}/provider/${Slug}` : 
                            `${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`;

                        return (
                            <div key={property.Property.Name} className="card" data-card="Media Scroller">
                                <div className="card-header">
                                    <span>
                                    <h4>
                                        {property.Property.Name}

                                            {
                                             !propertyIsAnOrg ? 
                                                <>
                                                    <small className="ms-2">{property.Property.Address}</small>&nbsp;
                                                </>: null
                                            }
                                            <a
                                                className="ms-2 small text-link text-nowrap"
                                                href={url}
                                                target="_blank" 
                                            >
                                                view on website
                                            </a>    
                                    </h4>
                                        {
                                             !propertyIsAnOrg ? 
                                             <>
                                                <ProfileCompletenessText
                                                    NHID={property.Property.NHID}
                                                    profileScoreType={ProfileScoreType.Video}
                                                /> 
                                            </>: null
                                        }
                                    </span>
                                    <div className="card-toolbar">
                                        <button 
                                            key='update-property-video-${property.Property.NHID}'
                                            className="btn btn-primary text-nowrap"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleSelNHID(property.Property.NHID);
                                            }}
                                        >
                                                {property.Asset.length > 0 ? 'Update Video' : 'Add Video'}
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="card-info no-scroll">
                                        <div>
                                        {
                                            property.Asset.length > 0 ?
                                                property.Asset.map((video:IPortalPropertyAssetObj) => {
                                                    return (
                                                        <div key={video.AssetID} className="media-edit media-video">
                                                            <DisplayAsset
                                                                TransformedAssetUrl={video.TransformedAssetUrl}
                                                                FileName={video.FileName}
                                                                AssetType={NHAssetType.Videos} 
                                                            />
                                                            <div>
                                                                <span>
                                                                    Date uploaded: {video.CreatedDate ? getClientFormattedDate(video.CreatedDate) : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            :
                                                <span className={cx('msg', isOrgVideoExists ? 'msg-info' : 'msg-error')}>
                                                    {isOrgVideoExists && orgNHID !== property.NHID ?
                                                        'The uploaded Provider video will show on this Facility page'
                                                    :
                                                        ' No video uploaded'
                                                    }
                                                </span>
                                        }
                                        </div>
                                        {   
                                            propertyIsAnOrg ?
                                                <div className="mt-4">
                                                    <span className="msg msg-info">
                                                    { isOrgVideoExists ? 
                                                        'This Provider video will show on all Facility pages where no Facility video has been uploaded'
                                                    :
                                                        'If a Provider video is uploaded it will show on all Facility pages where no Facility video has been uploaded'

                                                    }
                                                    </span>
                                                </div>
                                            :
                                                ''
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
            </>
        );
    };

    return sessionState.Org || (sessionState.Properties || []).length > 1 
        ? 
            busyLoadingAsset ? 
                <LoadingSpinner show={true} /> 
            : 
                renderMultiPropertiesVideos() 
        : null;
};

export default MultiOrgPropertyVideos;