import { SessionType, PropertyMode } from '../interfaces/session';

export const clamp = (num: number, min: number, max: number) =>
    Math.min(Math.max(num, min), max);

export const getSingleProperty = (
    sessionType: SessionType,
    nhID: number,
    properties: any[]
) => {
    if (nhID) {
        const singleMember = (properties || []).find(
            (property: any) => property.NHID === nhID
        );
        return singleMember;
    } else if (
        sessionType === SessionType.SessionTypeProperty &&
        properties.length === 1
    ) {
        return properties[0];
    }

    return null;
};

export const getCommunityMode = (
    groupID: number,
    nhIDs: number[],
    selNHID: number
): PropertyMode => {
    const singleCommunity =
        selNHID || ((nhIDs || []).length === 1 ? nhIDs[0] : null);

    return singleCommunity
        ? PropertyMode.SingleProperty
        : groupID
        ? PropertyMode.Org
        : nhIDs.length > 1
        ? PropertyMode.MultiProperty
        : PropertyMode.None;
};

export const isDefined = (val: any) => {
    return typeof val !== 'undefined' && val !== null;
};

export const timerStart = () => Date.now();
export const timerStop = (
    startTime: number,
    message: string = null,
    durationFormat: string = null
): number => {
    durationFormat = durationFormat || 'msecs';
    if (!['mins', 'secs', 'msecs'].includes(durationFormat)) {
        console.log('timerStop: Invalid duration format');
        return;
    }

    const durationMsecs = Date.now() - startTime;
    let duration = durationMsecs;

    switch (durationFormat) {
        case 'mins':
            duration = durationMsecs / (1000 * 60);
            break;
        case 'secs':
            duration = durationMsecs / 1000;
            break;
        case 'msecs':
        default:
            duration = durationMsecs;
            break;
    }

    if (message) {
        console.log(`${message} took ${duration.toFixed(2)} ${durationFormat}`);
    }

    return duration;
};

export const intersectionObserverAvailable = (): boolean => {
    return (
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
        'isIntersecting' in window.IntersectionObserverEntry.prototype
    );
};

export const scrollToTop = () => {
    const id = 'page_template_top';
    const yOffset = 0; //-120;
    const element = document.getElementById(id);
    if (element) {
        const y =
            element.getBoundingClientRect().top /*+ window.pageYOffset*/ +
            yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
};

export const arrayFindRecursive = (
    array: any[],
    key: string,
    value: string | number | boolean
) => {
    let item: any;

    const iter = (i: any) => {
        if (i[key] === value) {
            item = i;
            return true;
        }

        return Array.isArray(i.Children) && i.Children.some(iter);
    };

    array.some(iter);

    return item;
};

export const ordinalSuffix = (num: number): string => {
    if (isNaN(num)) {
        throw new Error(`ordinalSuffix: Invalid data - ${num}`);
    }

    const suffix =
        ['st', 'nd', 'rd'][((((num + 90) % 100) - 10) % 10) - 1] || 'th';

    return `${num}${suffix}`;
};

export const getWebsiteUrlDomain = (websiteUrl: string): string => {
    if (!websiteUrl.trim().length) {
        return '';
    }

    return websiteUrl.substring(websiteUrl.indexOf('.') + 1);
};

//Function will return difference between shared parameters between a and b. Will priorities entiries in b
export const getObjectDifferenceWhenSameParams = (a: any, b: any) => {
    return Object.entries(b)
        .filter(([key, val]) => a[key] !== val && key in a)
        .reduce((a, [key, v]) => ({ ...a, [key]: v }), {});
};

//Function will return differences between two object and if they share the same key but different values then
//it will return the object b value
export const getObjectDifference = (a: any, b: any) => {
    const diff = [...Object.keys(a), ...Object.keys(b)].reduce(
        (acc: any, key: any) => {
            if (a[key] !== b[key]) {
                acc[key] = b[key] || a[key];
            }
            return acc;
        },
        {}
    );
    return diff;
};

export const isObjectEmpty = (objectName: object) => {
    return Object.keys(objectName).length === 0;
};

export const numberRange = (start: number, stop: number, step: number) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
    );

export const generalSort = (prop: string, dir: 'ASC' | 'DESC' = 'ASC') => {

    const isDescending = dir === 'DESC' ? -1 : 1;

    return ((a: any, b: any) => {
        
        const valueA = a[prop];
        const valueB = b[prop];

        if (valueA === null && valueB === null) {
            return 0;
        } else if (valueA === null) {
            return -isDescending;
        } else if (valueB === null) {
            return isDescending;
        }
        if (valueA < valueB) {
            return -isDescending;
        }
        if (valueA > valueB) {
            return isDescending;
        }
        return 0;
    });
};

export const isBoolean = (val: any) => val === false || val === true;

export const textSort = (key: string, order: 'ASC' | 'DESC') => {

    if (order === 'ASC') {
        return (a: any, b: any) => {
            return (a[key] || '').toUpperCase() < (b[key] || '').toUpperCase() 
                    ? -1 : (a > b) ? 1 : 0;
        };
    }

    return (a: any, b: any) => {
        return (a[key] || '').toUpperCase() > (b[key] || '').toUpperCase() 
                ? -1 : (a > b) ? 1 : 0;
    };
    
}; 

export const boolSort = (key: string, order: 'ASC' | 'DESC') => {

    if (order === 'ASC') {
        return (a: any, b: any) => a[key] - b[key];
    }

    return (a: any, b: any) => b[key] - a[key];  
}; 

