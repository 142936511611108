import React, { useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import MultiOrgPropertyVideos from '../../components/assets/multi_org_property_videos';
import IndividualPropertyVideos from '../../components/assets/individual_property_videos';
import { useLocation } from 'react-router-dom';
import { ISessionState, SessionType } from '../../interfaces/session';
import { IPortalPropertyAsset } from '../../interfaces/asset';
import { useSelector } from 'react-redux';
import * as assetApi from '../../api/assets';
import ProfileCompletenessVideoHeader from '../../components/profile_completeness/profile_completeness_video_header';
import { buildProfileCompleteMapForNHIDs } from '../../utils/profile_completeness';
import { INhPortalScoreItem } from '../../interfaces/property';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const PropertyVideos = ({ nhIDs, orgNHID }: IProps) => {
    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const [busyLoadingAsset, setBusyLoadingAsset] = useState<boolean>(true);
    const [selNHID, setSelNHID] = useState<number>(defaultNHID);
    const [propertyVideos, setPropertyVideos] = useState<any[]>([]);
    const [profileCompletenessFilterValue, setProfileCompletenessFilterValue] = useState<boolean | null>(null);
    const [showWithNoVideoFilter, setShowWithNoVideoFilter] = useState<boolean>(false);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const { SessionType: sessionType } = Session;

    const loadPropertyVideos = async () => {
        if (!selNHID) {
            setPropertyVideos(null);
            setBusyLoadingAsset(true);
            try {
                const propertyVideos = sessionType === SessionType.SessionTypeOrg
                    ? await getOrgPropertiesVideos()
                    : await getPropertiesVideos();

                    setPropertyVideos(propertyVideos);
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        } else {
            setBusyLoadingAsset(false);
        }
    };

    useEffect(() => {
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        if (singleNHID) {
            setSelNHID(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
        if (!selNHID) {
            loadPropertyVideos();
        }

        setBusyLoadingAsset(true);
        (async () => {
            try {
                if (selNHID && selNHID !== orgNHID) {
                    const propertyVideos = await getPropertyVideos(selNHID);
                    if (propertyVideos) {
                        setPropertyVideos(propertyVideos);
                    }
                } 
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingAsset(false);
            }
        })();
    }, [selNHID]);

    useEffect(() => {
        loadPropertyVideos();


    }, [profileCompletenessFilterValue, showWithNoVideoFilter]);

    const getOrgPropertiesVideos = async (): Promise<IPortalPropertyAsset[]> => {
        if (!orgNHID) {
            console.error('getOrgPropertiesVideos: Invalid orgNHID');
            return null;
        }

        const filters = new Map<string, any>();
        if (showWithNoVideoFilter) {
            filters.set('LowResPhotos', showWithNoVideoFilter);
        }
        const videoData = await assetApi.getOrgPropertiesVideos(orgNHID, filters);
        return filterByProfileCompleteness(videoData);
    };

    const filterByProfileCompleteness = (videoData: IPortalPropertyAsset[]) => {

        if (profileCompletenessFilterValue === null) {
            return videoData;
        }

        const profileMap = buildProfileCompleteMapForNHIDs(profileCompletenessState);

        return videoData.filter((item: IPortalPropertyAsset) => {
            const profileCompleteItem = profileMap.get(item.NHID);
            return profileCompleteItem?.VideoComplete === profileCompletenessFilterValue;
        });
    };

    const getPropertiesVideos = async (): Promise<IPortalPropertyAsset[]> => {
        const filters = new Map<string, any>();

        // if (showWithNoVideoFilter) {
        //     filters.set('showWithNoVideoFilter', showWithNoVideoFilter);
        // }
        const videoData = await assetApi.getPropertiesVideos(nhIDs, filters);
        return filterByProfileCompleteness(videoData);
    };

    const getPropertyVideos= async ( nhID: number ): Promise<IPortalPropertyAsset[]> => {
        const videoData = await assetApi.getPropertiesVideos([nhID]);
        return filterByProfileCompleteness(videoData);
    };

     return (
        <> 
            <PageCommunitySelectWrapper
                label={'All Videos for'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />
            <div className="widget" data-widget-height="auto">
                <div className="widget-body"> 
                    <ProfileCompletenessVideoHeader
                        selNHID={selNHID}
                        nhIDs={nhIDs}
                        orgNHID={orgNHID}
                        handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                        handleNoVideoFilter={setShowWithNoVideoFilter}
                        showWithNoVideo={showWithNoVideoFilter}
                        profileCompletenessFilterValue={profileCompletenessFilterValue}
                    />
                </div>
            </div>
            {
                nhIDs?.length !== 1 && !selNHID ?
                    <MultiOrgPropertyVideos
                        handleSelNHID={setSelNHID}
                        orgNHID={orgNHID}
                        profileCompletenessFilterValue={profileCompletenessFilterValue}
                        propertyVideos={propertyVideos}
                        busyLoadingAsset={busyLoadingAsset}
                    />
                :
                    null
            }
            {
                nhIDs?.length === 1 || selNHID ?
                    <IndividualPropertyVideos
                        selNHID={selNHID}
                        propertyVideos={propertyVideos}
                        setPropertyVideos={setPropertyVideos}
                        busyLoadingAsset={busyLoadingAsset}
                        setBusyLoadingAsset={setBusyLoadingAsset}
                    />
                :
                    null
            }
        </>
    );
};

export default PropertyVideos;