import _ from 'lodash';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import * as csvEportAPI from '../../api/csv_export';
import { getUsers } from '../../api/user';
import ActiveFilters from '../../components/common/active_filters';
import DataTable, { INITIAL_ROWS_PER_PAGE } from '../../components/common/data_table';
import { LoadingMessage } from '../../components/common/loading_message';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import NoUserData from '../../components/users/no_user_data';
import UserTableExpandableRows from '../../components/users/user_table_expandable_rows';
import { FilterType } from '../../interfaces/filters';
import { ISelectOption } from '../../interfaces/form';
import { ISessionState } from '../../interfaces/session';
import { IPortalUser } from '../../interfaces/user';
import FilterMatrix from '../../modules/filter_matrix';
import { scrollToTop } from '../../utils/common';
import { longFormatDateTime } from '../../utils/date';
import { formatFileNameForCsvExport } from '../../utils/file';
import { SessionType } from '../../interfaces/session';

import AppContext from '../../context/app_context';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const createFilters = () => {
    return new FilterMatrix([
        {
            Key: 'FirstName', 
            FilterFormLabel: 'Filter by First Name', 
            Type: FilterType.String, 
            FilterPropName: 'FirstName'
        },
        {
            Key: 'LastName', 
            FilterFormLabel: 'Filter by Last Name', 
            Type: FilterType.String, 
            FilterPropName: 'LastName'
        },
        {
            Key: 'Permissions', 
            FilterFormLabel: 'Filter by Permissions', 
            Type: FilterType.LabelValue, 
            FilterPropName: 'Permissions'
        },
        {
            Key: 'EmailAddress', 
            FilterFormLabel: 'Filter by Email Address', 
            Type: FilterType.String, 
            FilterPropName: 'EmailAddress'
        },
        {
            Key: 'LastLoginFrom', 
            FilterFormLabel: 'Filter by last login from', 
            Type: FilterType.DateFromUTC, 
            FilterPropName: 'LastLoginFrom'
        },
        {
            Key: 'LastLoginTo', 
            FilterFormLabel: 'Filter by last login to', 
            Type: FilterType.DateToUTC, 
            FilterPropName: 'LastLoginTo'
        }
    ]);
};

const permissionOptions: ISelectOption[] = [{
    label: 'Reply to Reviews',
    value: 'CanReplyToReview'
}, {
    label: 'Manage Users asdfdsa',
    value: 'CanManageUsers'
}, {
    label: 'Review Notifications',
    value: 'CanReceiveNotifications'
},
{
    label: 'Review Card Order Primary Contact',
    value: 'IsReviewCardPrimaryContact'
},
{
    label: 'Primary Contact',
    value: 'IsPrimaryContact'
},
{
    label: 'Primary Subscription Contact',
    value: 'IsPrimarySubscriptionContact'
}

];

const UserList = ({ nhIDs, orgNHID }: IProps) => { 
    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );
    
    const [, updateState] = useState<any>();
    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ users, setUsers ] = useState<IPortalUser[]>([]);
    const [ totalUserCount, setTotalUserCount ] = useState<number>(0);
    const [ pageOffset, setPageOffset ] = useState<number>(0);
    const [ pageLimit, setPageLimit ] = useState<number>(INITIAL_ROWS_PER_PAGE);
    const [ resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);
    const [filterMatrix ] = useState(createFilters());

    const canManageUsers = _.get(sessionState, 'Session.Perms.CanManageUsers', false);
    const getDataDebounced = useCallback(_.debounce(() => getUserData(), 500), []);

    useEffect(() => {
        setResetPaginationToggle(!resetPaginationToggle);
        setPageOffset(0);
        getUserData();
    }, [nhIDs, orgNHID, pageLimit, selNHID]);

    useEffect(() => {
        getUserData();
    }, [pageOffset]);

    const appContext: any = useContext(AppContext);

    const buildColumns = () => {
        return [
            {
                name: 'User ID',
                selector: (row: IPortalUser) => row.UserID,
                sortable: true,
                id: 'UserID',
                width: '100px'
            },
            {
                name: 'Name (Job Title)',
                cell: (row: IPortalUser) => <p className="break-words">
                    {row.FirstName} {row.LastName}
                    {row.JobTitle?.trim() ? <>&nbsp;<em>({row.JobTitle})</em></>: null}
                </p>,
                selector: (row: IPortalUser) => row.FirstName.toLowerCase(),
                sortable: true,
                id: 'Name',
                width: '250px'
            },
            {
                name: 'User Email',
                cell: (row: IPortalUser) => row.EmailAddress,
                selector: (row: IPortalUser) => row.EmailAddress.toLowerCase(),
                sortable: true,
                id: 'EmailAddress',
                width: 'auto'
            },
            {
                name: 'Linked to Org/Facility',
                cell: (row: IPortalUser) => formatOrgPropertyColumn(row),
                id: 'LinkToOrg/Facility',
                width: appContext.isMobile ? '300px' : 'auto'
            },
            {
                name: 'Permissions',
                cell: (row: IPortalUser) => formatPermissionsColumn(row),
                id: 'Permissions',
                width:  '450px'
            },
            {
                name: 'Last Login Date',
                selector: (row: IPortalUser) => new Date(row.LastLoginDate).getTime(),
                cell: (row: IPortalUser) => formatLastLoginDateColumn(row),
                sortable: true,
                id: 'LastLoginDate',
                width: appContext.isMobile ? '200px' : '150px'
            }
        ];
    };

    const formatPermissionsColumn = (row: IPortalUser) => {
        const {
            CanReplyToReview,
            CanManageUsers,
            CanReceiveNotifications,
            IsReviewCardPrimaryContact,
            IsPrimaryContact,
            IsPrimarySubscriptionContact
        } = row;
  

        return (
            <>  
                    <div className='permission-label-container'>
                    { CanReplyToReview ?  <div className="permission-label__reviews"> Reply to Reviews </div>: null}
                    { CanReceiveNotifications ?  <div className="permission-label__notifications"> Review Notifications </div>: null}
                    { CanManageUsers ?  <div className="permission-label__users"> Manage Users </div>: null}
                    { IsReviewCardPrimaryContact ?  <div className="permission-label__reviewcardprimary"> Review Card Contact </div>: null}
                    { IsPrimaryContact ?  <div className="permission-label__primarycontact"> Primary Contact </div>: null}
                    { IsPrimarySubscriptionContact ?  <div className="permission-label__primarysubscriptioncontact">Subscription Primary Contact</div>: null}
                    </div>
            </>
        );
    };

    const formatLastLoginDateColumn = (row: IPortalUser) => { 

        if (row.LastLoginDate) {
            return <div>{longFormatDateTime(row.LastLoginDate)}</div>;
        }

        if (!row.HasActivated) {
            return <div>Not Yet Activated</div>;
        }
        
        return <div>-</div>;
    };

    const formatOrgPropertyColumn = (row: IPortalUser) => {
        const { KeyName, Entities } = row;
        if (!Entities.length) {
            return null;
        }

        if (KeyName === SessionType.SessionTypeOrg) {
            return  (
                <>
                    { Entities[0].EntityName  }(Org)
                </>
            );
        } else if (KeyName === SessionType.SessionTypeProperty && Entities.length === 1) {
            return (
                <>
                    { Entities[0].PropertyName }
                </>
            );
        }else if (KeyName === SessionType.SessionTypeProperty && Entities.length > 1) {
            const facilityList = Entities.map((entity) =>{
                return entity.PropertyName;
            });
            return (
                <>  
                    { facilityList.join(', ') }
                </>
            );
        }
    };


    const columns: any = buildColumns();

    if (canManageUsers) {
        columns.push({
            name: 'Edit User',
            cell: ({ UserID }: IPortalUser) => {
                return (
                    <Link to="/users/edit" state={{ UserID }}>
                        <button className="btn btn-sm btn-primary">Edit</button>
                    </Link>
                    
                );
            },
            id: 'editbutton',
            width: '100px'
        });
    }

    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);
        forceUpdate();

        if (refreshData) {
            getDataDebounced();
        }
    };

    const resetFilters = () => {
        filterMatrix.reset();
        getUserData();
    };
    
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    const getParams = () => {
        const params: any = {
            ...filterMatrix.getRequestParams(),
            NHID: selNHID,
            Offset: pageOffset,
            Limit: pageLimit
        };
        
        if (params.Permissions) {
            params.Permissions.split(',').forEach((p: string) => params[p] = true); 
            delete params.Permissions;
        }

        return params;
    };

    const getUserData = async () => {
        const params = getParams();

        setIsLoading(true);

        const data = await getUsers(params);

        const rowData = _.get(data, 'data', []);
        const totalRows = _.get(data, 'totalRows', 0);
    
        setUsers(rowData);
        setTotalUserCount(totalRows);
        setIsLoading(false);
    };

    const onPageChange = async (pageNumber: number) => {
        const newPageOffset = (pageNumber - 1) * pageLimit;

        if (newPageOffset !== pageOffset) {
            setPageOffset(newPageOffset);
            scrollToTop();
        }
    };

    const onLimitChange = async (newPerPage: number, pageNumber: number) => {
        if (newPerPage !== pageLimit) {
            setPageOffset((pageNumber - 1) * pageLimit);
            setPageLimit(newPerPage);
            scrollToTop();
        }
    };

    const startExport = async (): Promise<string> => {
        const params = getParams();

        return csvEportAPI.startExport('/user/export', params);
    };

    const buildLoadingMessage = () => {
        return 'Fetching users - see results below';
    };

    return (
        <>
             <PageCommunitySelectWrapper
                label={'Show users who have access to:'}
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            <div className="widget widget-table" data-widget-header="1" data-widget-footer="0">
                <div className="widget-body">
                    <div className="card">
                        <div className="card-body p-0">
                            <Accordion className="filters">
                                <Accordion.Item className="border-top-0" eventKey="0">
                                    <Accordion.Header className="accordion-header">Filters</Accordion.Header>
                                    <Accordion.Body className="accordion-body p-0">
                                        <div className="p-4">
                                            <div className="row mb-4">
                                                <div className="col-sm-3">
                                                    <label htmlFor="FirstName">First Name</label>
                                                    <input
                                                        type="text"
                                                        id="FirstName"
                                                        className="form-control"
                                                        onChange={(e) => applyFilter('FirstName', e.target.value)}
                                                        onKeyUp={(e: any) => e.keyCode === 13 ? applyFilter('FirstName', e.target.value) : ''}
                                                        value={filterMatrix.getFormFieldValue('FirstName')}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label htmlFor="LastName">Last Name</label>
                                                    <input
                                                        type="text"
                                                        id="LastName"
                                                        className="form-control"
                                                        onChange={(e) => applyFilter('LastName', e.target.value)}
                                                        onKeyUp={(e: any) => e.keyCode === 13 ? applyFilter('LastName', e.target.value) : ''}
                                                        value={filterMatrix.getFormFieldValue('LastName')}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label htmlFor="LastName">Email Address</label>
                                                    <input
                                                        type="email"
                                                        id="EmailAddress"
                                                        className="form-control"
                                                        onChange={(e) => applyFilter('EmailAddress', e.target.value)}
                                                        onKeyUp={(e: any) => e.keyCode === 13 ? applyFilter('EmailAddress', e.target.value) : ''}
                                                        value={filterMatrix.getFormFieldValue('EmailAddress')}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label htmlFor="Permissions">Permissions</label>
                                                    <Select
                                                        id="Permissions"
                                                        onChange={(e) => applyFilter('Permissions', e)}
                                                        options={permissionOptions}
                                                        isMulti
                                                        name="Permissions"
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        value={filterMatrix.getFormFieldValue('Permissions')}
                                                    />
                                                </div>                        
                                            </div>

                                            <div className="row mb-4 align-items-center">
                                                <div className="col-sm-3">
                                                    <p>Last Login From</p>
                                                    <DatePicker
                                                        dateFormat="MMMM d, yyyy"
                                                        onChange={(date: any) => {
                                                            if (date) {
                                                                date.setHours(0, 0, 0, 0);
                                                            }
                                                            applyFilter('LastLoginFrom', date);
                                                        }}
                                                        maxDate={filterMatrix.getFormFieldValue('LastLoginTo') || new Date()}
                                                        selected={filterMatrix.getFormFieldValue('LastLoginFrom')}
                                                        className="form-control"
                                                        placeholderText="Click to select a date"
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <p>Last Login To</p>
                                                    <DatePicker
                                                        dateFormat="MMMM d, yyyy"
                                                        onChange={(date: any) => {
                                                            if (date) {
                                                                date.setHours(23, 59, 59, 999);
                                                            }
                                                            applyFilter('LastLoginTo', date);
                                                        }}
                                                        minDate={filterMatrix.getFormFieldValue('LastLoginFrom')}
                                                        maxDate={new Date()}
                                                        selected={filterMatrix.getFormFieldValue('LastLoginTo')}
                                                        className="form-control"
                                                        placeholderText="Click to select a date"
                                                    />
                                                </div>
                                            </div> 

                                            <div className="row">
                                                <div className="d-flex justify-content-end">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={resetFilters}>Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <div className="table-info p-4 pb-4">
                                <div className="row align-items-center">
                                    <div className="col-xl-6 mb-4 mb-xl-0">
                                        <ActiveFilters 
                                            filterMatrices={[filterMatrix]} 
                                            totalFilteredSize={totalUserCount}
                                            isLoading={isLoading}
                                            pageOffset={pageOffset}
                                            pageLimit={pageLimit}
                                            singularNoun={'User'}
                                            pluralNoun={'Users'}
                                            showTotals={false}
                                            applyFilter={applyFilter}
                                        />
                                    </div>
                                    <div className="col-xl-6 text-center text-xl-end">
                                        <CsvExport 
                                            startExportFunction={startExport}
                                            modalTitleSuffix={'Users'}
                                            label="Export as CSV"
                                            displayType={DisplayType.Button}
                                            fileName={`${formatFileNameForCsvExport('user_export')}`}
                                        />
                                        <Link to="/users/add" className="ms-4">
                                            <button className="btn btn-sm btn-primary">Add User</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="users_dt sticky_dt table_dt expander_dt">

                                {  isLoading ? <LoadingMessage message={buildLoadingMessage()} /> : null } 

                                <DataTable
                                    columns={columns}
                                    data={users}
                                    expandableRows={true}
                                    expandableRowsComponent={UserTableExpandableRows}
                                    pagination={true}
                                    paginationServer={true}
                                    paginationTotalRows={totalUserCount}
                                    onChangePage={onPageChange}
                                    onChangeRowsPerPage={onLimitChange}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                    fixedHeader={false}
                                    noDataComponent={<NoUserData loading={isLoading} />}
                                    defaultExpanded={false}
                                    defaultExpandedIDProp={'UserID'}
                                /> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserList;