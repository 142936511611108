import React, { useContext, useEffect, useState } from 'react';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import DataTable, { DataTableSortOrder } from '../../components/common/data_table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import ProfileCompletenessHeader from '../../components/profile_completeness/profile_completeness_header';
import { 
    ProfileScoreType,
    IPropertyCoreDetailNHID,
    INhPortalScoreItem,
    INhPropertyScores,
    INhPropertyScoresItem,
    IPropertyCoreDetailInsuranceType
} from '../../interfaces/property';

import { getPropertyCoreDetails } from '../../api/property';
import { useSelector } from 'react-redux';
import { ISessionState, ISessionProperty } from '../../interfaces/session';
import PropertyCoreDetailsForm from './property_core_details_form';
import { Link } from 'react-router-dom';
import { boolSort, textSort } from '../../utils/common';
import AppContext from '../../context/app_context';
import parse from 'html-react-parser';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import { SortOrder, TableColumn } from 'react-data-table-component';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

interface ITableDataRow {
    NHID: number;
    Name: string;
    Address: string;
    ProfileComplete: boolean;
    LongTermCare: string;
    ShortTermCare: string;
    RespiteCare: string;
    AdmitPediatricResidents: string;
    RoomTypesOffered: string[];
    SharedLongTermCost: string;
    SharedShortTermCost: string;
    SharedRespiteCost: string;
    PrivateLongTermCost: string;
    PrivateShortTermCost: string;
    PrivateRespiteCost: string;
    SemiPrivateLongTermCost: string;
    SemiPrivateShortTermCost: string;
    SemiPrivateRespiteCost: string;
    MedicaidBedCount: string;
    MedicareBedCount: string;
    AcceptMedicareAdvantagePlans: string;
    AcceptMedicaidPendingPatients: string;
    InsuranceDetails: [number, number];
    FacilityHasMemoryCareUnit: string;
    FacilityHasBehaviouralHealthUnit: string;
    OtherFacilityTypes: string;
    MinAgeRequirement: string;
    MinAge: string;
    WebsiteURL: string; 
    StateSlug: string;
    CitySlug: string;
    Slug: string;
}

const PropertyCoreDetails = ({ nhIDs, orgNHID }: IProps) => {

    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [ coreDetailData, setCoreDetailData ] = useState<IPropertyCoreDetailNHID[]>([]);
    const [ tableData, setTableData ] = useState<ITableDataRow[]>([]);   
    const [sortField, setSortField] = useState<string>('Name');
    const [sortOrder, setSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const [ profileCompletenessFilterValue, setProfileCompletenessFilterValue ] = useState<boolean | null>(null);

    const sessionState: ISessionState = useSelector(
        ({SessionState}: any) => SessionState
    );

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const singlePropertySession = nhIDs?.length === 1;
    const singleNHID = singlePropertySession ? nhIDs[0] : selNHID;

    const appContext: any = useContext(AppContext);

    useEffect(() => {

        const fetchCoreDetails = async() => {
            const data = await getPropertyCoreDetails();
            setCoreDetailData(data);
        };

        fetchCoreDetails();
    }, [selNHID]);
    
    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    useEffect(() => {

        const getSessionData = (NHID: number) => {

            const property = (sessionState?.Properties || []).find((item: ISessionProperty) => (
                item.NHID === NHID
            ));
    
            const Addr =  property ? [
                property.Address1,
                property.City,
                property.PostCode,
                property.RegionCode
            ].join(', ') : '';

            const WebsiteURL = property?.WebsiteURL;
            const StateSlug = property?.StateSlug;
            const CitySlug = property?.CitySlug;
            const Slug = property?.Slug;

            return { Addr, WebsiteURL, StateSlug, CitySlug, Slug };
        };
    
        const getName = (NHID: number) => {
    
            const property = (sessionState?.Properties || []).find((item: ISessionProperty) => (
                item.NHID === NHID
            ));
    
            return property?.Name || '';
        };
    
        const getProfileComplete = (NHID: number) => {
    
            const propertyScore = (profileCompletenessState?.PropertyScores || []).find((item: INhPropertyScores) => (
                item.NHID === NHID
            ));
    
            const coreScore = (propertyScore?.Scores || []).find((item: INhPropertyScoresItem) => (
                item.KeyName === ProfileScoreType.FacilityBaseData
            ));
    
            return coreScore ? coreScore.Score === coreScore.AvailableScore : false;
        };

        const getMinAgeRequirement = (item: IPropertyCoreDetailNHID): string => {

            const minAgeRequirement = item.CoreDetails?.MinAgeRequirement;
            const minAge = item.CoreDetails?.MinAge;
      
            return minAgeRequirement === true ? `Yes (${minAge})` :
                minAgeRequirement === false ? 'No' : 'Unknown';
        };

        const getCoreDetailsYesNo = (item: IPropertyCoreDetailNHID, key: string): string => {
            return item.CoreDetails ? (item.CoreDetails as any)[key] === true ? 'Yes' :
                (item.CoreDetails as any)[key] === false ? 'No' : 'Unknown' : 'Unknown';
        };

        const getCoreDetailsText = (item: IPropertyCoreDetailNHID, key: string): string => {
            return  item.CoreDetails ? (item.CoreDetails as any)[key] ? (item.CoreDetails as any)[key] 
                : 'None Listed' : 'None Listed';
        };

        const getCoreDetailsCount = (item: IPropertyCoreDetailNHID, key: string): string => {

            return item.CoreDetails ? (item.CoreDetails as any)[key] !== null ? 
                (item.CoreDetails as any)[key] : 'Unknown': 'Unknown';
        };

        const getRoomTypesOffered = (item: IPropertyCoreDetailNHID): string[] => {

            const roomTypeArray = [];
            if (item.CoreDetails) {
              
                const types = [
                    ['RoomTypePrivateRoom', 'Private'],
                    ['RoomTypePrivateSuite', 'Private Suite'],
                    ['RoomTypeSemiPrivate', 'Semi Private'],
                    ['RoomTypeShared', 'Shared']
                ];

                for (const type of types) {
                    if ((item.CoreDetails as any)[type[0]] === true) {
                        roomTypeArray.push(type[1]);
                   }
                }

                if (item.CoreDetails?.RoomTypeOther) {
                    roomTypeArray.push(item.CoreDetails.RoomTypeOther);
                }
            }

            if (!roomTypeArray.length) {
                roomTypeArray.push('None Selected');
            }

            return roomTypeArray;
        };

        const getRoomCost = (item: IPropertyCoreDetailNHID, key: string): string => {

            if (!item.CoreDetails) {
                return 'Unknown';
            }

            const val = (item.CoreDetails as any)[key];
            return val === null ? 'Unknown' : `$${(item.CoreDetails as any)[key]}`;
        };

        const getInsuranceDetails = (item: IPropertyCoreDetailNHID): [number, number] => {

            if (!item.InsuranceTypes) {
                return [0,0];
            }

            return [
                item.InsuranceTypes.reduce((acc: number, val: IPropertyCoreDetailInsuranceType) => 
                        acc + (val.IsActive ? 1 : 0), 0),
                item.InsuranceTypes.length
            ];
        };

        let sortFn: (key: string, order: 'ASC' | 'DESC') => any;

        switch(sortField) {
            case'Name':
                sortFn = textSort;
                break;
            case 'ProfileComplete':
                sortFn = boolSort;
                break;
            default:
              console.log(`So sort function declared for column ${sortField}`);
        }

        const tableData = coreDetailData.map((item: IPropertyCoreDetailNHID): ITableDataRow => {

            const name = getName(item.NHID);
            const { Addr, WebsiteURL, StateSlug, CitySlug, Slug} = getSessionData(item.NHID);
            const profileComplete = getProfileComplete(item.NHID);

            return {
                NHID: item.NHID,
                Name: name,
                Address: Addr,
                ProfileComplete: profileComplete,
                LongTermCare: getCoreDetailsYesNo(item, 'LongTermCare'),
                ShortTermCare: getCoreDetailsYesNo(item, 'ShortTermCare'),
                RespiteCare: getCoreDetailsYesNo(item, 'RespiteCare'),
                AdmitPediatricResidents: getCoreDetailsYesNo(item, 'AdmitPediatricResidents'),
                RoomTypesOffered: getRoomTypesOffered(item),
                SharedLongTermCost: getRoomCost(item, 'LongTermSharedCostPerDay'),
                SharedShortTermCost: getRoomCost(item, 'ShortTermSharedCostPerDay'),
                SharedRespiteCost: getRoomCost(item, 'RespiteSharedCostPerDay'),
                PrivateLongTermCost: getRoomCost(item, 'LongTermPrivateCostPerDay'),
                PrivateShortTermCost: getRoomCost(item, 'ShortTermPrivateCostPerDay'),
                PrivateRespiteCost: getRoomCost(item, 'RespitePrivateCostPerDay'),
                SemiPrivateLongTermCost: getRoomCost(item, 'LongTermSemiPrivateCostPerDay'),
                SemiPrivateShortTermCost: getRoomCost(item, 'ShortTermSemiPrivateCostPerDay'),
                SemiPrivateRespiteCost: getRoomCost(item, 'RespiteSemiPrivateCostPerDay'),
                MedicaidBedCount: getCoreDetailsCount(item, 'BedsMedicaidCount'),
                MedicareBedCount: getCoreDetailsCount(item, 'BedsMedicareCount'),
                AcceptMedicareAdvantagePlans: getCoreDetailsYesNo(item, 'AcceptMedicareAdvantage'),
                AcceptMedicaidPendingPatients: getCoreDetailsYesNo(item, 'AcceptMedicaidPending'),
                InsuranceDetails: getInsuranceDetails(item),
                FacilityHasMemoryCareUnit: getCoreDetailsYesNo(item, 'FacilityHasMemoryCareUnit'),
                FacilityHasBehaviouralHealthUnit: getCoreDetailsYesNo(item, 'FacilityHasBehaviouralHealthUnit'),
                OtherFacilityTypes: getCoreDetailsText(item, 'OtherFacilityTypes'),
                MinAgeRequirement: getMinAgeRequirement(item),
                MinAge: getCoreDetailsCount(item, 'MinAge'),
                WebsiteURL,
                StateSlug,
                CitySlug,
                Slug
            };
        }).sort(sortFn(sortField, sortOrder));

        const filteredTableData = tableData.filter((item: ITableDataRow) => {

            const nhIDValid = !selNHID || item.NHID === selNHID;

            const profileCompletenessFilterValid = profileCompletenessFilterValue === null || 
                profileCompletenessFilterValue === item.ProfileComplete;

            return nhIDValid && profileCompletenessFilterValid;
        });

        setTableData(filteredTableData);

    }, [nhIDs, orgNHID, selNHID, coreDetailData, profileCompletenessFilterValue, sortField, sortOrder]);
   
    const formatProfileComplete = (row: ITableDataRow) => {

        return (
            <div className="d-block fw-bold text-nowrap text-center w-100">
                <FontAwesomeIcon 
                    icon={row.ProfileComplete ? faCheck : faXmark} 
                    className={`fe-2 ${row.ProfileComplete ? 'text-success' : 'text-danger'} fs-2`}
                /> 
            </div>
        );
    };

    const formatRoomTypesOffered = (row: ITableDataRow) => {
        return (
            <div className="py-4">
                {
                    row.RoomTypesOffered.map((item: string) => (
                        <div className="py-0 m-1">{item}</div>
                    ))
                }
            </div>
        );
    };

    const formatCostPerDay = (row: ITableDataRow) => {

        if (
            !row.RoomTypesOffered.includes('Shared') &&
            !row.RoomTypesOffered.includes('Private') &&
            !row.RoomTypesOffered.includes('Semi Private') 
        ) {
            return 'None Selected';
        }

        return (
            <div className="py-4">

            {
                row.RoomTypesOffered.includes('Shared') ?
                <div className="py-1">
                    <span className="fw-semibold">Shared</span><br></br>
                    <span className="py-0 mt-1"><em>Long Term:</em> {row.SharedLongTermCost}</span><br></br>
                    <span className="py-0 mt-1"><em>Short Term:</em> {row.SharedShortTermCost}</span><br></br>
                    <span className="py-0 mt-1"><em>Respite:</em> {row.SharedRespiteCost}</span>
                </div> : null
            }
            {
                row.RoomTypesOffered.includes('Private') ?
                <div className="mt-4 py-1">
                    <span className="fw-semibold">Private</span><br></br>
                    <span className="py-0 mt-1"><em>Long Term:</em> {row.PrivateLongTermCost}</span><br></br>
                    <span className="py-0 mt-1"><em>Short Term:</em> {row.PrivateShortTermCost}</span><br></br>
                    <span className="py-0 mt-1"><em>Respite:</em> {row.PrivateRespiteCost}</span>
                </div>: null
            }
            {
                row.RoomTypesOffered.includes('Semi Private') ?
                <div className="mt-4 py-1">
                    <span className="fw-semibold">Semi-private</span><br></br>
                    <span className="py-0 mt-1"><em>Long Term:</em> {row.SemiPrivateLongTermCost}</span><br></br>
                    <span className="py-0 mt-1"><em>Short Term:</em> {row.SemiPrivateShortTermCost}</span><br></br>
                    <span className="py-0 mt-1"><em>Respite:</em> {row.SemiPrivateRespiteCost}</span>
                </div>: null
            }
        </div>
        );
    };

    const formatPaymentType = (row: ITableDataRow) => {
        return (
            <div>
                <div className="py-1">
                    <span className="fw-semibold">Medicaid Bed Count</span><br></br>
                    <span className="py-0 mt-1">{row.MedicaidBedCount}</span>
                </div>
                <div className="mt-3 py-1">
                    <span className="fw-semibold">Medicare Bed Count</span><br></br>
                    <span className="py-0 mt-1">{row.MedicareBedCount}</span>
                </div>
                <div className="mt-3 py-1">
                    <span className="fw-semibold">Accept Medicare Advantage plans</span><br></br>
                    <span className="py-0 mt-1">{row.AcceptMedicareAdvantagePlans}</span>
                </div>
                <div className="mt-3 py-1">
                    <span className="fw-semibold">Accept Medicaid pending patients</span><br></br>
                    <span className="py-0 mt-1">{row.AcceptMedicaidPendingPatients}</span>
                </div>
            </div>
        );
    };
    
    const formatInsuranceTypes = (row: ITableDataRow) => {
        return (
            <div>
                <div className="d-block fw-bold text-nowrap text-center w-100">
                    {row.InsuranceDetails[0]} / {row.InsuranceDetails[1]}
                </div><small className="text-nowrap">completed</small></div>
        );
    };

    const formatCareOffered = (row: ITableDataRow) => {

        const array = [
            ['Long Term Care', 'LongTermCare'],
            ['Short Term Care', 'ShortTermCare'],
            ['Respite Care', 'RespiteCare'],
            ['Facility has a <br/>Memory Care Unit', 'FacilityHasMemoryCareUnit'],
            ['Facility has a <br/>Behavioural Health Unit', 'FacilityHasBehaviouralHealthUnit'],
            ['Speciality Niche Services', 'OtherFacilityTypes'],
            ['Minimum Age', 'MinAgeRequirement'],
            ['Pediatric Residents', 'AdmitPediatricResidents'],
        ];
        
        return <div className="py-2">
            {
            array.map((item: any) => (
                <>
                    <p><strong>{parse(item[0])}</strong>: {(row as any)[item[1]]}</p>
                </>
            ))
            }
        </div>;
    };

    const formatName = (row: ITableDataRow) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="py-2">
                <a 
                    href="#" 
                    className='fw-bolder d-block'
                    onClick={(e) => handleLinkClick(row.NHID, e)}
                >
                    { row.Name }
                </a>
                <small>{ row.Address }</small>&nbsp;
                <a
                    className="small text-link text-link-blue text-nowrap"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a>    
            </div>  
        );
    };

    const formatActions = (row: ITableDataRow) => {
        return (
            <Link 
                to={'/amenities'}
                onClick={(e) => handleLinkClick(row.NHID, e)}
                className="btn btn-sm btn-primary text-nowrap"
            >
                Edit
            </Link>  
        );
    };

    const columns = [
        {
            id: 'NHID',
            name: 'Facility Name',
            sortField: 'Name',
            sortable: true,
            minWidth: appContext.isMobile ? '146px' : '200px',
            cell: (row: any) => formatName(row)
        },
        {
            name: <>Profile Complete for Care Offered / Cost / Funding</>,
            sortable: true,
            sortField: 'ProfileComplete',
            width: '160px',
            cell: (row: any) => formatProfileComplete(row)
        },
        {
            name: 'Care Offered',
            width: '300px',
            cell: (row: any) => formatCareOffered(row)
        },
        {
            name: 'Room Types Offered',
            width: '140px',
            cell: (row: ITableDataRow) => formatRoomTypesOffered(row)
        },
        {
            name: 'Cost (starting at per day)',
            width: '180px',
            cell: (row: ITableDataRow) => formatCostPerDay(row)
        },
        {
            name: 'Bed Allocation and Payment Types',
            minWidth: '180px',
            cell: (row: ITableDataRow) => formatPaymentType(row)
        },
        {
            name: 'Insurance Providers Accepted',
            width: '105px',
            cell: (row: ITableDataRow) => formatInsuranceTypes(row)
        },
        {
            name: 'Actions',
            cell: (row: ITableDataRow) => formatActions(row),
            width: '90px',
            id: 'Actions'
        },
    ];

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelect(nhID);
        window.scrollTo(0, 0);
    };

    const handleTableSortChange = (
        column: TableColumn<any>,
        sortOrder: SortOrder
    ) => {
        setSortField(column.sortField);
        setSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    return (
        <>
            <PageCommunitySelectWrapper
                label={'Care Offered / Cost / Funding'}
                selNHID={selNHID}
                handleSelect={handleSelect}
            />
            
            <div>
                <div className="widget" data-widget-height="auto">
                    <div className="widget-body">                          
                        <ProfileCompletenessHeader
                            selNHID={selNHID}
                            nhIDs={nhIDs}
                            orgNHID={orgNHID}
                            profileScoreType={ProfileScoreType.FacilityBaseData}
                            handleProfileCompletenessFilter={setProfileCompletenessFilterValue}
                            profileCompletenessFilterValue={profileCompletenessFilterValue}
                            title="for Care Offered / Cost / Funding"
                        />
                    </div> 
                </div> 
                <div className="widget">
                    <div className="widget-body">
                    { 
                        !singlePropertySession && !selNHID
                            ?  
                        <div className={'coredetails_dt sticky_dt table_dt expander_dt overflow_dt'}>
                            <DataTable
                                columns={columns}
                                onSort={handleTableSortChange}
                                data={tableData}

                            />
                        </div>  
                            : 
                            <PropertyCoreDetailsForm
                                nhID={singleNHID} 
                            />
                    }
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropertyCoreDetails;