import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { LeadMetric } from '../../interfaces/leads';

type Props = {
    metric: LeadMetric;
    filters: any;
    isProviderData: boolean;
    data: any[];
};

export default function LeadsInsightsChart(props: Props) {
    const chartDiv = 'leads-insights-chart';

    useEffect(() => {
        if (!props.data) {
            return;
        }

        const root = am5.Root.new(chartDiv);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        root.dateFormatter.setAll({
            dateFormat: 'yyyy',
            dateFields: ['valueX']
        });

        const chartData = props.data.map((item: any) => {
            let date = new Date();
            date.setHours(0,0,0,0);
            date.setDate(1);

            switch (props.filters.DataPoint) {
                case 'month':
                    date.setFullYear(item.Year);
                    date.setMonth(item.Month - 1);
                    break;
                case 'week':
                    date.setFullYear(item.Year);
                    date.setMonth(0);
                    date.setDate(item.Week * 7);
                    break;
                case 'day':
                    date = new Date(item.EventDate);
                    break;
            }

            const value = item[props.metric];

            return {
                date: date.getTime(),
                value
            };
        });

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            pinchZoomX: true,
            paddingLeft: 0
        }));

        const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
            behavior: 'none'
        }));

        cursor.lineY.set('visible', false);

        const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0.2,
                baseInterval: {
                timeUnit: props.filters.DataPoint,
                count: 1
            },
                renderer: am5xy.AxisRendererX.new(root, {
                minorGridEnabled:true
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));
        
        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                pan: 'zoom'
            })  
        }));

        const series = chart.series.push(am5xy.LineSeries.new(root, {
            name: 'Series',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueXField: 'date',
            tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}'
            })
        }));

        series.data.setAll(chartData);

        series.appear(1000);
        chart.appear(1000, 100);

        return () => root.dispose();

    }, [props.data, props.metric]);

    return <div id={chartDiv} style={{height: '400px', width: '100%'}}></div>;
}
