import React, { useEffect, useState } from 'react';
import { ReviewCardsOrder, ReviewCardsOrderProperty, IReviewCardOrder, IReviewCardProperty } from '../../interfaces/review_cards';
import * as ReviewCardsApi from '../../api/review_cards';
import { Toast, showToast, ToastType } from '../../utils/toast';
import DataTable, { DataTableSortOrder, INITIAL_ROWS_PER_PAGE } from '../../components/common/data_table';
import { TableColumn, SortOrder } from 'react-data-table-component';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import Modal from 'react-bootstrap/Modal';
import { longFormatDateTime } from '../../utils/date';
import { DataRows } from '../../interfaces/common';
import { ISessionState, SessionType } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { OrderHistoryDetails } from './order_history_detail';
import moment from 'moment/moment';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { ISelectOption } from '../../interfaces/form';
import { useLocation, Link } from 'react-router-dom';
import PremiumLink from '../../components/common/premium_sign_up_link';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';

class Order {
    constructor(public wantReviewCards: boolean, public wantReviewStands: boolean, public recipientFirstName: string, public recipientLastName: string, public cardsQuantity: number, public standsQuantity: number) {
    }

    public get valid(): boolean {
        return this.recipientFirstName?.trimEnd().length > 0 && this.recipientLastName?.trimEnd().length > 0 && (this.wantReviewCards || this.wantReviewStands);
    }

    public get ignore(): boolean {
        return !this.wantReviewCards && !this.wantReviewStands;
    }

    public reset(): void {
        this.wantReviewCards = false;
        this.wantReviewStands = false;
        this.cardsQuantity = 0;
        this.standsQuantity = 0;
    }
}

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

enum DateFilterMode {
    All = 'all',
    OrdersSince = 'ordersince',
    NoOrdersSince = 'noorderssince'
}

const ReviewCards = ({nhIDs, orgNHID}: IProps) => {
    const dateFilterModeOptions: ISelectOption[] = [{
        label: 'All Nursing Homes',
        value: DateFilterMode.All
    }, {
        label: 'Nursing Homes with no orders placed...',
        value: DateFilterMode.NoOrdersSince
    }, {
        label: 'Nursing Homes with orders placed...',
        value: DateFilterMode.OrdersSince
    }];

    const location = useLocation();
    const state = location?.state as any;
    const defaultNHID = state?.NHID || null;
    const defaultCardsQuantity = 100;
    const defaultStandsQuantity = 1;

    const [busyLoadingProperties, setBusyLoadingProperties] = useState<boolean>(true);
    const [busySavingOrder, setBusySavingOrder] = useState<boolean>(false);
    const [disablePropertiesTable, setDisablePropertiesTable] = useState<boolean>(false);
    const [properties, setProperties] = useState<IReviewCardProperty[]>([]);
    const [propertiesTotalRows, setPropertiesTotalRows] = useState<number>(0);
    const [propertiesTableOffset, setPropertiesTableOffset] = useState<number>(0);
    const [propertiesTableLimit, setPropertiesTableLimit] = useState<number>(10000);
    const [propertiesTableSortColumn, setPropertiesTableSortColumn] = useState<string>('Name');
    const [propertiesTableSortOrder, setPropertiesTableSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Ascending);
    const [propertiesOrders] = useState<Map<number, Order>>(new Map<number, Order>());
    const [selectedNHID, setSelectedNHID] = useState<number>(defaultNHID);
    const [showOrdersModal, setShowOrdersModal] = useState<boolean>(false);
    const [historicalOrders, setHistoricalOrders] = useState<IReviewCardOrder[]>([]);
    const [busyLoadingOrders, setBusyLoadingOrders] = useState<boolean>(true);
    const [disableOrdersTable, setDisableOrdersTable] = useState<boolean>();
    const [ordersTotalRows, setOrdersTotalRows] = useState<number>();
    const [ordersTableOffset, setOrdersTableOffset] = useState<number>(0);
    const [ordersTableLimit, setOrdersTableLimit] = useState<number>(INITIAL_ROWS_PER_PAGE);
    const [ordersTableSortColumn, setOrdersTableSortColumn] = useState<string>('PostalCardsOrderID');
    const [ordersTableSortOrder, setOrdersTableSortOrder] = useState<DataTableSortOrder>(DataTableSortOrder.Descending);
    const [lastOrderDates] = useState<Map<number, moment.Moment>>(new Map<number, moment.Moment>);
    const [showOrderDeletionModal, setShowOrderDeletionModal] = useState<boolean>(false);
    const [busyDeletingOrder, setBusyDeletingOrder] = useState<boolean>(false);
    const [orderIDToDelete, setOrderIDToDelete] = useState<number>();
    const [allOrderCards, setAllOrderCards] = useState<boolean>(false);
    const [allOrderStands, setAllOrderStands] = useState<boolean>(false);
    const [dateFilterEnabled, setDateFilterEnabled] = useState<boolean>(false);
    const [filterDateMode, setFilterDateMode] = useState<ISelectOption>(dateFilterModeOptions[0]);
    const [filterDate, setFilterDate] = useState<Date>(null);
    const [reloadTick, setReloadTick] = useState<number>(0);
    const [hasOnePremium, setHasOnePremium] = useState<boolean>(null);

    const { Session }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { SessionType: sessionType } = Session;
      
    const loadPropertiesFirstPage = async () => {
        propertiesOrders.clear();
        setAllOrderCards(false);
        setAllOrderStands(false);

        if (!selectedNHID) {
            setProperties(null);
            setBusyLoadingProperties(true);
            try {
                const properties = sessionType === SessionType.SessionTypeOrg ?
                    await getOrgProperties(filterDateMode.value, filterDate) :
                    await getProperties(filterDateMode.value, filterDate);

                const hasOnePremium = (properties.data || []).findIndex((property: IReviewCardProperty) => property.IsPremium) > -1;

                setProperties(properties.data);
                setPropertiesTotalRows(properties.totalRows);
                setOrderButtonText();
                setHasOnePremium(hasOnePremium);

            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingProperties(false);
            }
        } else {
            setBusyLoadingProperties(false);
            setOrderButtonText();
        }
    };

    useEffect(() => {
        setOrderButtonText();
    }, []);

    useEffect(() => {
        const singleNHID = nhIDs && nhIDs.length === 1 ? nhIDs[0] : null;

        if (singleNHID) {
            handleSelect(singleNHID);
        }
    }, [nhIDs, orgNHID]);

    useEffect(() => {
        if (!selectedNHID && orgNHID) {
            (async () => {
                setDisablePropertiesTable(true);
                try {
                    const properties = (await getOrgProperties(filterDateMode.value, filterDate))?.data ?? [];
                    setProperties(properties);
                } catch (e) {
                    console.error(e);
                } finally {
                    setDisablePropertiesTable(false);
                }
            })();
        }
    }, [propertiesTableOffset,propertiesTableLimit, propertiesTableSortColumn, propertiesTableSortOrder]);

    useEffect(() => {
        if (!properties?.length) {
            return;
        }

        const premiumProperties = properties.filter((property: IReviewCardProperty) => property.IsPremium);

        if (premiumProperties) {
            for (const property of premiumProperties) {
                if (!propertiesOrders.has(property.NHID)) {
                    propertiesOrders.set(
                        property.NHID, 
                        new Order(
                            allOrderCards, 
                            allOrderStands,
                            property.PrimaryContactFirstName ?? property.LastRecipientFirstName,
                            property.PrimaryContactLastName ?? property.LastRecipientLastName ,
                            0,
                            0
                        )
                    );
                }
            }
        }
    }, [properties]);

    useEffect(() => {
        if (showOrdersModal) {
            initializeOrdersTable();
        } else {
            setHistoricalOrders(null);
        }
    }, [showOrdersModal]);

    useEffect(() => {
        if (!showOrdersModal) {
            return;
        }

        setDisableOrdersTable(true);

        (async () => {
            try {
                setHistoricalOrders((await getOrders()).data);
            } catch (e) {
                console.error(e);
            } finally {
                setDisableOrdersTable(false);
            }
        })();
    }, [ordersTableOffset, ordersTableLimit, ordersTableSortColumn, ordersTableSortOrder]);

    useEffect(() => {
        if (!selectedNHID) {
            loadPropertiesFirstPage();
        }

        setBusyLoadingProperties(true);

        (async () => {
            try {
                if (selectedNHID) {
                    const property = await getProperty();
                    if (property) {
                        const hasOnePremium = property.data?.[0].IsPremium || false;

                        setHasOnePremium(hasOnePremium);
                        setProperties(property.data);
                        setPropertiesTotalRows(1);
                    }
                }
               
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingProperties(false);
            }
        })();
    }, [selectedNHID]);

    useEffect(() => {
        for (const order of propertiesOrders.values()) {
            order.wantReviewCards = allOrderCards;
            order.wantReviewStands = allOrderStands;

            if (allOrderCards) {
                order.cardsQuantity = defaultCardsQuantity;
            }

            if (allOrderStands) {
                order.standsQuantity = defaultStandsQuantity;
            } 
        }

        setOrderButtonText();
    }, [allOrderCards, allOrderStands]);
    
    useEffect(() => {
        loadPropertiesFirstPage();
    }, [filterDateMode, filterDate]);

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelectedNHID(selNHID);    
    });

    const PropertiesTableRecipientFirstNameInputElement = (props: any) => {
        return (
            <input
                type="text"
                className="form-control"
                defaultValue={propertiesOrders.get(props.row.NHID)?.recipientFirstName}
                onChange={(e: any) => onPropertiesTableRecipientFirstNameChange(props.row.NHID, e.target.value)}
            />
        );
    };

    const PropertiesTableRecipientLastNameTableElement = (props: any) => {
        return (
            <input
                type="text"
                className="form-control"
                defaultValue={propertiesOrders.get(props.row.NHID)?.recipientLastName}
                onChange={(e: any) => onPropertiesTableRecipientLastNameChange(props.row.NHID, e.target.value)}
            />
        );
    };

    const PropertiesTableWantReviewCardsInputElement = (props: any) => {
        return (
            <div className="form-check form-check-custom form-check-lg d-flex align-items-center justify-content-center w-100">
                <input
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked={propertiesOrders.get(props.row.NHID)?.wantReviewCards}
                    onChange={(e: any) => onPropertiesTableWantReviewCardsChange(props.row.NHID, e.target.checked)}
                />
            </div>
        );
    };

    const PropertiesTableWantReviewStandsInputElement = (props: any) => {
        return (
            <div className="form-check form-check-custom form-check-lg d-flex align-items-center justify-content-center w-100">
                <input
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked={propertiesOrders.get(props.row.NHID)?.wantReviewStands}
                    onChange={(e: any) => onPropertiesTableWantReviewStandsChange(props.row.NHID, e.target.checked)}
                />
            </div>
        );
    };

    const PropertiesTableLastOrderDate = (props: any) => {
        return (
            <div>
                {
                    lastOrderDates.has(props.row.NHID) ?
                        longFormatDateTime(lastOrderDates.get(props.row.NHID))
                        :
                        props.row.LastOrderDate ?
                            longFormatDateTime(props.row.LastOrderDate)
                            : props.showNullMessage ?
                                'None'
                                : null
                }
            </div>
        );
    };

    const PropertyNameElement = function statelessFunctionComponentClass(props: any) {
        const formatAddress = (property: IReviewCardProperty) => {
            return [
                property.Address1,
                property.Address2,
                property.Address3,
                property.City,
                property.RegionCode,
                property.PostCode
            ].reduce((total: string, value: string) => {
                return value && value.length > 0 ? `${total}, ${value}` : total;
            });
        };

        return (
            <div className="my-1">
                <strong>
                    {props.row.Name}
                </strong>
                <small className="d-block lh-sm">
                    {formatAddress(props.row)}
                </small>
            </div>
        );
    };

    const handlePaste = (row: IReviewCardProperty, contactType: string) => {
        if (!row.IsPremium) {
            return;
        }

        if (contactType === 'LastRecipient') {
            propertiesOrders.set(
                row.NHID, 
                new Order(
                    allOrderCards, 
                    allOrderStands,
                    row.LastRecipientFirstName,
                    row.LastRecipientLastName,
                    0,
                    0
                )
            );
        } else {
            propertiesOrders.set(
                row.NHID, 
                new Order(
                    allOrderCards, 
                    allOrderStands,
                    row.PrimaryContactFirstName,
                    row.PrimaryContactLastName,
                    0,
                    0
                )
            );
        }
        setReloadTick(reloadTick + 1);
    };

    const formatContactName = (row: any) => {
        return (
            <div>
                {
                    row.PrimaryContactFirstName ?
                        <div>
                            <br/>
                            a) Review Card Portal User Account: 
                            <br/>
                            &nbsp;
                            <span className="fw-bold"> 
                                {row.PrimaryContactFirstName} {row.PrimaryContactLastName} 
                            </span> 
                            <a 
                                className="d-inline-block"
                                href="#" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePaste(row, 'PrimaryContact');
                                }}
                            >
                                &nbsp; <i className="fa fa-paste"></i>
                            </a>
                            <br/>
                        </div> 
                    :
                        <div>
                            <br/>
                            a) Review Card Portal User Account: N/A
                            <br/>
                        </div>
                }
                {
                    row.LastRecipientFirstName ?
                        <div>
                            <br/>
                            b) Previous Order Contact: 
                            <br/>
                            &nbsp;
                            <span className={`${row.PrimaryContactFirstName ? '' : 'fw-bold'}`}>
                                {row.LastRecipientFirstName} {row.LastRecipientLastName}
                            </span>
                            <a 
                                className="d-inline-block"
                                href="#" 
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePaste(row, 'LastRecipient');
                                }}
                            >
                                &nbsp; <i className="fa fa-paste"></i>
                            </a> 
                            <br/>
                            &nbsp;
                        </div> 
                    :
                        <div>
                            b) Previous Order Contact: N/A
                            <br/>
                        </div>
                }
            </div>
        );
    };

    const propertiesColumns = [
        {
            omit: true,
            name: 'NHID',
            selector: (row: IReviewCardProperty) => row.NHID,
            sortable: !selectedNHID,
            sortField: 'NHID',
            width: '150px'
        }, {
            name: 'Facility Name',
            selector: (row: IReviewCardProperty) => row.Name,
            sortable: !selectedNHID,
            sortField: 'Name',
            width: '230px',
            cell: (row: IReviewCardProperty) => <PropertyNameElement row={row}/>
        }, {
            name: 'Card Logo',
            selector: (row: IReviewCardProperty) => row.Name,
            sortable: !selectedNHID,
            width: '225px',
            cell: (row: IReviewCardProperty) => showCardLogo(row)
        },{
            name: 'Contact to use as Recipient',
            width: '340px',
            cell: ( row: IReviewCardProperty) => formatContactName(row)
        },{
            name: 'Recipient First Name',
            width: '200px',
            cell: (row: IReviewCardProperty) => row.IsPremium ? <PropertiesTableRecipientFirstNameInputElement row={row} /> : <small className="fw-bold text-danger">Premium&nbsp;Subscription Required</small>
        }, {
            name: 'Recipient Last Name',
            width: '200px',
            cell: (row: IReviewCardProperty) => row.IsPremium ? <PropertiesTableRecipientLastNameTableElement row={row} /> : <PremiumLink websiteUrl={row.WebsiteURL} />
        }, {
            name:
                <div className="text-center lh-1 mt-4">
                    Order Cards?
                    <div hidden={propertiesTotalRows === 1 || !hasOnePremium}>
                        <div className="form-check form-check-custom form-check-lg d-flex align-items-center justify-content-center mt-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={allOrderCards}
                                onChange={(e: any) => setAllOrderCards(e.target.checked)}
                                disabled={!hasOnePremium}
                            />
                        </div>
                    </div>
                </div>,
            width: '90px',
            cell: (row: IReviewCardProperty) => row.IsPremium ? <PropertiesTableWantReviewCardsInputElement row={row} /> : null
        }, {
            name:
                <div className="text-center lh-1 mt-4">
                    Order Stands?
                    <div hidden={propertiesTotalRows === 1 || !hasOnePremium}>
                        <div className="form-check form-check-custom form-check-lg d-flex align-items-center justify-content-center mt-2">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={allOrderStands}
                                onChange={(e: any) => setAllOrderStands(e.target.checked)}
                                disabled={!hasOnePremium}
                            />
                        </div>
                    </div>
                </div>,
            width: '90px',
            cell: (row: IReviewCardProperty) => row.IsPremium ? <PropertiesTableWantReviewStandsInputElement row={row}/> : null
        } ,{
            name: 'Date of Last Order',
            selector: (row: IReviewCardProperty) => row.LastOrderDate,
            width: '120px',
            sortable: !selectedNHID,
            sortField: 'LastOrderDate',
            cell: (row: IReviewCardProperty) => <PropertiesTableLastOrderDate row={row}/>
        }
    ];

    const mobilePropertiesColumns = [
        {
            omit: true,
            name: 'NH ID',
            selector: (row: IReviewCardProperty) => row.NHID,
            sortable: !selectedNHID,
            sortField: 'NHID',
            width: '150px'
        }, {
            name: 'Property Name',
            selector: (row: IReviewCardProperty) => row.Name,
            sortable: !selectedNHID,
            sortField: 'Name',
            cell: (row: unknown) => <PropertyNameElement row={row}/>
        }
    ];

    const ordersColumns = [
        {
            name: 'Order ID',
            width: '110px',
            sortable: true,
            sortField: 'PostalCardsOrderID',
            selector: (row: IReviewCardOrder) => row.PostalCardsOrderID
        }, {
            name: 'Submitted By',
            width: '160px',
            sortable: true,
            sortField: 'CreatedByUserName',
            selector: (row: IReviewCardOrder) => row.CreatedByUserName
        }, {
            name: 'Date Ordered',
            width: '280px',
            sortable: true,
            sortField: 'CreatedAt',
            cell: (row: IReviewCardOrder) => <span>{longFormatDateTime(row.CreatedAt)}</span>
        }, {
            name: 'Date Shipped',
            width: '280px',
            sortable: true,
            sortField: 'ShippedAt',
            cell: (row: IReviewCardOrder) => <span>{longFormatDateTime(row.ShippedAt)}</span>
        }, {
            name: 'Orders',
            width: '110px',
            sortable: true,
            sortField: 'OrderCount',
            selector: (row: IReviewCardOrder) => row.OrderCount
        }, {
            width: '120px',
            cell: (row: IReviewCardOrder) => {
                if (!row.ShippedAt) {
                    return <button
                        className="btn btn-sm btn-danger"
                        type="button"
                        onClick={() => {
                            setOrderIDToDelete(row.PostalCardsOrderID);
                            setShowOrderDeletionModal(true);
                        }}>
                        Delete
                    </button>;
                }
            }

        }
    ];

    const orderDeletionModal = (): JSX.Element => {
        return (
            <Modal show={true} centered size="sm">
                <Modal.Header>
                    <Modal.Title>
                        Confirm
                    </Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div className="text-center">
                            {`Sure that you want to delete order ${orderIDToDelete}`}?
                            <div className="mt-3">
                                <button
                                    className="btn btn-sm btn-primary"
                                    type="button"
                                    disabled={busyDeletingOrder}
                                    onClick={() => setShowOrderDeletionModal(false)}>
                                    Cancel
                                </button>
                                &nbsp;&nbsp;
                                <button
                                    className="btn btn-sm btn-danger ml-10"
                                    type="button"
                                    disabled={busyDeletingOrder}
                                    onClick={() => deleteOrder(orderIDToDelete)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
        );
    };

    const showCardLogo = (row: any) => {
        if (row.CardLogoUrl) {
            let cardLogoFileName = row.CardLogoUrl.substring(row.CardLogoUrl.lastIndexOf('/') + 1);
            cardLogoFileName = cardLogoFileName.substring(0, cardLogoFileName.lastIndexOf('.') + 1);
            const srcURL =  `${row.WebsiteURL}/logos/logo-review-card-online/${cardLogoFileName}webp`;            
            const cardLogoPdfURL =  `${row.WebsiteURL}/logos/reviewcard/${cardLogoFileName}pdf`;
            return <div>
                <img src={srcURL} alt='Card Logo Asset' />
                <small className="d-block lh-sm mt-3 mb-3">
                    <a rel="noreferrer" target='_blank' href={`${cardLogoPdfURL}` }>
                    Download PDF Print Version
                    </a>
                </small>
                <small className="d-block lh-sm">
                    <Link    
                        to="/logos"
                        state={{ NHID: row.NHID }}
                    >
                        Update this Logo
                    </Link>
                </small>
            </div>;
        }
        return null;
    };

    const deleteOrder = (orderID: number) => {
        (async () => {
            setBusyDeletingOrder(true);
            try {
                await ReviewCardsApi.deleteOrder(orderID);
                showToast('Order Deleted', ToastType.Success);
                initializeOrdersTable();
            } catch (e) {
                showToast('Order Deletion Failed', ToastType.Error);
                console.error(e);
            } finally {
                setBusyDeletingOrder(false);
                setShowOrderDeletionModal(false);
            }
        })();
    };

    const onPropertiesTableRecipientFirstNameChange = (nhID: number, value: string): void => {

        if (propertiesOrders.has(nhID)) {
            propertiesOrders.get(nhID).recipientFirstName = value;
            setOrderButtonText();
        }
    };

    const onPropertiesTableRecipientLastNameChange = (nhID: number, value: string): void => {
        if (propertiesOrders.has(nhID)) {
            propertiesOrders.get(nhID).recipientLastName = value;
            setOrderButtonText();
        }
    };

    const onPropertiesTableWantReviewCardsChange = (nhID: number, checked: boolean): void => {
        if (propertiesOrders.has(nhID)) {
            propertiesOrders.get(nhID).cardsQuantity = 0;
            propertiesOrders.get(nhID).wantReviewCards = checked;
            if(propertiesOrders.get(nhID).wantReviewCards) {
                propertiesOrders.get(nhID).cardsQuantity = defaultCardsQuantity;
            }
            setOrderButtonText();
        }
    };

    const onPropertiesTableWantReviewStandsChange  = (nhID: number, checked: boolean): void => {
        if (propertiesOrders.has(nhID)) {
            propertiesOrders.get(nhID).standsQuantity = 0;
            propertiesOrders.get(nhID).wantReviewStands = checked;
            if(propertiesOrders.get(nhID).wantReviewStands) {
                propertiesOrders.get(nhID).standsQuantity = defaultStandsQuantity;
            }
            setOrderButtonText();
        }
    };

    const getProperties = async (dateMode: DateFilterMode, date: Date): Promise<DataRows<IReviewCardProperty>> => {
        const filters = new Map<string, any>();
        if (dateMode !== DateFilterMode.All) {
            filters.set('CreatedDateFrom', date);
            filters.set('DateMode', dateMode);
        }

        return await ReviewCardsApi.getProperties(nhIDs, {
            Offset: propertiesTableOffset,
            Limit: propertiesTableLimit,
            SortField: propertiesTableSortColumn ?? 'Name',
            SortOrder: propertiesTableSortOrder
        }, filters);
    };

    const getOrgProperties = async (dateMode: DateFilterMode, date: Date): Promise<DataRows<IReviewCardProperty>> => {
        if (!orgNHID) {
            console.error('getOrgProperties: Invalid orgNHID');
            return null;
        }

        if (!date && dateMode !== DateFilterMode.All) {
            return new DataRows<IReviewCardProperty>();
        }

        const filters = new Map<string, any>();
        if (dateMode !== DateFilterMode.All) {
            filters.set('CreatedDateFrom', date);
            filters.set('DateMode', dateMode);
        }

        return await ReviewCardsApi.getOrgProperties(orgNHID, {
            Offset: propertiesTableOffset,
            Limit: propertiesTableLimit,
            SortField: propertiesTableSortColumn ?? 'Name',
            SortOrder: propertiesTableSortOrder
        }, filters);
    };

    const getProperty = async (): Promise<DataRows<IReviewCardProperty>> => {
        return await ReviewCardsApi.getProperties([selectedNHID], {});
    };

    const createPropertiesOrder = async () => {
        setBusySavingOrder(true);
        try {
            for (const order of propertiesOrders.values()) {
                if (!order.ignore && !order.valid) {
                    showToast('For all relevant orders please ensure that Recipient First Name, Recipient Last Name are filled in, and \'Order Cards\' or \'Order Stands\' is ticked', ToastType.Info);
                    return;
                }
            }
            const orders: ReviewCardsOrderProperty[] = [];
            for (const [NHID, order] of propertiesOrders.entries()) {
                if (order.ignore) {
                    continue;
                }
                orders.push(
                    new ReviewCardsOrderProperty(
                        NHID,
                        order.recipientFirstName,
                        order.recipientLastName,
                        order.cardsQuantity,
                        order.standsQuantity
                    )
                );
            }
            // Note that we can assume that all the properties in the list are for the same organization
            await ReviewCardsApi.create(new ReviewCardsOrder(properties[0].OrgID, orders));

            // Set state values for the last order date so that the order date is immediately shown in the table
            const now = moment();
            for (const [nhID, order] of propertiesOrders.entries()) {
                if (order.valid) {
                    lastOrderDates.set(nhID, now);
                }
            }

            for (const order of propertiesOrders.values()) {
                order.reset();
            }

            setOrderButtonText();
            setAllOrderCards(false);
            setAllOrderStands(false);
            showToast('Order Successful', ToastType.Success);
        } catch (e) {
            showToast('Order Failed', ToastType.Error);
            console.error(e);
        } finally {
            setBusySavingOrder(false);
        }
    };

    const onPropertiesPageChange = async (pageNumber: number) => {
        setPropertiesTableOffset((pageNumber - 1) * propertiesTableLimit);
    };

    const onPropertiesLimitChange = async (newPerPage: number, pageNumber: number) => {
        setPropertiesTableOffset((pageNumber - 1) * newPerPage);
        setPropertiesTableLimit(newPerPage);
    };

    const onPropertiesSortChange = (column: TableColumn<any>, sortOrder: SortOrder) => {
        setPropertiesTableSortColumn(column.sortField);
        setPropertiesTableSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);
    };

    const onOrdersPageChange = async (pageNumber: number) => {
        setOrdersTableOffset((pageNumber - 1) * ordersTableLimit);
    };

    const onOrdersLimitChange = async (newPerPage: number, pageNumber: number) => {
        setOrdersTableOffset((pageNumber - 1) * newPerPage);
        setOrdersTableLimit(newPerPage);
    };

    const onOrdersSortChange = (column: TableColumn<any>, sortOrder: SortOrder) => {
        setOrdersTableSortColumn(column.sortField);
        setOrdersTableSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);
    };

    const getOrders = async (): Promise<DataRows<IReviewCardOrder>> => {
        const filter = {
            OrgID: selectedNHID ? null : orgNHID,
            NHIDs: selectedNHID ?? nhIDs?.join(),
        };

        return await ReviewCardsApi.getOrders(filter, {
            Offset: ordersTableOffset,
            Limit: ordersTableLimit,
            SortField: ordersTableSortColumn,
            SortOrder: ordersTableSortOrder
        });
    };

    const initializeOrdersTable = () => {
        setHistoricalOrders(null);
        (async () => {
            setBusyLoadingOrders(true);
            try {
                const orders = await getOrders();
                setHistoricalOrders(orders.data);
                setOrdersTotalRows(orders.totalRows);
            } catch (e) {
                console.error(e);
            } finally {
                setBusyLoadingOrders(false);
            }
        })();
    };

    const setOrderButtonText = () => {
        let count = propertiesOrders.size;

        for (const order of propertiesOrders.values()) {
            if (order.ignore) {
                count--;
            }
        }

        setOrderButtonEnabled(count > 0);

        const countLabel =  document.getElementById('textOrderCount');
        if (countLabel) {
            countLabel.innerText = count.toString(10);
        }
    };

    const setOrderButtonEnabled = (enabled: boolean) => {
        const orderButton = document.getElementById('orderButton') as HTMLInputElement;
        if (orderButton) {
            orderButton.disabled = !enabled;
        }
    };

    const orderHistoryModal = (): JSX.Element => {
        return (
            <Modal show={true} centered size="xl">
                <Modal.Header>
                    <Modal.Title>
                        Order History
                    </Modal.Title>
                    <div className="float-end">
                        <button className="btn btn-primary" type="button" onClick={() => {
                            setShowOrdersModal(false);
                        }}>
                            Close
                        </button>
                    </div>

                </Modal.Header>
                <form>
                    <Modal.Body className="px-0">
                        <div className="form-group-last">
                            {
                                <div>
                                    {
                                        busyLoadingOrders ?
                                            <LoadingSpinner show={true}/>
                                            :
                                            <div className="review-cards-orders-dt">
                                                <DataTable
                                                    data={historicalOrders}
                                                    columns={ordersColumns}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    paginationTotalRows={ordersTotalRows}
                                                    initialRowsPerPage={10}
                                                    sortServer={true}
                                                    onSort={onOrdersSortChange}
                                                    onChangePage={onOrdersPageChange}
                                                    onChangeRowsPerPage={onOrdersLimitChange}
                                                    disabled={disableOrdersTable}
                                                    defaultSortFieldId={1}
                                                    expandableRows
                                                    expandableRowsComponent={OrderHistoryDetails}
                                                    fixedHeader={false}
                                                    noDataComponent={<span></span>}
                                                />
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
        );
    };

    const filters = (): JSX.Element => {
        return (
            <div>
                <div className="col-lg-12 col-xl-6 px-4">
                    <label className="mt-3">Show</label>
                    <Select
                        onChange={(e: any) => {
                            setFilterDateMode(e);
                            setDateFilterEnabled(e.value !== DateFilterMode.All);
                        }}
                        options={dateFilterModeOptions}
                        className="basic-multi-select z-index-10"
                        classNamePrefix="select"
                        value={filterDateMode}
                    />
                </div>
                <div hidden={!dateFilterEnabled} className="col-md-4 col-xl-3 px-4 mt-2">
                    <label className="mt-2">Since&nbsp;</label>
                    <DatePicker
                        id="DateFrom"
                        className='form-control date-picker'
                        dateFormat={'dd MMM yyyy'}
                        selected={filterDate}
                        placeholderText="Click to select a date"
                        onChange={(date: Date) => {
                            if (date) {
                                date.setHours(0, 0, 0, 0);
                            }
                            setFilterDate(date);
                        }}
                    />
                </div>
            </div>
        );
    };

    const mobilePropertySubRow = (row: any) => {
        const property: IReviewCardProperty = row.data;

        return (
            <div className="p-4" id="memberRow">
                <PropertyNameElement row={property}/>
                <div className="mt-3">
                    <strong className="mb-1 d-block">Recipient First Name</strong>
                    {
                        property.IsPremium 
                            ?  <PropertiesTableRecipientFirstNameInputElement row={property} />
                            :   <small className="fw-bold text-danger">Premium Subscription Required</small>
                    } 
                </div>
                <div className="mt-2">
                    <strong className="mb-1 d-block">Recipient Last Name</strong>
                    {
                        property.IsPremium 
                            ? <PropertiesTableRecipientLastNameTableElement row={property} />
                            : <PremiumLink websiteUrl={row.WebsiteURL} />
                    }
                </div>
                {
                    property.IsPremium ?
                        <>
                            <div className="mt-2">
                                <strong className="mb-1 d-block">Order Cards?</strong>
                                <div className="d-inline-block">
                                    <PropertiesTableWantReviewCardsInputElement row={property}/>
                                </div>
                            </div>
                            <div className="mt-2">
                                <strong className="mb-1 d-block">Order Stands?</strong>
                                <div className="d-inline-block">
                                    <PropertiesTableWantReviewStandsInputElement row={property}/>
                                </div>
                            </div>
                        </> : null
                }
               <div className="mt-2">
                    <strong className="mb-1 d-block">Date of Last Order</strong>
                    <PropertiesTableLastOrderDate row={row.data} showNullMessage={true}/>
                </div>
            </div>
        );
    };

    const propertiesTable = (): JSX.Element => {
        return (
            <div>
                {
                    busyLoadingProperties ?
                        <LoadingSpinner show={true}/>
                        :
                        <>
                            <LoadingSpinner show={busySavingOrder}/>

                            <div className="d-md-none review-cards-members-mobile-dt expander_dt">
                                <DataTable
                                    data={properties}
                                    columns={mobilePropertiesColumns}
                                    pagination={false}
                                    paginationServer={false}
                                    paginationTotalRows={propertiesTotalRows}
                                    sortServer={sessionType === SessionType.SessionTypeOrg}
                                    onSort={onPropertiesSortChange}
                                    onChangePage={onPropertiesPageChange}
                                    onChangeRowsPerPage={onPropertiesLimitChange}
                                    disabled={busySavingOrder || disablePropertiesTable}
                                    defaultSortFieldId={2}
                                    fixedHeader={false}
                                    expandableRows={true}
                                    expandableRowsComponent={mobilePropertySubRow}
                                    defaultExpanded={true}
                                    defaultExpandedIDProp="memberRow"
                                />
                            </div>

                            <div className="ordercards_dt table_dt d-none d-md-block">
                                <DataTable
                                    data={properties}
                                    columns={propertiesColumns}
                                    pagination={false}
                                    paginationServer={false}
                                    paginationTotalRows={propertiesTotalRows}
                                    sortServer={sessionType === SessionType.SessionTypeOrg}
                                    onSort={onPropertiesSortChange}
                                    onChangePage={onPropertiesPageChange}
                                    onChangeRowsPerPage={onPropertiesLimitChange}
                                    disabled={busySavingOrder || disablePropertiesTable}
                                    defaultSortFieldId={2}
                                    fixedHeader={false}
                                />
                            </div>
                            {
                                selectedNHID ? <div className="mt-4"></div> : null
                            }
                        </>
                }
            </div>
        );
    };

    return (
        <div>
            <Toast></Toast>
            <div className="widget widget-fullwidth">
                <div className="widget-body">
                    <div className="card">
                        <div className="card-header">
                            <h4>Order Review Cards</h4>
                            <div>
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() => setShowOrdersModal(true)}
                                    disabled={busyLoadingProperties || busySavingOrder}>
                                    View Order History
                                </button>
                            </div>
                        </div>
                        <div className="card-body p-0 pt-lg-4">
                            {
                                nhIDs?.length !== 1 ? // If not single-member view
                                    <PageCommunitySelectWrapper
                                        handleSelect={handleSelect}
                                        selNHID={selectedNHID}
                                        disabled={busySavingOrder}/>
                                    :
                                    null
                            }
                            <div className="px-4 pt-4">
                                <p>Each order receives 100 postcards and/or 1 stand depending on your selection. Orders typically take 7-10 business days to arrive.</p>
                                <p className="m-0">For requests of more than 100 postcards or more than 1 stand, please contact <a href="mailto:info@nursinghomes.com">info@nursinghomes.com</a></p>
                            </div>
                            {
                                !selectedNHID ? filters() : null
                            }
                            {
                                propertiesTable()
                            }
                            {
                                hasOnePremium ? 
                                    <button
                                        hidden={busyLoadingProperties}
                                        className="btn btn-primary btn-fixed-bottom shadow"
                                        type="button"
                                        id="orderButton"
                                        disabled={busySavingOrder}
                                        onClick={() => createPropertiesOrder()}
                                    >
                                        Place Order <span id="textOrderCount" className="badge bg-white text-black ms-2">0</span>
                                    </button> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                showOrdersModal ? orderHistoryModal() : null
            }
            {
                showOrderDeletionModal ? orderDeletionModal() : null
            }
        </div>
    );
};

export default ReviewCards;
