import * as yup from 'yup';

const schema = yup.object().shape({
    propertyId: yup.number().required('Please select a facility'),
    profileImageAssetId: yup.number().nullable(),
    sequence: yup.number(),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string(),
    position: yup
        .string()
        .required('A job title is required')
        .min(3, 'A job title should have 3 or more characters'),
    yearStarted: yup
        .number()
        .required('Please add the year this member started with the team'),
    publishWebsite: yup.string(),
    description: yup
        .string()
        .required('Please enter a little bit about the team member')
});

export { schema };
