import { IDataControls } from './common';
export interface IPropertyLiveDescriptionParams {
    NHID: number;
    HasLiveDescription: boolean;
}

export interface IPropertyLiveDescription  {
    NHID: number;
    PropertyName: string;
    Address1: string;
    Address2: string;
    CityName: string;
    StateCode: string;
    ZipCode: number;
    Description: string; 
}

export interface IPropertyContactDetailsParams extends IDataControls{
    OrgNHID: number;
    NHIDs: number[];
}

export interface IPropertyContactDetails  {
    NHID: number;
    OrgNHID: number;
    Name: string;
    Website: string;
    Email: string;
    Email2: string;
    Email3: string;
    PhoneNumber: string;
    Address1: string;
    Address2: string;
    Address3: string;
    CityName: string;
    StateCode: string;
    ZipCode: number;
    Slug: string;
    WebsiteURL: string;
    StateSlug: string; 
    CitySlug: string;
}

export interface IPropertyUpdateContactDetailsParams  {
    Website?: string;
    Email?: string;
    Email2?: string;
    Email3?: string;
    PhoneNumber?: string;
}

export interface IPropertyBulkDetailsPayload extends IPropertyUpdateContactDetailsParams {
    PhoneNumberOp?: number;
    EmailOp?: number;
    Email2Op?: number;
    Email3Op?: number;
    WebsiteOp?: number;
    OrgNHID?: number;
    NHIDs?: number[];
}

export interface IPropertyProfileCompleteness {
    TotalProfileCompletenessScore: number;
    ServicesAndAmenitiesScore: number;
    PhotoWithExternalTagScore: number;
    FiveTaggedPhotosWithoutExternalTagScore: number;
    VideoScore: number;
    FacilityBaseScore: number;
    DescriptionScore: number;
    MeetTheTeamScore: number;
}

export interface INhPropertyScoresItem {
    KeyName: string;
    FieldLabel: string;
    FieldName: string;
    Score: number;
    AvailableScore: number;
    FieldDescription: string;
}

export interface INhPropertyScores {
    NHID: number;
    Scores: INhPropertyScoresItem[];
    ScoreExtras: INhPropertyScoreExtras;
}

export interface INhPropertyScoreExtras {
    HasPhoneNumber: boolean;
    HasEmailAddress: boolean;
    HasWebsite: boolean;
    HasPortalUsers: boolean;
    HasLogo: boolean;
    HasPDFBrochure: boolean;
}

export interface INhOrgScoreFacilityCountItem {
    KeyName: string;
    FieldLabel: string;
    FieldName: string;
    Count: number;
}

export interface INhPortalScoreItem {
    OrgScores: INhOrgScoreFacilityCountItem[];
    TotalOrgProfileScore: number;
    OrgFacilityCount: number;
    PropertyScores: INhPropertyScores[]
}

export interface IScoreDisplayItem {
    TotalScore: number;
    AvailableScore: number;
    Label: string;
    KeyName: string;
    Description: string;
    FacilityCount: number;
    AchievedScoreCount: number;
    TotalAvailableScore: number;
    ScorePC: number;
    InternalURL: string;
}

export interface IScoreExtraDisplayItem {
    FacilityCount: number;
    HasPhoneNumberCount: number;
    HasEmailAddressCount: number;
    HasWebsiteCount: number;
    HasPortalUsersCount: number;
    HasLogoCount: number;
    HasPDFBrochureCount: number;
    InternalURL: string;
}

export interface IPropertyContactNoValueDetails  {
    NoEmailCount?: number;
    NoPhoneCount?: number;
    NoWebsiteCount?: number;
}

export enum ProfileScoreType {
    FacilityBaseData = 'facility-base-data',
    ServicesAndAmenities = 'services-and-amenities',
    Description = 'description-present',
    Photo = 'plus-10-photos',
    Video = 'video-present',
    MeetTheTeam = 'plus-one-meet-the-team-profile',
    Total = 'total-score'
}

export interface IProfileCompleteItem {
    NHID: number;
    FacilityBaseDataComplete: boolean;
    ServicesAndAmenitiesComplete: boolean;
    DescriptionComplete: boolean;
    PhotoComplete: boolean;
    VideoComplete: boolean;
    MeetTheTeamComplete: boolean;
    TotalComplete: boolean;
}

export interface IPropertyCoreDetailBasic {
    NHID: number,
    LongTermCare: boolean,
    ShortTermCare: boolean,
    RespiteCare: boolean,
    OtherFacilityTypes: string;
    AffiliatedHospitals: string;
    AvgShortTermResidentsPc: number;
    AvgShortStayDuration: number;
    AvgLongTermResidentsPc: number;
    AvgLongTermStayDuration: number;
    MinAgeRequirement: boolean;
    MinAge: number;
    AdmitPediatricResidents: boolean;
    PediatricAvgShortTermResidentsPc: number;
    PediatricAvgShortStayDuration: number;
    PediatricAvgLongTermResidentsPc: number;
    PediatricAvgLongTermStayDuration: number;
    PediatricMaxAgeRequirement: boolean;
    PediatricMaxAge: number;
    RoomTypeOther: string;
    RoomTypeSemiPrivate: boolean;
    RoomTypeShared: boolean;
    RoomTypePrivateSuite: boolean;
    RoomTypePrivateRoom: boolean;
	LongTermSharedCostPerDay: number;
	LongTermPrivateCostPerDay: number;
	LongTermSemiPrivateCostPerDay: number;
	LongTermRatesAllInclusive: boolean;
	LongTermRatePackagesAvailable: boolean;
	ShortTermSharedCostPerDay: number;
	ShortTermPrivateCostPerDay: number;
	ShortTermSemiPrivateCostPerDay: number;
	ShortTermRatesAllInclusive: boolean;
	ShortTermRatePackagesAvailable: boolean;
	RespiteSharedCostPerDay: number;
	RespitePrivateCostPerDay: number;
	RespiteSemiPrivateCostPerDay: number;
    RespiteMinStayRequirement: boolean;
    RespiteMinStay: number;
	RespiteRatesAllInclusive: boolean;
	RespiteRatePackagesAvailable: boolean;
    AcceptMedicareAdvantage: boolean;
    AcceptMedicaidPending: boolean;
    BedsAllDualLicenced: boolean;
    BedsMedicareCount: number;
    BedsMedicaidCount: number;
    PrivateInsurancePc: number;
    ManagedCareContractsInNetwork: boolean;
    FacilityHasMemoryCareUnit: boolean;
    FacilityHasBehaviouralHealthUnit: boolean;
}

export interface IPropertyCoreDetailInsuranceType {
    Name: string;
    Sequence: number;
    IsActive: boolean;
    PropertyInsuranceTypeID: number;
}

export interface IPropertyCoreDetailNHID {
    NHID: number;
    CoreDetails: IPropertyCoreDetailBasic
    InsuranceTypes: IPropertyCoreDetailInsuranceType[];
}

export interface IPropertyInsuranceTypesPayloadItem {
    PropertyInsuranceTypeID: number;
    IsActive: boolean;
}

export interface IPropertyCoreDetailPayload extends Partial<IPropertyCoreDetailBasic> {
    InsuranceTypes?: IPropertyInsuranceTypesPayloadItem[];
}

