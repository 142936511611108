import { secureHttp } from '../utils/request';
import { ReviewCardsOrder, IReviewCardOrder, ReviewCardsOrderProperty, IReviewCardProperty } from '../interfaces/review_cards';
import { IDataTableOptions } from '../components/common/data_table';
import { DataRows } from '../interfaces/common';

export const create = async (order: ReviewCardsOrder): Promise<number> => {
    return (await secureHttp.post('/postalcards/orders', order)).data.postalCardsOrderID;
};

export const getOrders = async (filter: any, dataTableOptions: IDataTableOptions): Promise<DataRows<IReviewCardOrder>> => {
    try {
        const {data: {data}} = await secureHttp.get('/postalcards/orders', {params: {...filter, ...dataTableOptions}});
        return {
            data: data.data,
            totalRows: data.totalRows
        } as DataRows<IReviewCardOrder>;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getOrder = async (orderID: number, dataTableOptions: IDataTableOptions = {}): Promise<DataRows<ReviewCardsOrderProperty>> => {
    try {
        const {data: {data}} = await secureHttp.get(`/postalcards/orders/${orderID}`, {params: {...dataTableOptions}});
        return {
            data: data.data,
            totalRows: data.totalRows
        } as DataRows<ReviewCardsOrderProperty>;
    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getOrgProperties = async (orgID: number, dataTableOptions: IDataTableOptions, filters: Map<string, any>)
    : Promise<DataRows<IReviewCardProperty>> => {

    try {
            
        const params = {
            ...dataTableOptions, 
            ...Object.fromEntries(filters)
        };

        const data = await secureHttp.get(`/postalcards/property/${orgID}`, { params });
        return data.data;

    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const getProperties = async (nhIDs: number[], dataTableOptions: IDataTableOptions, filters?: Map<string, any>): Promise<DataRows<IReviewCardProperty>> => {
    const data = await secureHttp.get(`/postalcards/property?NHIDs=${nhIDs.join()}`, {
        params:
            {
                ...dataTableOptions,
                ...(filters ? Object.fromEntries(filters) : {})
            }
    });
    return data.data;
};

export const deleteOrder = async (orderID: number): Promise<void> => {
    await secureHttp.delete(`/postalcards/orders/${orderID}`);
};
