import { getSessionData } from '../api/session';
import { store } from '../../src/store';
import { setCurrentSession } from '../store/actions/session_actions';
import { logout } from '../modules/login';

export const updateSessionData = async () => {

    try {
        const data = await getSessionData();

        if (data) {
            const payload = {
                Session: data.Session,
                Org: data.Org,
                Properties: data.Properties,
                User: data.User
            };
            store.dispatch(setCurrentSession(payload));
        }
    } catch(err) {
        console.log(err);
        logout();      
        window.location.reload();
    }
};