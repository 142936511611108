import React from 'react';
import { useRef } from 'react';
import moment from 'moment';
import ReviewListReplyButton from '../../components/reviews/review_list_reply_button';
import VisibleRatingStars from '../../components/common/visible_rating_stars';
import { getWebsiteUrlDomain } from '../../utils/common';

interface IProps {
    data: any;
    published?: boolean;
    setActiveReply?: any;
    setHasReply?: any;
}

const ReviewTableExpandableRows = ({ data, published, setActiveReply, setHasReply }: IProps) => {

    const ref = useRef();
    const inViewport = true; //intersectionObserverAvailable() ? useIntersection(ref) : true; 

    const renderReviewContent = () => {

        if (!data.ReviewContent) return <></>;

        return (
            <p className="review-review mt-3">
                <strong>Review:</strong> { data.ReviewContent }
            </p>
        );
    };

    const renderRatings = () => {

        if (!data.ReviewRating || !data.ReviewRating.length) return <></>;

        return (
            <ul className="review-ratings ratings-grid pb-20">
                {
                    data.ReviewRating.map((rating: any) => {
                        return (
                            <li key={rating.RatingName}>
                                <span className="rating-title">{ rating.RatingName }</span>
                                {
                                    rating.RatingValueName === 'N/A'
                                    ? <div className="d-inline-flex">no rating given</div>
                                    :                                     
                                    <VisibleRatingStars
                                        keyProp={rating.RatingName}
                                        value={rating.RatingValue}
                                    />
                                }
                            </li>
                        );
                    })
                }
            </ul>
        );
    };

    const renderSubmittedBy = () => {
        return (
            <>
                {
                    !data.ExperienceTypeName
                    ? <></>
                    : <p className="review-reviewer mt-3">
                        <strong>Reviewer Experience Type: </strong>
                        { data.ExperienceTypeName } 
                    </p>
                }
                {
                    !data.ConnectionName
                    ? <></>
                    : <p className="review-reviewer mt-3">
                        <strong>Reviewer relationship to the resident or client: </strong>
                        { data.ConnectionName }
                    </p>
                }
            </>
        );
    };

    const renderResponse = () => {

        const reply = data.Reply;

        if (reply) {
            return (
                <p className="review-reply mt-3"><strong>Reply:</strong> { reply.ReplyContent } 
                { 
                    reply.AuxiliaryStatusKeyName === 'awaiting-moderation' ?
                        <strong>&nbsp;( Awaiting Moderation )</strong> : null
                } 
                </p> 
            );

        } else {
            return (
                <p className="review-reply mt-3"><strong>Reply:</strong> Not Present</p> 
            );
        }
    };

    const renderPropertyAddress = () => {
        const { Name } = data;
        const keys = [
            'PropertyAddress1', 
            'PropertyAddress2', 
            'City', 
            'StateCode', 
            'Zip' 
        ];

        const address =  keys.reduce((acc: string, key: string, index: number) => acc + 
            (data[key] ? data[key] + `${index < keys.length-1 ? ', ' : ''}` : ''), '');

        return (
            <>
                <p>
                    <strong>{ Name }</strong>
                    &nbsp;{ address }
                    &nbsp;{ renderWebsiteUrl() }
                </p> 
            </>
        );
    };

    const renderWebsiteUrl = () => {
        const { ReviewID, WebsiteUrl, StateSlug, CitySlug, PropertySlug } = data;

        if (!WebsiteUrl || !StateSlug || !CitySlug || !PropertySlug) {
            return null; 
        }

        return (
            <a key={`review-property-link-${ReviewID}`} href={`${WebsiteUrl}/${StateSlug}/${CitySlug}/${PropertySlug}/`} target="_blank" rel="noreferrer">
                View on { getWebsiteUrlDomain(WebsiteUrl) }
            </a>            
        );

    };

    const renderReviewHeadings = () => {

        return (
            <>
                <p className="review-name mt-3">
                    <strong>Display Name:</strong> { data.ReviewerDisplayName }
                </p>
                <p className="review-submitted mt-3">
                    <strong>Submitted Date:</strong> { moment(data.CreatedAt).format('MMMM Do YYYY h:mm a') }
                </p>
                <p className="review-published mt-3">
                    <strong>{ published ? 'Publication Date: ' :  'Reason Not Published: ' }</strong> { published ? moment(data.LastPublishedDate).format('MMMM Do YYYY h:mm a') : data.AuxiliaryStatusName }
                </p> 
            </>
        );
    };

    const render = () => {

        return (
            <>
                <div className="p-4">
                    { renderPropertyAddress() }
                    { renderReviewHeadings() }
                    { renderReviewContent() }
                    { renderSubmittedBy() }
                    { renderResponse() }
                    {
                        published ?             
                        <ReviewListReplyButton
                            data={data}
                            setActiveReply={setActiveReply}
                            setHasReply={setHasReply}
                        /> : null
                    }
                </div>
                { renderRatings() }
            </>
        );
    };

    return (
        <div className="d-flex justify-content-between flex-column flex-md-row" ref={ref}>
            {
                inViewport ? render() : null
            }
        </div>
    );
};
export default ReviewTableExpandableRows;
