import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { resetPassword, resetPasswordTokenValid } from '../../api/login';
import { sendActivationEmail } from '../../api/user'; 
import { Toast, showToast, ToastType } from '../../utils/toast';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import _ from 'lodash';

interface FormInputs {
    Password: string;
    ConfirmPassword: string;
}

interface IProps {
    mode: string;
}

interface IModeData {
    title: string;
    passwordLabel: string;
    successTitle: string;
    successText: string;
    btnText: string;
}

const PasswordReset = ({ mode }: IProps) => {

    const { token }  = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [validToken, setValidToken] = useState<boolean>(true);
    const [emailAddress, setEmailAddress] = useState<string | null>(null);
    const [userID, setUserID] = useState<number | null>(null);

    const navigate = useNavigate();

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues 
    } = useForm<FormInputs>();

    useEffect(() => {

        const testToken = async(token: string) => {
            const resp: any = await resetPasswordTokenValid(token);
            if (!resp?.data) {
                setValidToken(false);
                setEmailAddress(null);
                return;
            }

            const {Token: returnedToken, EmailAddress, UserID} = resp.data;
            setValidToken(!!returnedToken);
            setEmailAddress(EmailAddress);
            setUserID(UserID);
        };

        testToken(token);

    }, [token]);


    const buildModeData = (): IModeData => (

        mode === 'activate' ? {
            title: 'Activate Your Account',
            passwordLabel: 'Password',
            successTitle: 'Account Successfully Activated',
            successText: 'Your Account has been activated',
            btnText: 'Activate Account'


        } : {
            title: 'Reset Your Password',
            passwordLabel: 'New Password',
            successTitle: 'Password Successfully Reset',
            successText: 'Your password has been reset',
            btnText: 'Reset Password'
        }
    );

    if (!['activate', 'password_reset'].includes(mode)) {
        return (
            <h6>Invalid Mode {mode}</h6>
        );
    }

    const modeData = buildModeData();

    const onSubmit = async (data: any) => {

        const { Password, ConfirmPassword } = data;

        if (!Password || !ConfirmPassword || Password !== ConfirmPassword) {
            showToast('Passwords do not match', ToastType.Error);
            return;
        }

        setLoading(true);

        try {
            await resetPassword(mode, token, Password);
            showToast(`${modeData.btnText} succeeded`, ToastType.Success, () => {
                navigate('/login');
            });

            setLoading(false);

        } catch (err) {
            const userMsg = _.get(err, 'data.data.UserMsg', 'Set Password Failed');
            showToast(userMsg, ToastType.Error);
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const handleResendActivationEmail = () => {
        if (userID) {
            sendActivationEmail(userID);
        }
    };

    const renderInvalidToken = () => {

        return (
            <div className="card-body">
                <form className="kt-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="kt-portlet__body p-0">
                        <div className="form-group mb-3">
                            <div className="row mb-4">
                                <div className="col-sm-12">
                                    <p>
                                        {`${mode === 'password_reset' ? 'Password Reset' : 'Account Activation'} failed as the link has already been used or has expired.`}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__foot mt-4">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="kt-form__actions col-12 col-sm-12">
                                        <Link 
                                            className="btn btn-primary"
                                            onClick={() => handleResendActivationEmail()}
                                            to={{
                                                pathname: '/login'
                                            }}> 
                                            Resend Activation Email { emailAddress  ? `to ${emailAddress}` : ''}
                                        </Link>
                                    </div>
                                    <div className="kt-form__actions col-12 col-sm-6 mt-4">
                                        <Link 
                                            className="btn btn-primary"
                                            to={{
                                                pathname: '/login'
                                            }}> Return to login
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    const renderForm = () => {

        return (
            <div className="card-body">
                <form className="kt-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="kt-portlet__body p-0">
                        <div className="form-group mb-3">
                            <div className="row mb-4">
                                <div className="col-sm-12">

                                    <p className="mb-4 mt-2">
                                        <small>Passwords must have a minimum length of 8 characters and a maximum length of 32</small>
                                    </p>

                                    <label htmlFor="password" className="mb-2">{modeData.passwordLabel}</label>
                                    <input 
                                        {
                                            ...register('Password', {
                                                required: 'Please enter your password',
                                            })
                                        }
                                        type="password"
                                        id="password"
                                        className={`form-control ${errors.Password ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="Password"
                                        render={({ message }: any) => <span className="form-error">{message}</span>}
                                    />

                                </div>
                            </div>
                            <div className="row mb-8 my-5">
                                <div className="col-sm-12">
                                    <label htmlFor="password" className="mb-2">Confirm Password</label>
                                    <input
                                        {
                                            ...register('ConfirmPassword', {
                                                required: 'Please enter your password',
                                                validate: value => value === getValues().Password
                                            })
                                        }
                                        type="password"
                                        id="confirm-password"
                                        className={`form-control ${errors.ConfirmPassword ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="ConfirmPassword"
                                        render={() => <span className="form-error">Passwords do not match</span>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__foot mt-4">
                            <div className="kt-form__actions">
                                <div className="row align-items-center">
                                    <div className="col-12 col-sm-6">
                                        <button type="submit" className="btn btn-primary">
                                            {modeData.btnText}
                                        </button>
                                    </div>
                                    <div className="kt-form__actions mt-3 mt-md-0 text-sm-end col-12 col-sm-6">
                                        <Link to={{
                                            pathname: '/login'
                                        }}> Return to login
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    return (
        <>
        { loading ? <LoadingSpinner show={true}/> : null }
        <Toast></Toast>
        <div className="container-fluid public public__login">
            <div className="row">
                <div className="col-12">
                    <div className="widget mt-3 mt-md-5">
                        <div className="widget-body">
                            <div className="card">
                                <div className="card-header border-bottom">
                                    <a href="/" className="d-flex flex-row align-items-end text-secondary">
                                        <img src="/logo.svg" height="30px" className="logo-large" alt="Portal" />
                                        <span className="d-block ms-3">Portal</span>
                                    </a>
                                </div>
                                <div className="card-body">
                                    { validToken ? renderForm() : renderInvalidToken() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default PasswordReset;