import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleQuestion,
    faUser,
    faRightFromBracket,
    faPlus,
    faEquals,
} from '@fortawesome/pro-light-svg-icons';
import {
    faFilter
} from '@fortawesome/pro-thin-svg-icons';
import HeaderSessionText from '../../components/common/header_session_text';
import { logout } from '../../modules/login';
import HeaderContactInfo from '../common/header_contact_info';

import { secureHttp } from '../../utils/request';
import { ENV } from '../../config/keys';
import { ISessionProperty, ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import _ from 'lodash';
// import ReviewScoreHeaderCarousel from '../reviews/review_score_header_carousel';

const Header = () => {
    const [ showInfoModal, setShowInfoModal ] = useState<boolean>(false);

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );
    
    const navigate = useNavigate();

    const firstName = _.get(sessionState, 'User.FirstName', '');
    const lastName = _.get(sessionState, 'User.LastName', '');

    const renderSubscriptionLink = () => {
        const { Org, Properties } = sessionState || {};

        if (!Properties?.[0]?.WebsiteURL) {
            return;
        }

        const showAsPremium = Org ? Org.IsPremium : Properties.every((property: ISessionProperty) => property.IsPremium);

        return (
            <>
                <div className="app-header-subscription">
                    <span>Current Subscription:</span> 
                    <a href={`${Properties[0].WebsiteURL}/subscribe/`} target='_blank' rel="noreferrer">{ showAsPremium ? 'Premium' : 'Basic'}</a>
                </div>
            </>
        );
    };


    return (
        <div className="app-header d-flex order-lg-1">
            <div className="app-header-bottom">
                <div className="app-header-logo d-none d-lg-flex align-items-center">
                    <a href="/" className="d-flex flex-column align-items-center text-secondary">
                        <img src="/logo.svg" className="logo-large" alt="Portal" />
                        <img src="/logo_bird.svg" className="logo-small" alt="Portal" />
                        <small>Portal</small>
                    </a>
                </div>
                <div className="app-header-name order-3 order-lg-1 ">
                    <div className="app-header-name__title d-flex flex-column gap-1">
                        <HeaderSessionText/>
                    </div>
                    <div className="app-header-name__banner d-flex justify-content-center flex-fill">
                        {/*<div>
                            <span>
                                A Complete Profile
                            </span>
                            <div className="profile_completeness_single_chart">
                                <svg viewBox="0 0 36 36" className="profile_completeness_circular_chart green">
                                    <path className="profile_completeness_circle_bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                    <path className="profile_completeness_circle" strokeDasharray="100,100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                    <text x="18" y="11.2" className="profile_completeness_percentage-before">Profile</text>
                                    <text x="18" y="22" className="profile_completeness_percentage">100<tspan>%</tspan></text>
                                    <text x="18" y="27" className="profile_completeness_percentage-after">Complete</text>
                                </svg>
                            </div>
                            <FontAwesomeIcon icon={faPlus} />
                            <span>
                                A High Review Score
                            </span>
                            <div className="review-score__score">
                                10
                            </div>
                            <FontAwesomeIcon icon={faEquals} />
                            <span>
                                <FontAwesomeIcon icon={faFilter} />
                                <strong>More Leads</strong>
                            </span>
                        </div>*/}
                    </div>
                </div>

                <div className="app-header-support order-1 order-lg-2">
                    <div className="app-header-support-help justify-content-end">
                        {/*<div className="permission-label__reviews fs-6 me-2"> Reply to Reviews <span>3</span></div>
                        <div className="permission-label__notifications fs-6 me-2"> Review Notifications <span>6</span></div>*/}
                        { renderSubscriptionLink() }
                        <button className="btn btn-link text-body fw-bold" onClick={() => setShowInfoModal(true)}><FontAwesomeIcon icon={faCircleQuestion} className="text-primary me-2"/> Help</button>
                    </div>
                    <div className="app-header-support-block justify-content-end d-none d-lg-flex"> 
                        <div className="app-header-login">
                            <strong>{firstName} {lastName}</strong><FontAwesomeIcon icon={faUser} />
                            <a 
                                href='#'
                                onClick={() => {
                                    logout();      
                                    navigate(0);     
                                }}
                            >
                                <FontAwesomeIcon icon={faRightFromBracket} />
                            </a>
                            {
                                ENV !== 'production' ?
                                <a 
                                    href='#'
                                    onClick={() => {
                                        secureHttp.get('/auth/expireToken');
                                    }}
                                >
                                    Expire JWT
                                </a> : null
                            }
                        </div>              
                    </div>
                </div>
            </div>

            <HeaderContactInfo
                show={showInfoModal}
                handleClose={() => setShowInfoModal(false)}
            />
        </div>
    );
};

export default Header;
