import Select, { StylesConfig, ClassNamesConfig } from 'react-select';
import { ConnectForm } from '../form/form';
import React, { FC, InputHTMLAttributes, ReactElement } from 'react';
import cx from 'classnames';

type Option = { label: string; value: any };

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
    label?: string;
    name: string;
    options: Option[];
    isMulti?: boolean;
    inline?: boolean;
}

const FormSelect: FC<Props> = (props) => {
    const { name, id, label, options, inline = true, ...rest } = props;

    return (
        <ConnectForm>
            {(methods: any) => {
                const { register, formState, setValue, watch, isRequired } =
                    methods;
                const fieldError = formState?.errors?.[name];
                const inputId: string = id || name;
                const currentSelectedValue = watch(name);

                const errorMessage: ReactElement = (
                    <small className="text-danger position-absolute">
                        {fieldError?.message}
                    </small>
                );

                const selectInputStyles: StylesConfig = {
                    control: (baseStyles, { isFocused }) => ({
                        ...baseStyles,
                        boxShadow: isFocused
                            ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)'
                            : 'none',
                        borderRadius: 'var(--bs-border-radius)'
                    })
                };

                const selectInputClassNames: ClassNamesConfig = {
                    control: ({ isFocused }) =>
                        cx('border fs-5', {
                            'border-info': isFocused && !fieldError,
                            'border-danger': !!fieldError
                        }),
                    option: () => 'fs-5'
                };

                const filterValue: Option[] = options.filter(
                    ({ value }: Option) =>
                        value === Number(currentSelectedValue)
                );

                const inputWrapperClassName = cx({
                    'mt-3': label,
                    row: inline
                });

                const labelClassName = cx('fw-label fs-5', {
                    'col-sm-4 col-lg-3 d-flex align-items-center m-0': inline,
                    'mb-1': !inline
                });
                return (
                    <div
                        className={inputWrapperClassName}
                        style={fieldError ? { marginBottom: '30px' } : {}}
                    >
                        {label && (
                            <label htmlFor={inputId} className={labelClassName}>
                                {label}
                                {isRequired(name) && '*'}
                            </label>
                        )}
                        <div
                            className={cx(
                                'position-relative',
                                { 'col-sm-8 col-lg-9': inline && label },
                                { 'col-sm-12': inline && !label }
                            )}
                        >
                            <Select
                                {...register(name)}
                                {...rest}
                                styles={selectInputStyles}
                                classNames={selectInputClassNames}
                                id={inputId}
                                options={options}
                                value={filterValue}
                                onChange={(val: Option) =>
                                    setValue(name, val?.value)
                                }
                            />
                            {fieldError && errorMessage}
                        </div>
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormSelect };
