import {
    IScoreDisplayItem,
    IScoreExtraDisplayItem,
    INhPortalScoreItem,
    INhPropertyScoresItem
} from '../interfaces/property';
import { store } from '../../src/store';
import { getPropertyProfileCompleteness } from '../api/property';
import { setProfileCompleteness } from '../store/actions/profile_completeness_actions';
import { 
    ProfileScoreType, 
    IProfileCompleteItem, 
    INhPropertyScores 
} from '../interfaces/property';

const linkMap = new Map<string, string>([
    [ProfileScoreType.Description, '/descriptions'],
    [ProfileScoreType.Photo, '/photos'],
    [ProfileScoreType.Video, '/videos'],
    [ProfileScoreType.MeetTheTeam, '/team'],
    [ProfileScoreType.FacilityBaseData, '/core-details'],
    [ProfileScoreType.ServicesAndAmenities, '/amenities']
]);

export const updateProfileCompleteness = async () => {

    const profileData = await getPropertyProfileCompleteness();

    if (profileData) {
        store.dispatch(setProfileCompleteness(profileData));
    }
};

export const getOrgProfileScoreFromProfileData = (data: INhPortalScoreItem) => 
    data ? data.TotalOrgProfileScore : 0;

export const getFacilityCountFromProfileData = (data: INhPortalScoreItem) => 
    data?.PropertyScores?.length;

export const mapProfileDatatoDisplay = (data: INhPortalScoreItem, selNHID: number | null)
    :IScoreDisplayItem[] => {

    const propertyScores = selNHID ? data.PropertyScores.filter((item: any) => item.NHID === selNHID) : data.PropertyScores;

    const metScore = (item: INhPropertyScoresItem) => item.Score && item.Score === item.AvailableScore;

    const propScoreMap = new Map<string, IScoreDisplayItem>();
    for (const score of propertyScores) {

        score.Scores.forEach((item: INhPropertyScoresItem) => {
            if (item.KeyName !== ProfileScoreType.Total) {
                if (propScoreMap.has(item.KeyName)) {
                    const dets = propScoreMap.get(item.KeyName);
                    dets.TotalScore += item.Score;
                    dets.TotalAvailableScore += item.AvailableScore;
                    dets.AchievedScoreCount += metScore(item) ? 1 : 0;
                } else {
                    propScoreMap.set(item.KeyName, {
                        TotalScore: item.Score,
                        AvailableScore: item.AvailableScore,
                        Label: item.FieldLabel,
                        KeyName: item.KeyName,
                        Description: item.FieldDescription,
                        FacilityCount: propertyScores.length,
                        AchievedScoreCount: metScore(item) ? 1 : 0,
                        TotalAvailableScore: item.AvailableScore,
                        ScorePC: 0,
                        InternalURL: linkMap.get(item.KeyName) || '/'
                    });
                }
            }
        });
    }

    const scoreArray: IScoreDisplayItem[] = Array.from(propScoreMap, ([, value ]) => value);
    scoreArray.forEach((scoreItem: IScoreDisplayItem) => scoreItem.ScorePC = scoreItem.TotalAvailableScore > 0 ? 
        Math.round(((scoreItem.TotalScore/scoreItem.TotalAvailableScore)*scoreItem.AvailableScore)) : 0);

    return scoreArray;
};

export const mapProfileExtraDatatoDisplay = (data: INhPortalScoreItem, selNHID: number | null)
    :IScoreExtraDisplayItem => {

    const propertyScores = selNHID ? data.PropertyScores.filter((item: any) => item.NHID === selNHID) : data.PropertyScores;

    const scoreExtraDisplay: IScoreExtraDisplayItem = {
        FacilityCount:  propertyScores.length,
        HasPhoneNumberCount: 0,
        HasEmailAddressCount: 0,
        HasWebsiteCount: 0,
        HasPortalUsersCount: 0,
        HasLogoCount: 0,
        HasPDFBrochureCount: 0,
        InternalURL: ''
    };

    for (const score of propertyScores) {

        const {
            HasPhoneNumber,
            HasEmailAddress,
            HasWebsite,
            HasPortalUsers,
            HasLogo,
            HasPDFBrochure
        } = score.ScoreExtras;

        scoreExtraDisplay.HasPhoneNumberCount += HasPhoneNumber ? 1 : 0;
        scoreExtraDisplay.HasEmailAddressCount += HasEmailAddress ? 1 : 0;
        scoreExtraDisplay.HasWebsiteCount += HasWebsite ? 1 : 0;
        scoreExtraDisplay.HasPortalUsersCount += HasPortalUsers ? 1 : 0;
        scoreExtraDisplay.HasLogoCount += HasLogo ? 1 : 0;
        scoreExtraDisplay.HasPDFBrochureCount += HasPDFBrochure ? 1 : 0;
    }

    return scoreExtraDisplay;
};

export const getTotalScoreForNHIDFromProfileData = (data: INhPortalScoreItem, NHID: number): number => {

    const scoreForNHID = (data?.PropertyScores || []).find((scoreItem: any) => scoreItem.NHID === NHID);
    const totalScoreItem = (scoreForNHID?.Scores || []).find((item: any) => item.KeyName === ProfileScoreType.Total);

    return totalScoreItem ? totalScoreItem.Score : 0;
};

export const getAverageScoreForNHIDsFromProfileData = (data: INhPortalScoreItem, NHIDs: number[]): number => {

    const totalScore = (NHIDs || []).reduce((acc: number, val: number) => {
        const scoreForNHID = (data?.PropertyScores || []).find((scoreItem: any) => scoreItem.NHID === val);
        const totalScoreItem = (scoreForNHID?.Scores || []).find((item: any) => item.KeyName === ProfileScoreType.Total);

        return totalScoreItem ? acc + totalScoreItem.Score : acc;
    }, 0);

    return NHIDs?.length ? Math.round( totalScore / NHIDs.length ) : 0;
};

export const buildProfileCompleteMapForNHIDs = (
    profileCompletenessState: INhPortalScoreItem
): Map<number, IProfileCompleteItem> => {

    const isScoreComplete = (scoreItem: INhPropertyScoresItem) => (
        scoreItem.Score === scoreItem.AvailableScore
    );

    const map = new Map<number, IProfileCompleteItem>();

    (profileCompletenessState?.PropertyScores || []).forEach((scoreItem: INhPropertyScores) => {

        const profileCompleteItem = {
            NHID: scoreItem.NHID,
            FacilityBaseDataComplete: false,
            ServicesAndAmenitiesComplete: false,
            DescriptionComplete: false,
            PhotoComplete: false,
            VideoComplete: false,
            MeetTheTeamComplete: false,
            TotalComplete: false,
        };

        (scoreItem?.Scores || []).forEach((item: INhPropertyScoresItem) => {

            switch(item.KeyName) {
                case ProfileScoreType.FacilityBaseData:
                    profileCompleteItem.FacilityBaseDataComplete = isScoreComplete(item);
                    break;
                case ProfileScoreType.ServicesAndAmenities:
                    profileCompleteItem.ServicesAndAmenitiesComplete = isScoreComplete(item);
                    break;
                case ProfileScoreType.Description:
                    profileCompleteItem.DescriptionComplete = isScoreComplete(item);
                    break;
                case ProfileScoreType.Photo:
                    profileCompleteItem.PhotoComplete = isScoreComplete(item);
                    break;
                case ProfileScoreType.Video:
                    profileCompleteItem.VideoComplete = isScoreComplete(item);
                    break;
                case ProfileScoreType.MeetTheTeam:
                    profileCompleteItem.MeetTheTeamComplete = isScoreComplete(item);
                    break;
                case ProfileScoreType.Total:
                    profileCompleteItem.TotalComplete = isScoreComplete(item);
                    break;
            }
        });

        map.set(profileCompleteItem.NHID, profileCompleteItem);
    });

    return map;
};
