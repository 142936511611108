import { numberRange } from '../../../../../utils/common';
import { MeetTheTeamMemberPayload, MeetTheTeamMemberType } from '../../types';

const buildDateOptions = () => {
    const YEARS_IN_TEAM_REASONABLE_LIMIT = 50;
    const currentYear = new Date().getFullYear();
    const earliestStartYear = currentYear - YEARS_IN_TEAM_REASONABLE_LIMIT;

    const years = numberRange(currentYear, earliestStartYear, -1);

    return years.map((year) => ({ label: `${year}`, value: year }));
};

interface Facility {
    NHID: number;
    Name: string;
}

type FacilityOption = { label: string; value: number };

const buildPropertyOptions = (properties: Facility[]): FacilityOption[] =>
    properties.map((property) => ({
        value: property.NHID,
        label: property.Name
    }));

type CreateMemberPayloadValues = Omit<
    MeetTheTeamMemberType,
    'propertyId' | 'sequence'
>;

const prepareCreateTeamMemberPayload = (
    initialValues: CreateMemberPayloadValues
): MeetTheTeamMemberPayload => {
    return {
        Description: initialValues.description,
        FirstName: initialValues.firstName,
        LastName: initialValues.lastName,
        Position: initialValues.position,
        ProfileImageAssetID: initialValues.profileImageAssetId,
        PublishWebsite: initialValues.publishWebsite === 'yes',
        YearStarted: initialValues.yearStarted
    };
};

type UpdateMemberPayloadValues = Omit<MeetTheTeamMemberType, 'sequence'>;

const prepareUpdateTeamMemberPayload = (
    initialValues: UpdateMemberPayloadValues
): MeetTheTeamMemberPayload & { UpdatedPropertyID: number } => {
    return {
        UpdatedPropertyID: initialValues.propertyId,
        Description: initialValues.description,
        FirstName: initialValues.firstName,
        LastName: initialValues.lastName,
        Position: initialValues.position,
        ProfileImageAssetID: initialValues.profileImageAssetId,
        PublishWebsite: initialValues.publishWebsite === 'yes',
        YearStarted: initialValues.yearStarted
    };
};

export {
    prepareUpdateTeamMemberPayload,
    buildDateOptions,
    buildPropertyOptions,
    prepareCreateTeamMemberPayload
};
