import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import { LoadingSpinner } from '../common/loading_spinner';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { 
    INhPortalScoreItem,
    IScoreDisplayItem,
    IScoreExtraDisplayItem
} from '../../interfaces/property';

import { 
    mapProfileDatatoDisplay,
    getOrgProfileScoreFromProfileData,
    getFacilityCountFromProfileData,
    getTotalScoreForNHIDFromProfileData,
    mapProfileExtraDatatoDisplay,
    getAverageScoreForNHIDsFromProfileData
} from '../../utils/profile_completeness';

import { get } from 'lodash';
import { PropertyMode } from '../../interfaces/session';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number | null;
    setIsLoading?: any;
    isLoading?: boolean;
    propertyMode: PropertyMode,
    widgetSize: 'small' | 'large';
}

export const ProfileCompletnessWidget = ({  
    nhIDs, 
    orgNHID, 
    selNHID, 
}: IProps) => {
    
    const [ scoreArray, setScoreArray ] = useState<IScoreDisplayItem[]>([]);
    const [ scoreExtra, setScoreExtra ] = useState<IScoreExtraDisplayItem>();

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    useEffect(() => {        
        const scoreArray = mapProfileDatatoDisplay(profileCompletenessState, selNHID);
        const scoreExtra = mapProfileExtraDatatoDisplay(profileCompletenessState, selNHID);
        setScoreExtra(scoreExtra);
        setScoreArray(scoreArray);
}, [ orgNHID, nhIDs, selNHID, profileCompletenessState ]);

    const renderWidget = () => {

        const { Properties }: ISessionState = useSelector(
            ({ SessionState }: any) => SessionState
        );
        
        const singleNHID = selNHID || (nhIDs?.length === 1 ? nhIDs[0] : null);
        const facilityCount = selNHID ? 1 : getFacilityCountFromProfileData(profileCompletenessState);
        const orgIsSelected = orgNHID && !selNHID;

        if (!scoreExtra) {
            return <LoadingSpinner/>;
        }

        const score = singleNHID ? getTotalScoreForNHIDFromProfileData(profileCompletenessState, singleNHID) : orgNHID 
            ? getOrgProfileScoreFromProfileData(profileCompletenessState)  
            : getAverageScoreForNHIDsFromProfileData(profileCompletenessState, nhIDs);

        const { HasLogoCount, HasPDFBrochureCount } = scoreExtra;

        const renderOrgText = () => {

            const name = get(Properties, '[0].OrgName', '');

            return (
                <>
                    <strong>The Profile Completeness Score for {name} is {score}<tspan>%</tspan></strong>
                    <p className="mt-2"><span className="fw-bold text-success">x/x%</span> shows the % value/attainable for profile completeness per content type</p>
                    <p className="mt-2"><span className="badge badge-light text-primary fs-6 fw-normal">x/x</span> shows the number of Facilities that are profile complete per content type</p>
                </>
            );
        };

        const renderMultiText = () => {

            if (!nhIDs?.length) {
                return null;
            } 

            const avgProfileScore = getAverageScoreForNHIDsFromProfileData(profileCompletenessState, nhIDs);

            return (
                <>
                    <strong>The Profile Completeness Score for All {nhIDs.length} Facilities is {avgProfileScore}<tspan>%</tspan></strong>
                    <p className="mt-2"><span className="fw-bold text-success">x/x%</span> shows the % value/attainable for profile completeness per content type</p>
                    <p className="mt-2"><span className="badge badge-light text-primary fs-6 fw-normal">x/x</span> shows the number of Facilities that are profile complete per content type</p>
                </>
            );
        };

        const renderSingleNHIDText = () => {

            const property = Properties.find((property: any) => property.NHID === singleNHID);

            if (!property) {
                return null;
            }

            return (
                <>
                    <strong>The Profile Completeness Score for {property.Name} is {score}<tspan>%</tspan> </strong>
                    <p className="mt-2"><span className="fw-bold text-success">x/x%</span> shows the % value/attainable for profile completeness per content type</p>
                </>
            );
        };

        return (
            <>
                <div className="d-flex flex-col align-items-center pb-4 border-bottom">
                    <div className="profile_completeness_single_chart pe-4">
                        <svg viewBox="0 0 36 36" className="profile_completeness_circular_chart green">
                            <path className="profile_completeness_circle_bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <path className="profile_completeness_circle" strokeDasharray={`${score},100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                            <text x="18" y="11.2" className="profile_completeness_percentage-before">Profile</text>
                            <text x="18" y="22" className="profile_completeness_percentage">{score}<tspan>%</tspan></text>
                            <text x="18" y="27" className="profile_completeness_percentage-after">Complete</text>
                        </svg>
                    </div>
                    <div>
                        { 
                            singleNHID ? renderSingleNHIDText() : orgNHID ? renderOrgText()
                                : renderMultiText() 
                        }
                    </div>
                </div>
                <table className="table table-row-bordered table-row-gray-300 align-middle gx-0 gy-3 mb-0 transparent">
                    <tbody>
                        {
                            scoreArray.map((score: IScoreDisplayItem) => (
                                <tr>
                                    <th>
                                        {
                                                score.AchievedScoreCount === facilityCount ? 
                                                    <div className="d-flex align-items-center text-success fw-bold">
                                                    <FontAwesomeIcon icon={faCheck} className="me-2" /> {score.ScorePC}/{score.AvailableScore}%
                                                </div> 
                                                : 
                                                <div className="d-flex align-items-center text-danger fw-bold">
                                                    <FontAwesomeIcon icon={faXmark} className="me-2" /> {score.ScorePC}/{score.AvailableScore}%
                                                </div> 
                                        }
                                    </th>
                                    <td className="w-58">
                                        <div className="d-flex">
                                        {
                                            facilityCount > 1 ?
                                                <a className="text-link text-nowrap" href={score.InternalURL}>
                                                    <span className="badge badge-light text-primary fs-5 fw-normal text-primary">
                                                        {score.AchievedScoreCount}/{facilityCount}
                                                    </span> 
                                                </a>
                                                : <>&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        }
                                        </div>
                                    </td> 
                                    <td>
                                        <div className="text-primary">
                                            <a className="text-link text-nowrap" href={score.InternalURL}>
                                                <span className="pe-2">{score.Label}</span>
                                            </a>
                                            <a id={`${score.KeyName}`} className="ml-auto text-link tooltip-link px-2 badge badge-light fs-5 fw-normal text-primary" ><small>criteria</small></a>
                                            <Tooltip anchorSelect={`#${score.KeyName}`} className="tooltip">
                                                {score.Description} <br/>({score.AvailableScore}% of the total Profile Completeness Score is attributed to {score.Label})
                                            </Tooltip>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-root d-flex justify-content-center text-primary">
                                            <a className="text-link text-nowrap" href={score.InternalURL}>View</a>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td colSpan={4}>
                                <strong className="mt-4 d-block">
                                    NOT included in Profile Completeness
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {
                                    orgIsSelected || facilityCount >= 1 ?
                                    <a className="text-link text-nowrap" href="/logos">
                                        <div className={`d-flex align-items-center ${ HasLogoCount === facilityCount ? 'text-success' : 'text-danger'} fw-bold`}>
                                            <FontAwesomeIcon icon={ HasLogoCount === facilityCount ? faCheck : faXmark} className="me-2" />
                                        </div>
                                    </a> : null
                                }
                            </th>
                            <td>
                                <div className="d-flex">
                                {
                                    facilityCount > 1 ?
                                        <a className="text-link text-nowrap" href="/logos">
                                            <span className="badge badge-light text-primary fs-5 fw-normal">
                                                {HasLogoCount}/{facilityCount}
                                            </span>
                                        </a> : null
                                }
                                </div>
                            </td>
                            <td>
                                <div className="text-primary">
                                    <a className="text-link text-nowrap" href="/logos">
                                        <span className="pe-4">Logo</span>
                                    </a>
                                </div>
                            </td>

                            <td>
                                <div className="flex-root d-flex justify-content-center text-primary">
                                    <a className="text-link text-nowrap" href="/logos">View</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {
                                    orgIsSelected || facilityCount >= 1 ?
                                    <a className="text-link text-nowrap" href="/brochures">
                                        <div className={`d-flex align-items-center ${ HasPDFBrochureCount === facilityCount ? 'text-success' : 'text-danger'} fw-bold`}>
                                            <FontAwesomeIcon icon={ HasPDFBrochureCount === facilityCount ? faCheck : faXmark} className="me-2" />
                                        </div>
                                    </a> : null
                                }
                            </th>
                            <td>
                                <div className="d-flex">
                                {
                                    facilityCount > 1 ?
                                        <a className="text-link text-nowrap" href="/brochures">
                                            <span className="badge badge-light text-primary fs-5 fw-normal">
                                                {HasPDFBrochureCount}/{facilityCount}
                                            </span>
                                        </a> : null
                                }
                                </div>
                            </td>
                            <td>
                                <div className="text-primary">
                                    <a className="text-link text-nowrap" href="/brochures">
                                        <span className="pe-4">PDF Brochure</span>
                                    </a>
                                </div>
                            </td>

                            <td>
                                <div className="flex-root d-flex justify-content-center text-primary">
                                    <a className="text-link text-nowrap" href="/brochures">View</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
        );    
    };

    return (
        <>
            { renderWidget() }
        </>
    );
};
