import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import makeAnimated from 'react-select/animated';
import ActiveFilters from '../common/active_filters';

const animatedComponents = makeAnimated();

type Props = {
    templateTypes: any[];
    pageOffset: number;
    pageLimit: number;
    totalRows: number;
    filterMatrix: any;
    data: any[];
    applyFilter(key: string, value: any, refreshData?: boolean): void;
    resetFilters(): void;
};

export default function LeadEmailFilters(props: Props) {
    return (
        <div className="container-fluid py-4">
            <div className="row mb-4">
                <div className="col-md-4">
                    <div className="d-flex align-items-center me-4">
                        <label className="text-nowrap me-4">Inquiry Type</label>
                        <Select 
                            key="TemplateTypeID"
                            className="w-100"
                            options={props.templateTypes}
                            placeholder="Select Inquiry Type..."
                            components={animatedComponents}
                            onChange={(option: any) => props.applyFilter('TemplateTypeID', [option], true)}
                            value={
                                props.filterMatrix.getFormFieldValue('TemplateTypeID')?.length 
                                ? props.templateTypes?.find((item: any) => item.value === props.filterMatrix.getFormFieldValue('TemplateTypeID')[0].value)
                                : ''
                            }
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="d-flex align-items-center me-4">
                        <label className="text-nowrap me-4" htmlFor="SenderName">Inquirer Name</label>
                        <input
                            id="SenderName"
                            type="text"
                            className="form-control"
                            onChange={(e: any) => props.applyFilter('SenderName', e.target.value)}
                            value={props.filterMatrix.getFormFieldValue('SenderName') || ''}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="d-flex align-items-center me-4">
                        <label className="text-nowrap me-4" htmlFor="SenderEmail">Inquirer Email</label>
                        <input
                            id="SenderEmail"
                            type="email"
                            className="form-control"
                            onChange={(e: any) => props.applyFilter('SenderEmail', e.target.value, true)}
                            value={props.filterMatrix.getFormFieldValue('SenderEmail') || ''}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-4">
                    <div className="d-flex align-items-center me-4">
                        <label className="text-nowrap me-4" htmlFor="DateFrom">Date From</label>
                        <DatePicker
                            id="DateFrom"
                            className="form-control"
                            selected={props.filterMatrix.getFormFieldValue('DateFrom') || null}
                            onChange={(date: Date) => {
                                if (date) {
                                    date.setHours(0, 0, 0, 0);
                                }

                                props.applyFilter('DateFrom', date, true);
                            }}
                            dateFormat="dd MMM yyyy"
                            placeholderText="Click to select a date"
                            value={props.filterMatrix.getFormFieldValue('DateFrom')}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="d-flex align-items-center me-4">
                        <label className="text-nowrap me-4" htmlFor="DateTo">Date To</label>
                        <DatePicker
                            id="DateTo"
                            className="form-control"
                            selected={props.filterMatrix.getFormFieldValue('DateTo') || null}
                            onChange={(date: Date) => {
                                if (date) {
                                    date.setHours(0, 0, 0, 0);
                                }

                                props.applyFilter('DateTo', date, true);
                            }}
                            dateFormat="dd MMM yyyy"
                            placeholderText="Click to select a date"
                            value={props.filterMatrix.getFormFieldValue('DateTo')}
                        />
                    </div>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                    <button className="btn btn-primary" onClick={props.resetFilters}>Reset filters</button>
                </div>
            </div>

            {
                props.data && props.data.length
                ? <div className="row">
                    <div className="col-12">
                        <ActiveFilters 
                            filterMatrices={[props.filterMatrix]} 
                            isLoading={false}
                            pageOffset={props.pageOffset}
                            pageLimit={props.pageLimit}
                            singularNoun={'Inquiry'}
                            pluralNoun={'Inquiries'}
                            totalSize={props.totalRows}
                            showTotals={true}
                            applyFilter={props.applyFilter}
                        />
                    </div>
                </div>
                : null
            }
        </div>
    );
}
