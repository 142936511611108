import { object } from 'yup';
import * as yup from 'yup';

export const updateContactObjectSchema = object({
    PhoneNumber: yup
        .number()
        .typeError({
            key: 'PhoneNumber',
            Message: 'Please enter a Valid Phone Number'
        })
        .notRequired()
        .transform((_, val) => (val !== '' ? Number(val) : null)),
    Email: yup
        .string()
        .email({ key: 'Email', Message: 'Please enter a Valid Email Address' })
        .max(255, {
            key: 'Email',
            Message: 'Email must be 255 characters or less'
        })
        .transform((o, c) => (o?.trim() === '' ? null : c))
        .nullable()
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            {
                message: {
                    key: 'Email',
                    Message: 'Please enter a Valid Email Address'
                }
            }
        ),

    Email2: yup
        .string()
        .email({ key: 'Email2', Message: 'Please enter a Valid Email Address' })
        .transform((o, c) => (o?.trim() === '' ? null : c))
        .max(255, {
            key: 'Email2',
            Message: ' Email must be 255 characters or less'
        })
        .nullable()
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            {
                message: {
                    key: 'Email2',
                    Message: 'Please enter a Valid Email Address'
                }
            }
        ),

    Email3: yup
        .string()
        .nullable()
        .email({ key: 'Email3', Message: 'Please enter a Valid Email Address' })
        .transform((o, c) => (o?.trim() === '' ? null : c))
        .max(255, {
            key: 'Emai3',
            Message: 'Email must be 255 characters or less'
        })
        .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            {
                message: {
                    key: 'Email3',
                    Message: 'Please enter a Valid Email Address'
                }
            }
        ),

    Website: yup
        .string()
        .nullable()
        .transform((o, c) => (o?.trim() === '' ? null : c))
        .url({ key: 'Website', Message: 'Please enter a valid Website URL, it must start with https:// and contain no spaces (check for trailing spaces)' })
        .min(8, {
            key: 'Website',
            Message: 'Website URL must be at least 5 characters'
        })
        .max(255, {
            key: 'Website',
            Message: 'Website URL must be 255 characters or less'
        })
        .typeError({
            key: 'Website',
            Message: 'Please enter a valid Website URL, it must start with https:// and contain no spaces (check for trailing spaces)'
        })
        .matches(new RegExp('^https://', 'i'), {
            message: {
                key: 'Website',
                Message: 'Website must start with https://'
            }
        })
});