import { http } from '../utils/request';

export const loginByEmail = async (email: string, password: string): Promise<any> => {
    try {
        const { data } = await http.post('/login', {
            EmailAddress: email,
            Password: password
        });

        return data;

    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const loginByToken = async (token: string): Promise<any> => {
    try {
        const { data } = await http.post('/login', {
            Token: token
        });

        return data;

    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const loginBySessionToken = async (token: string): Promise<any> => {
    try {
        const { data } = await http.post('/login', {
            SessionToken: token
        });

        return data;

    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const fetchLoginTokens = async (refreshToken: string): Promise<any> => {

    try {
        const {data: {data}} = await http.get('/login/tokens', { 
            params: {
                RefreshToken: refreshToken
            }
        });

        return data;

    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};

export const sendPasswordResetEmail = async (emailAddress: string): Promise<any> => {
    try {
        await http.get(`/login/resetpasswordemail/${encodeURIComponent(emailAddress)}`);
    } catch (e: any) {
       console.log(`sendPasswordResetEmail failed: ${e.message}`);
    }
};

export const resetPassword = async (mode: string, token: string, password: string)
    : Promise<any> => {

    const postUrl = mode === 'activate' ? '/login/activate'
        : '/login/resetpassword';

    await http.post(postUrl, {
        Token: token,
        Password: password
    });
};

export const resetPasswordTokenValid = async (token: string)
    : Promise<boolean> => {

    try {
        const { data } = await http.get(`/login/password/${token}`);
        return data;
    } catch(err) {
        return null;
    }
};

export const getTestUsers = async (): Promise<any> => {
    try {
        const { data } = await http.get('/login/testusers');
        return data;

    } catch (e: any) {
        if (e.status !== 404) {
            throw e;
        }
    }
};