import React, { useState, useEffect, useCallback } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import PageCommunitySelectWrapper from '../../components/common/page_community_select_wrapper';
import LeadEmailFilters from '../../components/leads/lead_email_filters';
import LeadEmailTable from '../../components/leads/lead_email_table';
import { getTemplateTypes, getWebsiteEnquiries } from '../../api/email';
import FilterMatrix from '../../modules/filter_matrix';
import { FilterType } from '../../interfaces/filters';
import { DataTableSortOrder } from '../../components/common/data_table';
import { SortOrder, TableColumn } from 'react-data-table-component';
import CsvExport, { DisplayType } from '../../components/csv_export/csv_export';
import * as csvEportAPI from '../../api/csv_export';
import { formatFileNameForCsvExport } from '../../utils/file';
import { useCommunitySelectHistory } from '../../hooks/use_community_select_history';
import { useLocation } from 'react-router-dom';

const EmailLeads = () => {
    const [ selNHID, setSelNHID ] = useState<number>(null);
    const [templateTypes, setTemplateTypes] = useState<any[]>([]);
    const [, updateState] = useState<any>();
    const [ data, setData ] = useState<any[]>([]);
    const [ totalRows, setTotalRows ] = useState<number>(0);
    const [ offset, setOffset ] = useState<number>();
    const [ limit, setLimit ] = useState<number>(100);
    const [ sortColumn, setSortColumn ] = useState<string>();
    const [ sortOrder, setSortOrder ] = useState<DataTableSortOrder>();
    const [ loading, setLoading ] = useState<boolean>(false);

    const location = useLocation();

    const [ filterMatrix ] = useState(new FilterMatrix([
        {
            Key: 'TemplateTypeID',
            FilterFormLabel: 'Inquiry Type',
            Type: FilterType.LabelValue,
            FilterPropName: 'TemplateTypeID'
        },
        {
            Key: 'SenderName',
            FilterFormLabel: 'Inquirer Name',
            Type: FilterType.String,
            FilterPropName: 'SenderName'
        },
        {
            Key: 'SenderEmail',
            FilterFormLabel: 'Inquirer Email',
            Type: FilterType.String,
            FilterPropName: 'SenderEmail'
        },
        {
            Key: 'DateFrom',
            FilterFormLabel: 'Date From',
            Type: FilterType.DateFromUTC,
            FilterPropName: 'DateFrom'
        },
        { 
            Key: 'DateTo', 
            FilterFormLabel: 'DateTo',
            Type: FilterType.DateToUTC, 
            FilterPropName: 'DateTo', 
        }
    ]));

    
    const getData = async () => {
        setLoading(true);
        const params: any = {
            Offset: offset,
            Limit: limit || 25,
            SortField: sortColumn,
            SortOrder: sortOrder,
            ...filterMatrix.getRequestParams()
        };

        if (selNHID) {
            params.NHIDs = [selNHID];
        }

        const [rows, count] = await getWebsiteEnquiries(params);

        setData(rows);
        setTotalRows(count);
        setLoading(false);
    };

    const handlePageChange = async (pageNumber: number) => {
        setOffset((pageNumber - 1) * limit);
    };

    const handleLimitChange = async (newPerPage: number, pageNumber: number) => {
        setOffset((pageNumber - 1) * newPerPage);
        setLimit(newPerPage);
    };

    const handleSortChange = (column: TableColumn<any>, sortOrder: SortOrder) => {
        setSortColumn(column.sortField);
        setSortOrder(sortOrder === 'asc' ? DataTableSortOrder.Ascending : DataTableSortOrder.Descending);
    };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        setSelNHID(selNHID);
    });

    useEffect(() => {
        getData();
    }, [offset, limit, sortColumn, sortOrder, filterMatrix, selNHID]);

    const forceUpdate = useCallback(() => updateState({}), []);

    const applyFilter = (key: string, value: any, refreshData = true) => {
        filterMatrix.setValue(key, value);
        forceUpdate();

        if (refreshData) {
            getData();
        }
    };

    const getTypeOptionsData = async () => {
        const resp = await getTemplateTypes({
            KeyNames: ['website-enquiry-to-property', 'brochure-request-to-property']
        });

        const data = resp.map((type: any) => ({
            label: type.ShortDisplayName,
            value: type.TemplateTypeID
        }));

        setTemplateTypes(data);
    };

    const startExport = async (): Promise<string> => {
        return csvEportAPI.startExport('/email/websiteenquiry/export', filterMatrix.getRequestParams());
    };

    const resetFilters = () => {
        filterMatrix.reset();
        getData();
    };

    useEffect(() => {
        getTypeOptionsData();
    }, []);

    useEffect(() => {
        if (location?.state && location.state.filters) {
            for (const i in location.state.filters) {
                if (!location.state.filters[i]) {
                    continue;
                }

                applyFilter(i, location.state.filters[i]);
            }
        }
    }, [location]);

    return (
        <>
            <PageCommunitySelectWrapper
                label="All Leads for"
                handleSelect={handleSelect}
                selNHID={selNHID}
            />

            <div className="widget widget-fullwidth">
                <div className="widget-body">
                    <div className="card mb-9 mb-md-0">
                            <Accordion className="filters" defaultActiveKey="0">
                                <Accordion.Item className="border-top-0" eventKey="0">
                                    <Accordion.Header className="accordion-header">
                                        Filters
                                    </Accordion.Header>
                                    <Accordion.Body className="accordion-body p-0">
                                        <LeadEmailFilters
                                            data={data}
                                            templateTypes={templateTypes}
                                            pageOffset={offset}
                                            pageLimit={limit}
                                            totalRows={totalRows}
                                            filterMatrix={filterMatrix}
                                            applyFilter={applyFilter}
                                            resetFilters={resetFilters}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        <div className="card-body flex-col justify-content-center p-0">
                            <div className="container-fluid">
                                <div className="my-4 d-flex justify-content-end">
                                    <CsvExport 
                                        startExportFunction={startExport}
                                        modalTitleSuffix={'Review Score'}
                                        label="Export as CSV"
                                        displayType={DisplayType.Button}
                                        fileName={`${formatFileNameForCsvExport('email_log')}`}
                                    />
                                </div>
                            </div>
                            <LeadEmailTable
                                filterMatrix={filterMatrix}
                                data={data}
                                totalRows={totalRows}
                                handleLimitChange={handleLimitChange}
                                handlePageChange={handlePageChange}
                                handleSortChange={handleSortChange}
                                limit={limit}
                                loading={loading}
                            />
                        </div>
                    </div> 
                </div>
            </div>
        </>
    );
};

export default EmailLeads;
