export class ReviewCardsOrder {
    constructor(public OrgID: number, public Orders: ReviewCardsOrderProperty[]) {
    }
}

export class ReviewCardsOrderProperty {
    constructor(
        public NHID: number,
        public RecipientFirstName: string,
        public RecipientLastName: string,
        public CardsQuantity =  0, 
        public StandsQuantity = 0
    ) {}
}

export interface IReviewCardOrder {
    PostalCardsOrderID: number;
    OrgID: number;
    NHID: number;
    Name: string;
    CreatedByUserName: string;
    CreatedAt: string;
    ShippedAt: string;
    OrderCount: number;
}

export interface IReviewCardsOrderProperty {
    NHID: number;
    PropertyName: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    Region: string;
    RegionCode: string;
    PostCode: string;
    RecipientFirstName: string,
    RecipientLastName: string,
    CardsQuantity: number,
    StandsQuantity: number
}

export interface IReviewCardProperty {
    OrgID: number;
    NHID: number;
    Name: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    Region: string;
    RegionCode: string;
    PostCode: string;
    LastOrderDate: string;
    LastRecipientFirstName: string;
    LastRecipientLastName: string;
    PrimaryContactFirstName: string;
    PrimaryContactLastName: string;
    CardLogoUrl: string;
    WebsiteURL: string;
    IsPremium: boolean;
}
