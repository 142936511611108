import React, { FC } from 'react';
import { FormRadioGroup } from '../../../../../components/common/form_radio_group';
import { FormSelect } from '../../../../../components/common/form_select';
import { FormTextArea } from '../../../../../components/common/form_text_area';
import { FormInput } from '../../../../../components/common/form_input';
import { Form } from '../../../../../components/common/form';
import { schema } from './property_team_form.validation';
import { buildDateOptions } from './helpers';
import { ISessionProperty } from '../../../../../interfaces/session';
import { MeetTheTeamMemberType } from '../../types';
import { FormFacilitySelect, PhotoUpload } from './components';

interface Props {
    properties: ISessionProperty[];
    defaultValues?: MeetTheTeamMemberType;
    onSubmit: (values: MeetTheTeamMemberType) => Promise<void>;
    id?: string;
}

const PropertyTeamForm: FC<Props> = ({
    properties,
    defaultValues,
    onSubmit,
    id
}) => {
    const descriptionFieldDescription = (
        <>
            <p className="mt-1 mb-2">
                Write about the role, personal experience, qualifications,
                achievements and passion for care of this team member.
            </p>
            <p>
                Can be written either in first person (I ..) or third person
                ('Name'/They..)
            </p>
        </>
    );

    return (
        <Form<MeetTheTeamMemberType>
            id={id}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            validationSchema={schema}
        >
            <FormFacilitySelect properties={properties} />
            <FormInput name="firstName" label="First Name" />
            <FormInput name="lastName" label="Last Name" />
            <FormInput name="position" label="Job Title" />
            <FormTextArea
                name="description"
                label="Description"
                rows={5}
                description={descriptionFieldDescription}
                inline={false}
            />
            <FormSelect
                name="yearStarted"
                options={buildDateOptions()}
                label="Year Started"
            />
            <FormRadioGroup
                name="publishWebsite"
                options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' }
                ]}
                groupLabel="Publish to Website"
            />
            <PhotoUpload />
        </Form>
    );
};

export { PropertyTeamForm };
