import React, { FC, ReactElement, ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { Button } from '../button';

interface Props {
    onClose: () => void;
    isOpen: boolean;
    title: string;
    footerActions?: ReactElement;
    children?: ReactNode;
}

const Modal: FC<Props> = (props) => {
    const { onClose, isOpen, title, children, footerActions } = props;

    const closeModalButton: ReactElement = (
        <Button styleType="outline" onClick={onClose}>
            Close
        </Button>
    );

    return (
        <BootstrapModal show={isOpen} onHide={onClose} centered size="lg">
            <BootstrapModal.Header>
                <BootstrapModal.Title>{title}</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>{children}</BootstrapModal.Body>
            <BootstrapModal.Footer>
                {footerActions}
                {closeModalButton}
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
};

export { Modal };
