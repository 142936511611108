import React, { useState, useEffect } from 'react';
import PageCommunitySelectWrapper from '../../../components/common/page_community_select_wrapper';
import Select from 'react-select';
import DataTable from '../../../components/common/data_table';
import Accordion from 'react-bootstrap/Accordion';
import { LoadingSpinner } from '../../../components/common/loading_spinner';
import {
    IPropertyContactDetails,
    IPropertyContactDetailsParams,
    IPropertyContactNoValueDetails
} from '../../../interfaces/property';
import {
    getPropertyContactDetails,
    updatePropertyContactDetails,
    updateBulkPropertyContactDetails,
    getPropertyContactDetailsFilterDetails
} from '../../../api/property';
import { Toast, showToast, ToastType } from '../../../utils/toast';
import { DataTableSortOrder } from '../../../components/common/data_table';
import { SortOrder, TableColumn } from 'react-data-table-component';
import {
    getObjectDifferenceWhenSameParams,
    isObjectEmpty
} from '../../../utils/common';
import { formatAddress } from '../../../utils/property';
import { updateContactObjectSchema } from './contact_details_schema';
import { websiteJSXIcon } from './website_link_icon';
import { useCommunitySelectHistory } from '../../../hooks/use_community_select_history';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
}

const bulkUpdateoptions = [
    { label: 'Ignore', value: 0 },
    { label: 'Update', value: 1 },
    { label: 'Update only if no existing data', value: 2 },
    { label: 'Delete', value: 3 }
];

const PropertyContacts = ({ nhIDs, orgNHID }: IProps) => {
    const [busy, setBusy] = useState<boolean>(false);
    const [selNHID, setSelNHID] = useState<number[]>([]);
    const [contactDetails, setContactDetails] = useState<
        IPropertyContactDetails[]
    >([]);
    const [newContactDetails, setNewContactDetails] = useState<
        IPropertyContactDetails[]
    >([]);
    const [tableOffset, setTableOffset] = useState<number>(0);
    const [tableLimit, setTableLimit] = useState<number>(100);
    const [tableSortColumn, setTableSortColumn] = useState<string>('Name');
    const [tableSortOrder, setTableSortOrder] = useState<any>('DESC');
    const [tableTotalRows, setTableTotalRows] = useState<number>(0);
    const [changedTick, setChangedTick] = useState<number>(0);
    const [newBulkData, setNewBulkData] = useState<any>({});
    const [newBulkSChange, setNewBulkSChange] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<any>({
        PhoneNumber: 0,
        Website: 0,
        Email: 0,
        Email2: 0,
        Email3: 0
    });
    const [bulkValidationError, SetBulkValidationError] = useState<any[]>([]);
    const [rowValidationError, SetRowValidationError] = useState<any[]>([]);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<any>({
        HasNoPhone: false,
        HasNoWebsite: false,
        HasNoEmail: false
    });
    const [filterCounts, setFilterCounts] = useState<any>({
        HasNoPhone: 0,
        HasNoWebsite: 0,
        HasNoEmail: 0
    });

    useEffect(() => {
        fetchPropertyContactDetails();
    }, [tableOffset, tableLimit, tableSortColumn, tableSortOrder, selNHID, selectedFilterOptions]);

    const fetchPropertyContactDetails = async () => {
        const params: IPropertyContactDetailsParams = {
            Offset: tableOffset,
            Limit: tableLimit,
            SortField: tableSortColumn,
            SortOrder: tableSortOrder,
            NHIDs: selNHID.length ? selNHID : nhIDs,
            OrgNHID: orgNHID,
            ...selectedFilterOptions
        };
        setBusy(true);

        const [[propertyContactDetails, totalRows], noValueData]: [
            [IPropertyContactDetails[], number],
            IPropertyContactNoValueDetails
        ] = await Promise.all([
            getPropertyContactDetails(params),
            getPropertyContactDetailsFilterDetails(params)
        ]);

        if (totalRows) {
            //done to create deep copies not referencing original object

            setNewContactDetails(
                JSON.parse(JSON.stringify(propertyContactDetails))
            );
            setContactDetails(
                JSON.parse(JSON.stringify(propertyContactDetails))
            );
            setTableTotalRows(totalRows);
        }
        if (noValueData) {
            setFilterCounts({
                HasNoPhone: noValueData.NoPhoneCount ?? 0,
                HasNoWebsite: noValueData.NoWebsiteCount ?? 0,
                HasNoEmail: noValueData.NoEmailCount ?? 0
            });
        }

        setBusy(false);
        setChangedTick(changedTick + 1);
    };

    const updateSelectedOptions = (selected: any, key: string) => {
        const newDataObject = selectedOptions;
        newDataObject[key] = selected.value;
        setSelectedOptions(newDataObject);
        if (selected.value > 0) {
            setNewBulkSChange(true);
        }
        if (
            (selected.value === 0 || selected.value === 3) &&
            bulkValidationError.length
        ) {
            const errorObjectArray = bulkValidationError.filter(function (e) {
                return e.key !== key;
            });
            SetBulkValidationError(errorObjectArray);
        }
        setChangedTick(changedTick + 1);
    };

    const handleUpdateField = (nhID: number, key: string, value: string) => {
        const nhObjectIndex = newContactDetails.findIndex(
            obj => obj.NHID === nhID
        );

        const nhObject = newContactDetails.filter(
            (details: IPropertyContactDetails) => details.NHID === nhID
        )[0];
        (nhObject as any)[key] = value;
        const newDataArray = JSON.parse(JSON.stringify(newContactDetails));
        newDataArray[nhObjectIndex] = nhObject;
        if (rowValidationError.length) {
            const errorObjectIndex = rowValidationError.reduce(
                (r, v, i) =>
                    r.concat(v.NHID === nhID && v.key === key ? i : []),
                []
            );
            if (errorObjectIndex.length) {
                const cleanedErrorArray = rowValidationError.filter(function (
                    value,
                    index
                ) {
                    return errorObjectIndex.indexOf(index) === -1;
                });
                SetRowValidationError(cleanedErrorArray);
            }
        }
        setNewContactDetails(newDataArray);
        setChangedTick(changedTick + 1);
    };

    const handleSelect = useCommunitySelectHistory((selNHID: number) => {
        if (selNHID) {
            handleClearFilters();
            setSelNHID([selNHID]);
        } else {
            setSelNHID([]);
        }
    });

    const handleSetNewBulkData = (key: string, value: string) => {
        const newDataObject = newBulkData;
        newDataObject[key] = value;
        if (bulkValidationError.length) {
            const errorObjectArray = bulkValidationError.filter(function (e) {
                return e.key !== key;
            });
            SetBulkValidationError(errorObjectArray);
        }
        setNewBulkData(newDataObject);
        setChangedTick(changedTick + 1);
    };

    const handleRowUpdate = async (nhID: number) => {
        const oldObject = contactDetails.filter(
            (details: IPropertyContactDetails) => details.NHID === nhID
        )[0];
        const newObject = newContactDetails.filter(
            (details: IPropertyContactDetails) => details.NHID === nhID
        )[0];
        const differences = getObjectDifferenceWhenSameParams(
            oldObject,
            newObject
        );
        if (!isObjectEmpty(differences)) {
            try {
                await updateContactObjectSchema.validate(differences, {
                    abortEarly: false
                });
            } catch (err: any) {
                const errorPayload: any = err.errors ?? [err.message];
                const errorNHIDPayload = errorPayload.map((e: any) => {
                    const errorCopy = { ...e };
                    errorCopy.NHID = nhID;
                    return errorCopy;
                });
                const newErrorArray =
                    rowValidationError.concat(errorNHIDPayload);
                SetRowValidationError(newErrorArray);
                setChangedTick(changedTick + 1);
                return;
            }
            setBusy(true);
            try {
                await updatePropertyContactDetails(nhID, differences);
                showToast(
                    'Facility contact details saved successfully. Please allow up to 60 minutes for the changes to show on the website.',
                    ToastType.Success
                );
                SetRowValidationError([]);
                fetchPropertyContactDetails();
            } catch (e) {
                showToast(
                    'An error occurred saving the updated contact details.',
                    ToastType.Error
                );
                console.log(e);
            } finally {
                setBusy(false);
            }
        }
    };

    const renderRowErrorMessage = (
        key: string,
        row?: IPropertyContactDetails
    ) => {
        const errorObjectArray = row
            ? rowValidationError.filter(function (e) {
                  return e.NHID === row.NHID && e.key === key;
              })
            : bulkValidationError.filter(function (e) {
                  return e.key === key;
              });

        if (errorObjectArray.length) {
            return (
                <>
                    <small className="text-danger">
                        {`${errorObjectArray[0].Message} `}
                    </small>
                </>
            );
        }
    };

    const checkRowError = (row: IPropertyContactDetails) => {
        const errorObjectArray = rowValidationError.filter(function (e) {
            return e.NHID === row.NHID;
        });

        if (errorObjectArray.length) {
            return true;
        }
        return false;
    };

    const checkBulkObjectError = () => {
        if (bulkValidationError.length) {
            return true;
        }
        return false;
    };

    const checkBulkOptionUpdateSelected = () => {
        for (const i in selectedOptions) {
            if (selectedOptions[i] > 0) return true;
        }
        return false;
    };

    const checkIsDeletedOrIgnored = (key: string) => {
        if (selectedOptions[key] === 3 || selectedOptions[key] === 0) {
            return true;
        }
        return false;
    };

    const handleTablePageChange = async (pageNumber: number) => {
        setTableOffset((pageNumber - 1) * tableLimit);
    };

    const handleTableLimitChange = async (
        newPerPage: number,
        pageNumber: number
    ) => {
        setTableOffset((pageNumber - 1) * newPerPage);
        setTableLimit(newPerPage);
    };

    const handleTableSortChange = (
        column: TableColumn<any>,
        sortOrder: SortOrder
    ) => {
        setTableSortColumn(column.sortField);
        setTableSortOrder(
            sortOrder === 'asc'
                ? DataTableSortOrder.Ascending
                : DataTableSortOrder.Descending
        );
    };

    const handleBulkUpdate = async () => {
        const payload: any = newBulkData;
        let blankUpdateErrorCount = 0;
        Object.entries(selectedOptions).forEach(([key, value]) => {
            if (parseInt(value as string) > 0) {
                payload[`${key}Op`] = selectedOptions[key];
                if (
                    (parseInt(value as string) === 1 ||
                        parseInt(value as string) === 2) &&
                    !payload[key]
                ) {
                    blankUpdateErrorCount = +1;
                    SetBulkValidationError(
                        bulkValidationError.concat([
                            {
                                key,
                                Message:
                                    'Please type in a value for the Bulk Update'
                            }
                        ])
                    );
                }
            } else {
                delete payload[key];
            }
        });
        if (blankUpdateErrorCount) {
            setChangedTick(changedTick + 1);
            return;
        }
        if (!Object.keys(payload).length) {
            showToast(
                'No bulk amendments detected. Please amend the fields you wish to update.',
                ToastType.Error
            );
            return;
        }
        payload.OrgNHID = orgNHID;
        payload.NHIDs = nhIDs ?? null;
        if (selNHID.length) {
            payload.NHIDs = selNHID;
        }
        try {
            await updateContactObjectSchema.validate(payload, {
                abortEarly: false
            });
        } catch (err: any) {
            const errorPayload: any = err.errors ?? [err.message];
            const newErrorArray = errorPayload;
            SetBulkValidationError(newErrorArray);
            setChangedTick(changedTick + 1);
            return;
        }
        try {
            setBusy(true);
            await updateBulkPropertyContactDetails(payload);
            const successString = `${
                nhIDs ? nhIDs.length : contactDetails.length
            } Facility contact details saved successfully. Please allow up to 60 minutes for the changes to show on the website.`;
            showToast(successString, ToastType.Success);
            setSelectedOptions({
                PhoneNumber: 0,
                Website: 0,
                Email: 0,
                Email2: 0,
                Email3: 0
            });
            setNewBulkData({});
            setNewBulkSChange(false);
            fetchPropertyContactDetails();
        } catch (e) {
            showToast(
                'An error occurred saving the updated contact details.',
                ToastType.Error
            );
            console.log(e);
        } finally {
            setBusy(false);
        }
    };

    const columns = [
        {
            name: 'Facility Name',
            selector: (row: IPropertyContactDetails) => row.Name,
            sort: true,
            maxWidth: '200px',
            sortable: true,
            sortField: 'Name',
            cell: (row: IPropertyContactDetails) => formatFacility(row)
        },
        {
            name: 'Phone',
            selector: (row: IPropertyContactDetails) => row.PhoneNumber,
            sort: true,
            width: '200px',
            sortable: true,
            sortField: 'PhoneNumber',
            cell: (row: IPropertyContactDetails) => (
                <>
                    <div className="column">
                        <input
                            type="text"
                            className={formatTableCell('PhoneNumber', row)}
                            id="dt-phone-number"
                            value={row.PhoneNumber || ''}
                            onChange={e =>
                                handleUpdateField(
                                    row.NHID,
                                    'PhoneNumber',
                                    e.target.value
                                )
                            }
                        />
                        {renderRowErrorMessage('PhoneNumber', row)}
                    </div>
                </>
            )
        },
        {
            name: 'Email',
            sortable: true,
            sortField: 'Email',
            width: '420px',
            selector: (row: IPropertyContactDetails) => row.Email,
            cell: (row: IPropertyContactDetails) => (
                <div className="w-100">
                    <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                        <div className="flex-row-auto flex-sm-row align-items-md-center w-100">
                            <input
                                type="text"
                                className={formatTableCell('Email', row)}
                                id="dt-email1"
                                value={row.Email || ''}
                                placeholder="email 1"
                                title="email 1"
                                onChange={e =>
                                    handleUpdateField(
                                        row.NHID,
                                        'Email',
                                        e.target.value
                                    )
                                }
                            />
                            {renderRowErrorMessage('Email', row)}
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                        <div className="flex-row-auto flex-sm-row align-items-md-center w-100">
                            <input
                                type="text"
                                className={formatTableCell('Email2', row)}
                                id="dt-email2"
                                value={row.Email2 || ''}
                                placeholder="email 2"
                                title="email 2"
                                onChange={e =>
                                    handleUpdateField(
                                        row.NHID,
                                        'Email2',
                                        e.target.value
                                    )
                                }
                            />
                            {renderRowErrorMessage('Email2', row)}
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                        <div className="flex-row-auto flex-sm-row align-items-md-center w-100">
                            <input
                                type="text"
                                className={formatTableCell('Email3', row)}
                                id="dt-email3"
                                placeholder="email 3"
                                title="email 3"
                                value={row.Email3 || ''}
                                onChange={e =>
                                    handleUpdateField(
                                        row.NHID,
                                        'Email3',
                                        e.target.value
                                    )
                                }
                            />
                            {renderRowErrorMessage('Email3', row)}
                        </div>
                    </div>
                </div>
            )
        },
        {
            name: 'Website',
            sortable: true,
            sortField: 'Website',
            minWidth: '300px',
            selector: (row: IPropertyContactDetails) => row.Website,
            cell: (row: IPropertyContactDetails) => (
                <>
                    <div className="column w-100">
                        <div className="d-flex flex-row flex-sm-row align-items-md-center w-100">
                            <input
                                type="text"
                                className={formatTableCell('Website', row)}
                                id="dt-website"
                                value={row.Website || ''}
                                onChange={e =>
                                    handleUpdateField(
                                        row.NHID,
                                        'Website',
                                        e.target.value
                                    )
                                }
                            />
                            &nbsp;
                            {websiteJSXIcon(row.Website)}
                        </div>
                        <div className="row w-100">
                            <div className="d-flex flex-column flex-sm-row align-items-md-center w-100">
                                {renderRowErrorMessage('Website', row)}
                            </div>
                        </div>
                    </div>
                </>
            )
        },
        {
            name: '',
            maxWidth: '125px',
            cell: (row: IPropertyContactDetails) =>
                row ? renderUpdateButton(row) : null
        }
    ];

    const formatTableCell = (key: string, row: IPropertyContactDetails) => {
        let formClass;
        let errorFormat = '';

        const errorObjectArray = rowValidationError.filter(function (e) {
            return e.NHID === row.NHID && e.key === key;
        });

        if (errorObjectArray.length) {
            errorFormat = 'border border-danger';
        }

        switch (key) {
            case 'PhoneNumber':
                formClass = `form-control w-180px mb-2 mb-sm-0 ${errorFormat}`;
                break;
            case 'Website':
                formClass = `form-control w-100 ${errorFormat}`;
                break;
            default:
                formClass = `form-control w-400px mb-2 mb-sm-0 ${errorFormat}`;
                break;
        }

        return formClass;
    };

    const renderUpdateButton = (row: IPropertyContactDetails) => {
        const oldObj = contactDetails.filter(
            (details: IPropertyContactDetails) => details.NHID === row.NHID
        )[0];
        const isdisabled =
            JSON.stringify(row) === JSON.stringify(oldObj) ? true : false;

        return (
            <button
                type="button"
                className="btn btn-sm btn-primary text-nowrap"
                disabled={isdisabled || busy || checkRowError(row)}
                onClick={() => handleRowUpdate(row.NHID)}
            >
                Update
            </button>
        );
    };

    const formatFacility = (row: IPropertyContactDetails) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

        return (
            <div className="py-2">
                <b>{row.Name}</b>
                <br />
                <small>{formatAddress(row)}</small>
                &nbsp;
                <a
                    target="_blank" 
                    className="small text-link text-link-blue text-nowrap"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                >
                    view on website
                </a>    
            </div>

        );
    };

    const renderLoading = () => <LoadingSpinner show={true} />;

    const handleUpdateFilters = (key: string, value: boolean) => {
        const filterOptions = JSON.parse(JSON.stringify(selectedFilterOptions));
        filterOptions[key] = value;
        setSelectedFilterOptions(filterOptions);
        setChangedTick(changedTick + 1);
    };

    const handleClearFilters = () => {
        setSelectedFilterOptions({
            HasNoPhone: false,
            HasNoWebsite: false,
            HasNoEmail: false
        });
        setChangedTick(changedTick + 1);
    };

    const renderCheckBox = (key: string, label: string) => {
        return (
            <div
                className="d-flex align-items-center"
            >
                <input
                    type="checkbox"
                    className="form-check-input m-0 me-2"
                    name={key}
                    checked={selectedFilterOptions[key]}
                    disabled={!filterCounts[key]}
                    onChange={(e: any) =>
                        handleUpdateFilters(key, e.target.checked)
                    }
                />
                <div
                    onClick={(e: any) => {
                        filterCounts[key]
                            ? handleUpdateFilters(
                                    key,
                                    !selectedFilterOptions[key]
                                )
                            : null;
                    }}
                >
                    {label} (
                    {filterCounts[key] > 0
                        ? filterCounts[key]
                        : 'all completed'}
                    )
                </div>
            </div>
        );
    };

    const renderBulkOptions = (
        htmlId: string,
        key: string,
        label: string,
        data?: IPropertyContactDetails
    ) => {
        let formClass;

        const errorObjectArray = data
            ? rowValidationError.filter(function (e) {
                  return e.NHID === data.NHID && e.key === key;
              })
            : bulkValidationError.filter(function (e) {
                  return e.key === key;
              });
        let errorFormat = '';

        if (errorObjectArray.length) {
            errorFormat = 'border border-danger';
        }

        switch (htmlId) {
            case 'website':
                formClass = `form-control ${errorFormat}`;
                break;
            case 'phone-number':
                formClass = `form-control  mw-250px ${errorFormat}`;
                break;
            default:
                formClass = `form-control mw-400px ${errorFormat}`;
                break;
        }

        // if (!data) {
        //     formClass = `form-control w-100 w-sm-auto me-sm-4 mb-2 mb-sm-0 mw-250px ${errorFormat}`;
        // }
        if (data) {
            return (
                <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                    <label
                        htmlFor={htmlId}
                        className="col-form-label text-nowrap col-12 col-sm-2 mw-300px"
                    >
                        {label}
                    </label>
                    {key === 'Website' ? (
                        <div className="column w-100">
                            <div className="d-flex flex-row flex-sm-row align-items-md-center w-100">
                                <input
                                    type="text"
                                    className={formatTableCell('Website', data)}
                                    id="dt-website"
                                    value={data.Website || ''}
                                    onChange={e =>
                                        handleUpdateField(
                                            data.NHID,
                                            'Website',
                                            e.target.value
                                        )
                                    }
                                />
                                &nbsp;
                                {websiteJSXIcon(data.Website)}
                            </div>
                            <div className="row w-100">
                                <div className="d-flex flex-column flex-sm-row align-items-md-center w-100">
                                    {renderRowErrorMessage('Website', data)}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="column flex-sm-row align-items-md-center w-100">
                            <input
                                type="text"
                                className={formClass}
                                id={htmlId}
                                value={(data as any)[key] || ''}
                                onChange={e =>
                                    handleUpdateField(
                                        data.NHID,
                                        key,
                                        e.target.value
                                    )
                                }
                            />
                            {key === 'Website'
                                ? websiteJSXIcon(data.Website)
                                : null}
                            {renderRowErrorMessage(key, data)}
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div className="d-flex flex-column flex-sm-row align-items-md-center mb-4">
                <label
                    htmlFor={htmlId}
                    className="col-form-label text-nowrap col-12 col-sm-2 mw-300px"
                >
                    {label}
                </label>
                <div className="column align-items-md-center w-25">
                    <Select
                        options={bulkUpdateoptions}
                        defaultValue={bulkUpdateoptions[0]}
                        className=" mw-200px"
                        onChange={(selectedOption: any) =>
                            updateSelectedOptions(selectedOption, key)
                        }
                        value={
                            bulkUpdateoptions[selectedOptions[key]] ||
                            bulkUpdateoptions[0]
                        }
                    />
                </div>
                <div className="column w-100">
                    <input
                        type="text"
                        className={formClass}
                        id={htmlId}
                        value={(newBulkData as any)[key] || ''}
                        disabled={checkIsDeletedOrIgnored(key)}
                        onChange={e =>
                            handleSetNewBulkData(key, e.target.value)
                        }
                    />
                    {renderRowErrorMessage(key)}
                </div>
            </div>
        );
    };

    return (
        <>
            <PageCommunitySelectWrapper
                label={'Phone / Website / Email'}
                handleSelect={handleSelect}
                selNHID={selNHID[0]}
            />

            {(orgNHID && contactDetails.length && !selNHID.length) ||
            (nhIDs && nhIDs.length > 1 && !selNHID.length) ? (
                <Accordion className="mt-10 accordion-bulk-update">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header as="span">
                            <span className="m-0">
                                <h5 className="m-0">
                                    Bulk Update all Facility Contact details
                                </h5>
                                <div className="mt-1 small">
                                    Click to view options for bulk updating all Facilities
                                </div>
                            </span>
                        </Accordion.Header>
                        <Accordion.Body>
                            {renderBulkOptions(
                                'phone-number',
                                'PhoneNumber',
                                'Phone Number'
                            )}
                            {renderBulkOptions('website', 'Website', 'Website')}
                            {renderBulkOptions('email1', 'Email', 'Email 1')}
                            {renderBulkOptions('email2', 'Email2', 'Email 2')}
                            {renderBulkOptions('email3', 'Email3', 'Email 3')}
                            <div className="card-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary text-nowrap"
                                    disabled={
                                        (isObjectEmpty(newBulkData) &&
                                            !newBulkSChange &&
                                            !checkBulkOptionUpdateSelected()) ||
                                        checkBulkObjectError()
                                    }
                                    onClick={handleBulkUpdate}
                                >
                                    Apply these Updates
                                </button>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ) : null}
            {busy ? renderLoading() : null}
            <Toast></Toast>

            {(orgNHID && contactDetails.length && !selNHID.length) ||
            (nhIDs && nhIDs.length > 1 && !selNHID.length) ? (
                <div className="widget widget-fullwidth dataTable-widget" data-widget-header="1">
                    <div className="widget-header">
                        <h4>All Facilities</h4>
                        <div>
                            <div className="d-inline-flex align-items-center gap-5 small">
                                {renderCheckBox('HasNoPhone', 'Has no Phone')}
                                {renderCheckBox('HasNoEmail', 'Has no Email')}
                                {renderCheckBox(
                                    'HasNoWebsite',
                                    'Has no Website'
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="widget-body">
                        <div className="card mb-9 mb-md-0">
                            <div className="card-body flex-col justify-content-center p-0">
                                <div className="bulk-contact-table sticky_dt">
                                    <DataTable
                                        columns={columns}
                                        key={'NHID'}
                                        data={newContactDetails}
                                        paginationTotalRows={tableTotalRows}
                                        pagination={true}
                                        paginationServer={true}
                                        initialRowsPerPage={tableLimit}
                                        sortServer={true}
                                        onChangePage={handleTablePageChange}
                                        onChangeRowsPerPage={
                                            handleTableLimitChange
                                        }
                                        onSort={handleTableSortChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {(!orgNHID &&
                newContactDetails.length &&
                nhIDs &&
                nhIDs.length === 1) ||
            selNHID.length ? (
                <div>
                    <div className="widget widget-fullwidth dataTable-widget">
                        <div className="card-header">
                            <h4>{newContactDetails[0].Name}</h4>
                        </div>
                        <div className="card mb-9 mb-md-10 mt-24">
                            <div className="card-body flex-col justify-content-center">
                                {renderBulkOptions(
                                    'phone-number',
                                    'PhoneNumber',
                                    'Phone',
                                    newContactDetails[0]
                                )}
                                {renderBulkOptions(
                                    'website',
                                    'Website',
                                    'Website',
                                    newContactDetails[0]
                                )}
                                {renderBulkOptions(
                                    'email1',
                                    'Email',
                                    'Email 1',
                                    newContactDetails[0]
                                )}
                                {renderBulkOptions(
                                    'email2',
                                    'Email2',
                                    'Email 2',
                                    newContactDetails[0]
                                )}
                                {renderBulkOptions(
                                    'email3',
                                    'Email3',
                                    'Email 3',
                                    newContactDetails[0]
                                )}
                            </div>
                            <div className="card-footer">
                                {renderUpdateButton(newContactDetails[0])}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default PropertyContacts;
