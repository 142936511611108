import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SortOrder, TableColumn } from 'react-data-table-component';
import DataTable, { DataTableSortOrder, INITIAL_ROWS_PER_PAGE }  from '../common/data_table';
import { IPropertyServiceTotalParams, IServiceTotals } from '../../interfaces/service';
import { getPropertyServiceTotals } from '../../api/service';
import AppContext from '../../context/app_context';
import { LoadingSpinner } from '../common/loading_spinner';
import { formatAddress } from '../../utils/property';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { 
    INhPortalScoreItem,
    IScoreDisplayItem,
    ProfileScoreType
} from '../../interfaces/property';

import { mapProfileDatatoDisplay } from '../../utils/profile_completeness';
import { generalSort } from '../../utils/common';

interface IProps {
    selNHID: number;
    nhIDs: number[];
    orgNHID: number;
    handleSelect: (nhID: number) => void; 
    profileCompletenessFilterValue: boolean | null;
}

const PropertyServiceTotalsTable = ({ 
    handleSelect, 
    selNHID, 
    nhIDs, 
    orgNHID,
    profileCompletenessFilterValue
}: IProps) => {

    const [ busy, setBusy ] = useState<boolean>(false);
    const [ propertyServiceTotals, setPropertyServiceTotals ] = useState<IServiceTotals[]>();
    const [ scoreItem, setScoreItem ] = useState<IScoreDisplayItem>(null);

    const profileCompletenessState: INhPortalScoreItem = useSelector(
        ({ ProfileCompletenessState }: any) => ProfileCompletenessState
    );

    const appContext: any = useContext(AppContext);

    useEffect(() => {
        
        const scoreItem = mapProfileDatatoDisplay(profileCompletenessState, selNHID)
            .find((item: IScoreDisplayItem) => item.KeyName === ProfileScoreType.FacilityBaseData);

        setScoreItem(scoreItem);

    }, [ orgNHID, nhIDs, selNHID ]);

    useEffect(() => {
        fetchPropertyServiceTotals();
    }, [
        profileCompletenessFilterValue
    ]);

    const columns = [{
        name: 'Facility Name',
        cell: (row: IServiceTotals) => formatFacility(row),
        sortable: true,
        sortField: 'PropertyName',
        minWidth: appContext.isMobile ? '146px' : '400px',
        id: 'PropertyName'
    }, {
        name: <>Profile <br/>Complete <br/>for Services & Amenities</>,
        cell: (row: IServiceTotals) => formatProfileCompleteness(row),
        sortable: true,
        sortField: 'ProfileCompleteResourceCount',
        width: '150px',
        id: 'ProfileCompleteResourceCount'
    }, {
        name: 'Misc Services',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'ServiceExtra'),
        sortable: true,
        sortField: 'ServiceExtraCount',
        minWidth: '130px',
        id: 'ServiceExtraCount'
    }, {
        name: 'Health Care Services',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'HealthCareServices'),
        sortable: true,
        sortField: 'HealthCareServicesCount',
        minWidth: '130px',
        id: 'HealthCareServicesCount'
    }, {
        name: 'Activities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'Activities'),
        sortable: true,
        sortField: 'ActivitiesCount',
        minWidth: '130px',
        id: 'ActivitiesCount'
    }, {
        name: 'Community Amenities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'CommunityAmenities'),
        sortable: true,
        sortField: 'CommunityAmenitiesCount',
        minWidth: '130px',
        id: 'CommunityAmenitiesCount'
    }, {
        name: 'Dining',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'Dining'),
        sortable: true,
        sortField: 'DiningCount',
        minWidth: '130px',
        id: 'DiningCount'
    }, {
        name: 'Room Amenities',
        cell: (row: IServiceTotals) => formatServiceTotal(row, 'RoomAmenities'),
        sortable: true,
        sortField: 'RoomAmenitiesCount',
        minWidth: '130px',
        id: 'RoomAmenitiesCount'
    }, {
        name: 'Actions',
        cell: (row: IServiceTotals) => formatActions(row),
        width: '90px',
        id: 'Actions'
    }];

    const formatActions = (row: IServiceTotals) => {
        return (
            <Link 
                to={'/amenities'}
                onClick={(e) => handleLinkClick(row.NHID, e)}
                className="btn btn-sm btn-primary text-nowrap"
            >
                Edit
            </Link>  
        );
    };

    const formatProfileCompleteness = (row: IServiceTotals) => {

        const complete = row.ProfileCompleteResourceCount === row.ProfileCompleteResourceTotal;

        return (
            <>
                <div className="my-3">
                    <div className="d-flex flex-column align-items-center justify-content-center just fw-bold text-nowrap w-100">
                        {
                            <div>
                                <FontAwesomeIcon 
                                    icon={complete ? faCheck : faXmark} 
                                    className={`${complete ? 'text-success' : 'text-danger'} fs-3`}
                                /> 
                            </div>
                        }
                    </div>
                </div>
            </>
        );
    };

    const formatServiceTotal = (row: IServiceTotals, key: string, showProfileComplete = false) => {

        const complete = (row as any)[`${key}Count`] === (row as any)[`${key}Total`];

        return (
            <>
                <div className="my-3">
                    <div className="d-flex flex-column align-items-center justify-content-center just fw-bold text-nowrap w-100">
                        {
                            showProfileComplete && <div>
                                <FontAwesomeIcon 
                                    icon={complete ? faCheck : faXmark} 
                                    className={`${complete ? 'text-success' : 'text-danger'} fs-3`}
                                /> 
                            </div>
                        }
                        <div>
                            { (row as any)[`${key}Count`] } / { (row as any)[`${key}Total`] }
                        </div> 
                    </div>
                    <small className="text-nowrap">completed</small>
                </div>
            </>
        );
    };

    const formatFacility = (row: IServiceTotals) => {

        const { WebsiteURL, StateSlug, CitySlug, Slug } = row;

          return (
            <div className="py-2">
                <Link 
                    className="fw-bolder" 
                    to={'/amenities'}
                    onClick={(e) => handleLinkClick(row.NHID, e)}
                >
                    { row.PropertyName }
                </Link>      
                <br />
                <small>{ formatAddress(row) }</small>&nbsp;
                <a
                    className="small text-link text-link-blue text-nowrap"
                    href={`${WebsiteURL}/${StateSlug}/${CitySlug}/${Slug}`}
                    target="_blank"
                >
                    view on website
                </a>    
            </div>
        );
    };

    const handleLinkClick = (
        nhID: number, 
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        handleSelect(nhID);
    };

    const fetchPropertyServiceTotals = async () => {
        setBusy(true);

        const params: IPropertyServiceTotalParams = {
            ResourceProfileCompleted: profileCompletenessFilterValue
        };

        const [ 
            serviceTotals, 
        ]: [
            IServiceTotals[],
            number
        ] = await getPropertyServiceTotals(params);

        if (serviceTotals) {
            setPropertyServiceTotals(serviceTotals);
        }

        setBusy(false);
    };

    if (!propertyServiceTotals) {
        return <LoadingSpinner show={true}/>;
    }

    const handleTableSortChange = (column: TableColumn<any>, sortOrder: string) => {
        propertyServiceTotals.sort(generalSort(column.sortField, sortOrder === 'asc' ? 'ASC' : 'DESC'));
        setPropertyServiceTotals(propertyServiceTotals);
    };

    return (
        <>
            { busy ? <LoadingSpinner show={true}/> : null }
            
           
            <div className={'reviewscore_dt sticky_dt table_dt expander_dt'}>
                <DataTable
                    columns={columns}
                    data={propertyServiceTotals}
                    sortServer={true}
                    fixedHeader={false}
                    onSort={handleTableSortChange}
                />
            </div>
        </>
    );
};

export default PropertyServiceTotalsTable;