export enum SessionTypeID { 
    Property = 1,
    Org 
}

export enum SessionType {
    SessionTypeProperty = 'property',
    SessionTypeOrg = 'org'
}

export enum PropertyMode {
    SingleProperty = 'single-property',
    MultiProperty = 'multi-property',
    Org = 'org',
    None = 'none',
}

export interface ISessionOrg {
    NHID: number;
    Name: string;
    Slug: string;
    ReviewScoreDisplay: number;
    IsPremium: boolean;
    SessionID: number;
    WebsiteURL: string;
}

export interface ISessionProperty {
    NHID: number;
    OrgNHID: number;
    OrgName: string;
    OrgSlug: string;
    Name: string;
    Address1: string;
    City: string;
    PostCode: string;
    RegionCode: string;
    StateSlug: string;
    CitySlug: string;
    Slug: string;
    ReviewSubmissionURL: string;
    WebsiteURL: string;
    IsPremium: boolean;
    ReviewScoreDisplay: number;
    TotalProfileCompletenessScore: number;
}

export interface IPortalPermissions {
    CanReplyToReview: boolean;
    CanManageUsers: boolean;
    CanReceiveNotifications: boolean;
    CanViewTestFeatures: boolean;
}

export interface ISessionData {
    SessionType: SessionType;
    SessionID: number;
    UserID: number;
    EmailAddress: string;
    FirstName: string;
    LastName: string;
    Perms: IPortalPermissions;
}

export interface ISessionState {
    Session: ISessionData;
    Org: ISessionOrg;
    Properties: ISessionProperty[];
}