import React, { useEffect, useState } from 'react';
import IndividualPropertyPhotos from '../../components/assets/individual_property_photos';
import PhotoUploadForm from '../../components/assets/photo_upload_form';
import { 
    NHAssetType,
    TagType,
    IPortalPropertyPhotoAsset
} from '../../interfaces/asset';
import { ITag } from '../../interfaces/tag';
import { IMessageType } from '../../interfaces/common';
import * as assetApi from '../../api/assets';
interface IProps {
    selectedNHID: number | null;
    nhIDs: number[];
    propertyPhotos: IPortalPropertyPhotoAsset[];
    setPropertyPhotos:(photos: IPortalPropertyPhotoAsset[]) => void;
    busyLoadingPhotos: boolean;
    setBusyLoadingPhotos: (busyState: boolean) => void;
    showPhotoUploadForm: boolean;
    setShowPhotoUploadForm: (showForm: boolean) => void;
}

const IndividualPropertyPhotosWrapper = ({
    selectedNHID,
    nhIDs,
    propertyPhotos,
    setPropertyPhotos,
    busyLoadingPhotos,
    setBusyLoadingPhotos,
    showPhotoUploadForm,
    setShowPhotoUploadForm
}: IProps) => {
    const [photoTags, setPhotoTags] = useState<ITag[]>([]);
    const [photoUploadMessages, setPhotoUploadMessages] = useState<IMessageType[]>([]);

    useEffect(() => {
        const fetchPhotoTags  = async () : Promise<void>  => {
            const data: ITag[] = await (propertyPhotos?.length ? assetApi.getTags({
                AssetTypeID: NHAssetType.Photos, 
                WebsiteID: propertyPhotos[0].Property.WebsiteID, 
                IsTagPublishPortal: true, 
                TagTypeID: TagType.SingleTag
            }) : Promise.resolve(null));
                
            if(data)      {
                setPhotoTags(data);
            }
        };

        fetchPhotoTags();
    }, [propertyPhotos]);

    const renderPhotoWrapper = () => {
        return (
            <>
                <div className="widget mt-0">
                    <div className="widget-body">
                        { 
                        propertyPhotos?.length === 1 ?
                            <>
                            <div className="header-tabs mt-0">
                                <ul className="breadcrumb">
                                    <li className={`breadcrumb-item me-4 ${showPhotoUploadForm ? '' :  'current'}`}>
                                        <button 
                                            key='show-property-photos'
                                            className="button-as-link text-link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowPhotoUploadForm(false);
                                                setPhotoUploadMessages([]);
                                            }}
                                        >
                                            Update Photos
                                        </button>
                                    </li>
                                    <li className={`breadcrumb-item ${showPhotoUploadForm ? 'current' :  ''}`} >
                                        <button 
                                            key='show-property-photo-upload-form'
                                            className="button-as-link text-link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowPhotoUploadForm(true);
                                                setPhotoUploadMessages([]);
                                            }}
                                        >
                                            Add Photos
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            
                            <IndividualPropertyPhotos
                                selectedNHID={selectedNHID}
                                nhIDs={nhIDs}
                                propertyPhotos={propertyPhotos}
                                setPropertyPhotos={setPropertyPhotos}
                                showPhotoUploadForm={showPhotoUploadForm}
                                photoTags={photoTags}
                                busyLoadingPhotos={busyLoadingPhotos}
                                setBusyLoadingPhotos={setBusyLoadingPhotos}
                                photoUploadMessages={photoUploadMessages}
                                setPhotoUploadMessages={setPhotoUploadMessages}
                            />

                            <PhotoUploadForm
                                selectedNHID={selectedNHID}
                                propertyPhotos={propertyPhotos}
                                setPropertyPhotos={setPropertyPhotos}
                                showPhotoUploadForm={showPhotoUploadForm}
                                setShowPhotoUploadForm={setShowPhotoUploadForm}
                                photoTags={photoTags}
                                busyLoadingPhotos={busyLoadingPhotos}
                                setBusyLoadingPhotos={setBusyLoadingPhotos}
                                setPhotoUploadMessages={setPhotoUploadMessages}
                            />
                        </>
                    :
                        null
                    }
                </div>
            </div>
            </>
        );
    };

    return renderPhotoWrapper();
};

export default IndividualPropertyPhotosWrapper;
