import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReCAPTCHA from 'react-google-recaptcha';
import HeaderSessionSelect from '../../components/common/header_session_select';
import { emailLogin, logout } from '../../modules/login';
import { ENV } from '../../config/keys';
import _ from 'lodash';

interface FormInputs {
    email: string;
    password: string;
    reCaptcha: string;
}

const Login = () => { 

    const navigate = useNavigate();
    const location = useLocation();
    const state: any = location.state;

    const from = _.get(state, 'from', null);
    const redirectToPath = from && from !== '/login' ? from : '/';

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        setError,
    } = useForm<FormInputs>({mode: 'onSubmit'});

    const [errorFlag, setErrorFlag] = useState<boolean>(false);
    const [busy, setBusy] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        if (success) {
            navigate(redirectToPath);
        }
    }, [success]);

    const handleLogin = async (data: any) => {
        const { email, password } = data;

        let errFound = false;

        if (!email) {
            setError('email', { type: 'custom', message: 'Please enter an email address' });
            errFound = true;
        }

        if (!password) {
            setError('password', { type: 'custom', message: 'Please enter a password' });
            errFound = true;
        }

        if (errFound) {
            setErrorFlag(true);
            return;
        }

        setBusy(true);

        try {
            await logout();
            await emailLogin(email, password);
            setSuccess(true);
        } catch(e) {
            setErrorFlag(true);
            const userMsg = _.get(e, 'data.data.UserMsg', 'Login Failed');
            setError('email', { type: 'custom', message: userMsg });
        } finally {
            setBusy(false);
        }
    };

    const handleSessionSelect = async (option: any) => {

        setBusy(true);
        try {
            await logout();
            await emailLogin(option.email, 'password');
            setSuccess(true);
        } catch(err) {
            console.log(`handleSessionSelect failed: ${err}`);
        } finally {
            setBusy(false);
        }
    };

    return (
        
        <div className="container-fluid public public__login">
            <div className="row">
                <div className="col-12">
                    <div className="widget mt-3 mt-md-5">
                        <div className="widget-body">
                            <div className="card">
                                <div className="card-header border-bottom">
                                    <a href="/" className="d-flex flex-row align-items-end text-secondary">
                                        <img src="/logo.svg" height="30px" className="logo-large" alt="Portal" />
                                        <span className="d-block ms-3">Portal</span>
                                    </a>
                                </div>
                                <div className="card-body">
                                    <form className="kt-form" onSubmit={handleSubmit(handleLogin)}>
                                        <div className="kt-portlet__body p-0">

                                            {
                                                ENV !== 'production' ?
                                                <div className="form-group mb-3">
                                                    <label className="mb-2">Records for testing</label>
                                                    <HeaderSessionSelect
                                                        handleSessionSelect={handleSessionSelect}
                                                    />  
                                                </div> : null
                                            }

                                            <div className={errorFlag ? 'kt-section kt-section--first validated mb-0' : 'kt-section kt-section--first mb-0'}>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="email" className="mb-2">Email</label>
                                                    <input
                                                        {  ...register('email') }
                                                        type="email"
                                                        id="email"
                                                        className={errorFlag ? 'form-control is-invalid' : 'form-control'}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="email"
                                                        render={({ message }: any) => <span className="form-error">{message}</span>}
                                                    />
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="password" className="mb-2">Password</label>
                                                    <input 
                                                        {...register('password')}
                                                        type="password"
                                                        id="password"
                                                        className={errorFlag ? 'form-control is-invalid' : 'form-control'}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="password"
                                                        render={({ message }: any) => <span className="form-error">{message}</span>}
                                                    />
                                                </div>
                                                {
                                                    errorFlag ?
                                                    <div className="form-group mb-3">
                                                        <p className="invalid-feedback m-0">Invalid login credentials</p>
                                                    </div>
                                                    : <></>
                                                }
                                                <div className="form-group">
                                                    <div className="d-flex justify-content-end">
                                                        <div>
                                                        <ReCAPTCHA
                                                            { ...register('reCaptcha' , { required: true })}
                                                            sitekey="6Lcbpe0UAAAAAHsJsXEcaUjIKs6JSeDdGa3lCgL9"
                                                            onChange={(value: string) => setValue('reCaptcha', value)}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="reCaptcha"
                                                            render={() => <span className="form-error">{'Please complete the reCAPTCHA'}</span>}
                                                        />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-portlet__foot mt-4">
                                            <div className="kt-form__actions">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <div>
                                                            <Link to={{
                                                                pathname: '/passwordresetemail'
                                                            }}> Reset Password
                                                            </Link>
                                                        </div>
                                                        <div className="mt-2">
                                                            <Link to={{
                                                                pathname: '/requestaccess'
                                                            }}> Request access
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button type="submit" className="btn btn-primary">
                                                            {
                                                                busy ? <div className="kt-spinner kt-spinner--md kt-spinner--success"></div>
                                                                : 'Login'
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default Login;