import React from 'react';
import { useSelector } from 'react-redux';
import { ILeadTotals, LeadMetric } from '../../interfaces/leads';
import { ISessionState } from '../../interfaces/session';
import { SessionType as SessionTypeKeyName } from '../../interfaces/session';

type Props = {
    metric: LeadMetric;
    handleChange(tab: LeadMetric, isProviderData: boolean): void;
    isProviderData: boolean;
    propertyTotals: ILeadTotals;
    orgTotals: ILeadTotals;
    selNHID: number;
};

interface ITab {
    name: string;
    value: LeadMetric;
    isProviderData: boolean;
}

const tabs: ITab[] = [
    {
        name: 'All Channels',
        value: 'All',
        isProviderData: false
    },
    {
        name: 'Facility Page Views',
        value: 'PageViews',
        isProviderData: false
    },
    {
        name: 'Provider Page Views',
        value: 'PageViews',
        isProviderData: true
    },
    {
        name: 'Phone Number Views',
        value: 'PhoneNumberViews',
        isProviderData: false
    },
    {
        name: 'Website Referrals',
        value: 'WebsiteReferrals',
        isProviderData: false
    },
    {
        name: 'Email Inquiries',
        value: 'EmailEnquiryCount',
        isProviderData: false
    },
    {
        name: 'Brochure Downloads',
        value: 'EmailBrochureCount',
        isProviderData: false
    }
];

export default function LeadInsightsTabs(props: Props) {
    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );
    const isOrgSession = sessionState?.Session?.SessionType === SessionTypeKeyName.SessionTypeOrg;

    return (
        <ul>
            {
                tabs.map((tab: ITab, index: number) => {
                    const active = tab.value === props.metric && tab.isProviderData === props.isProviderData;

                    if (!isOrgSession && sessionState.Properties.length === 1 && tab.isProviderData) {
                        return null;
                    }

                    const key = tab.value as keyof ILeadTotals;

                    return (
                        <li
                            key={index}
                            className={`items-center ${active ? 'selected' : ''} ${tab.value === 'All' ? 'all-tab' : ''}`}
                            onClick={() => props.handleChange(tab.value, tab.isProviderData)}
                        >
                            <span>
                                {
                                    tab.value === 'All' ? null
                                    : tab.isProviderData
                                        ? props.orgTotals[key]?.toLocaleString() || 0
                                        : props.propertyTotals[key]?.toLocaleString() || 0 
                                }
                            </span>
                            {tab.name}
                        </li>
                    );
                })
            }
        </ul>
    );
}