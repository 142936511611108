import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MeetTheTeamMemberType } from '../../types';
import { columnStyles } from '../style';
import {
    DeleteTeamMemberDialogButton,
    ProfileImage,
    EditTeamMemberModal
} from '../../components';

interface Props extends MeetTheTeamMemberType {
    index: number;
}

const DraggableMemberCard: FC<Props> = (props) => {
    const {
        id,
        index,
        sequence,
        photoUrl,
        firstName,
        lastName,
        publishWebsite,
        position,
        yearStarted,
        description
    } = props;

    const isPublishedToWebsiteIcon = publishWebsite ? (
        <span className="fa fa-check text-success fs-4" />
    ) : (
        <span className="fa fa-close text-danger fs-4" />
    );

    const details = (
        <>
            <div style={columnStyles[0]}>{sequence}</div>
            <div style={columnStyles[1]}>
                <ProfileImage
                    style={{ width: '160px', height: '160px' }}
                    imgUrl={photoUrl}
                />
            </div>
            <div style={columnStyles[2]}>{firstName} {lastName}</div>
            <div style={columnStyles[3]}>{position}</div>
            <div style={columnStyles[4]}>{yearStarted}</div>
            <div style={columnStyles[5]}>{description}</div>
            <div style={columnStyles[6]}>{isPublishedToWebsiteIcon}</div>
            <div style={columnStyles[7]}>
                <DeleteTeamMemberDialogButton
                    memberDetails={props}
                    isInSinglePropertyContext={true}
                />
                <EditTeamMemberModal
                    memberDetails={props}
                    isInSinglePropertyContext={true}
                />
            </div>
        </>
    );

    return (
        <Draggable key={id} draggableId={String(id)} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {details}
                </div>
            )}
        </Draggable>
    );
};

export { DraggableMemberCard };
