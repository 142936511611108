import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getEmailStats } from '../../api/email';
import { LoadingSpinner } from '../common/loading_spinner';

type Props = {
    selNHID: number;
};

export const LeadsEmailLog = (props: Props) => {
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ stats, setStats ] = useState<any>({});

    const getStats = async () => {
        const params: any = {};

        if (props.selNHID) {
            params.NHIDs = [props.selNHID];
        }

        const data = await getEmailStats(params);
        setStats(data);
    };

    useEffect(() => {
        getStats();
        setLoading(false);
    }, [props.selNHID]);

    const renderWidget = () => {
        return (
            <>
                <div className="card" data-card="Profile Completeness">
                    <div className="card-header">
                        <h4>
                            Email Inquiry &amp; Brochure Download Log
                        </h4>
                    </div>
                    <div className="card-body flex-col pb-0">
                        <div className="provider-review-score-cards m-0">
                            <div className="m-0 p-0 border-0">
                                <span className="card-stat position-relative">
                                    {loading ? <LoadingSpinner show={true} showInPlace={true} /> : stats?.EmailEnquiryCount || 0}
                                </span> 
                                <div>
                                    <h4>Email Inquiries</h4>
                                </div>
                            </div>
                        </div>
                        <div className="provider-review-score-cards">
                            <div className="">
                                <span className="card-stat position-relative">
                                    {loading ? <LoadingSpinner show={true} showInPlace={true} /> : stats?.EmailBrochureCount || 0}
                                </span> 
                                <div>
                                    <h4>Brochure Downloads</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer justify-content-end">
                        <NavLink to="/leadslog" className="btn btn-primary text-nowrap">
                            View Email Log
                        </NavLink>
                    </div>
                </div>  
            </>
        );    
    };

    return (
        <>
            { renderWidget() }
        </>
    );
};
