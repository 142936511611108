import { secureHttp } from '../utils/request';

export const getWebsiteEnquiries = async (params: any) => {
    try {
        const { data: { data, totalRows } } = await secureHttp.get('/email/websiteenquiry', {params});
        return [data, totalRows];
    } catch (e) {
        return [];
    }
};

export const exportWebsiteEnquiries = async (params: any) => {
    try {
        const { data: { data, totalRows } } = await secureHttp.get('/email/websiteenquiry/export', {params});
        return [data, totalRows];
    } catch (e) {
        return [];
    }
};

export const getTemplateTypes = async (params: any) => {
    try {
        const { data: { data } } = await secureHttp.get('/email/templatetype', {params});
        return data;
    } catch (e) {
        return [];
    }
};

export const getEmailStats = async (params: any) => {
    try {
        const { data: { data } } = await secureHttp.get('/email/stats', {params});
        return data;
    } catch (e) {
        return [];
    }
};
