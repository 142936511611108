import React, { useState, useEffect, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import LeadsInsightsChart from '../charts/lead_insights_chart';
import { ILeadTotals, LeadMetric } from '../../interfaces/leads';
import LeadInsightsTabs from '../leads/lead_insights_tabs';
import Select from 'react-select';
import { getEmailStats, getTemplateTypes } from '../../api/email';
import {
    getOrgLeads,
    getPropertyLeads,
    getOrgLeadTotals,
    getPropertyLeadTotals
} from '../../api/leads';
import LeadInsightsTable from '../leads/lead_insights_table';
import { ISessionState } from '../../interfaces/session';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import LeadsInsightsChartAll from '../charts/lead_insights_chart_all';

type Props = {
    selNHID: number;
    showTable?: boolean;
};

export const LeadsWidget = (props: Props) => {
    const location = useLocation();
    const path = location.pathname;

    const { Session, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const isOrgMulti = Session?.SessionType === 'org' || Properties?.length > 1;

    const [metric, setMetric] = useState<LeadMetric>('All');
    const [isProviderData, setIsProviderData] = useState<boolean>(false);
    const [filters, setFilters] = useState<any>({
        DataPoint: 'month'
    });
    const [templateTypes, setTemplateTypes] = useState<any[]>([]);

    const [propertyTotals, setPropertyTotals] = useState<ILeadTotals>({
        PageViews: 0,
        PhoneNumberViews: 0,
        WebsiteReferrals: 0,
        EmailEnquiryCount: 0,
        EmailBrochureCount: 0
    });

    const [orgTotals, setOrgTotals] = useState<ILeadTotals>({
        PageViews: 0,
    });

    const dataPointOptions = [
        {
            label: 'Month',
            value: 'month'
        },
        {
            label: 'Week',
            value: 'week'
        },
        {
            label: 'Day',
            value: 'day'
        }
    ];

    const updateFilter = (key: string, value: any) => setFilters((prev: any) => ({
        ...prev,
        [key]: value
    }));

    const resetFilter = (key: string) => {
        const curr = {...filters};
        delete curr[key];
        setFilters(curr);
    };

    const getTotals = async () => {
        const params: any = {};

        if (props.selNHID) {
            params.NHIDs = [props.selNHID];
        }

        const [
            propertyData,
            orgData,
            emailData
        ] = await Promise.all([
            getPropertyLeadTotals(params),
            getOrgLeadTotals(params),
            getEmailStats(params)
        ]);

        setPropertyTotals({...propertyData, ...emailData});
        setOrgTotals(orgData);
    };

    const getTypeOptionsData = async () => {
        const resp = await getTemplateTypes({
            KeyNames: ['website-enquiry-to-property', 'brochure-request-to-property']
        });

        const data = resp.map((type: any) => ({
            label: type.ShortDisplayName,
            value: type.TemplateTypeID
        }));

        setTemplateTypes(data);
    };

    const [data, setData] = useState<any[]>([]);

    const getData = async () => {
        const orgLeadData = await getOrgLeads(filters);

        if (isProviderData) {
            setData(orgLeadData);
            return;
        }

        const leadData = await getPropertyLeads(filters);
        setData(leadData);
    };

    useEffect(() => {
        getData();
    }, [filters, isProviderData]);

    useEffect(() => {
        getTotals();
    }, [filters]);

    useEffect(() => {
        getTypeOptionsData();
    }, []);

    useEffect(() => {
        if (!props.selNHID) {
            resetFilter('NHIDs');
            return;
        }

        updateFilter('NHIDs', [props.selNHID]);
    }, [props.selNHID]);

    return (
        <>
            {
                path === '/leads'
                ? <div className="row mt-4">
                    <div className="col-md-12 d-flex justify-content-center align-items-center">
                        <div className="me-4">
                            <label className="text-nowrap me-2" htmlFor="DateFrom">Date From:</label>
                            <DatePicker
                                id="DateFrom"
                                className="form-control"
                                selected={filters.DateFrom}
                                onChange={(date: Date) => {
                                    if (date) {
                                        date.setHours(0, 0, 0, 0);
                                    }

                                    updateFilter('DateFrom', date);
                                }}
                                dateFormat="dd MMM yyyy"
                                placeholderText="Click to select a date"
                                value={filters.DateFrom as any}
                            />
                        </div>
                        <div className="me-4">
                            <label className="text-nowrap me-2" htmlFor="DateTo">Date To:</label>
                            <DatePicker
                                id="DateTo"
                                className="form-control"
                                selected={filters.DateTo}
                                onChange={(date: Date) => {
                                    if (date) {
                                        date.setHours(0, 0, 0, 0);
                                    }

                                    updateFilter('DateTo', date);
                                }}
                                dateFormat="dd MMM yyyy"
                                placeholderText="Click to select a date"
                                value={filters.DateTo as any}
                                minDate={filters.DateFrom}
                            />
                        </div>
                        <div>
                            <span
                                className="text-primary cursor-pointer"
                                onClick={() => {
                                    updateFilter('DateFrom', null);
                                    updateFilter('DateTo', null);
                                }}
                            >
                                Clear filter
                            </span>
                        </div>
                    </div>
                </div>
                : null
            }
            <div className="card" data-card="Leads">
                {
                    path === '/leads'
                        ? null
                        : <div className="card-header">
                            <h4>Leads</h4>
                            <div className="d-flex align-items-center justify-content-end">
                                <span className="me-4">
                                    Show leads by
                                </span>
                                <Select
                                    options={dataPointOptions}
                                    id="DataPoint"
                                    onChange={(option) => updateFilter('DataPoint', option.value)}
                                    value={dataPointOptions.find((item) => item.value === filters.DataPoint)}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                }
                <div className="card-body">
                    <div className="card-info-box">
                        
                        {
                            path === '/leads'
                            ? <div className="d-flex align-items-center justify-content-end mb-4">
                                <span className="me-4">
                                    Show leads by
                                </span>
                                <Select
                                    options={dataPointOptions}
                                    id="DataPoint"
                                    onChange={(option) => updateFilter('DataPoint', option.value)}
                                    value={dataPointOptions.find((item) => item.value === filters.DataPoint)}
                                    isSearchable={false}
                                />
                            </div> : null
                        }
                        <LeadInsightsTabs
                            metric={metric}
                            handleChange={(metric: LeadMetric, isProvider: boolean) => {
                                setMetric(metric);
                                setIsProviderData(isProvider);
                            }}
                            isProviderData={isProviderData}
                            propertyTotals={propertyTotals}
                            orgTotals={orgTotals}
                            selNHID={props.selNHID}
                        />
                    </div>
                    {
                        metric === 'All'
                        ? <LeadsInsightsChartAll
                            filters={filters}
                            data={data}
                        />
                        : <LeadsInsightsChart
                            data={data}
                            metric={metric}
                            filters={filters}
                            isProviderData={isProviderData}
                        />
                    }
                </div>
                {
                    props.showTable
                    ? <div className="card-info-box mt-4">
                        <LeadInsightsTable
                            data={data}
                            dataPoint={filters.DataPoint}
                            totals={propertyTotals}
                            filters={filters}
                            templateTypes={templateTypes}
                        />
                    </div> 
                    : null
                }
                {
                    path === '/leads'
                    ? null
                    :
                    <div className="card-footer justify-content-end">
                        {
                            isOrgMulti
                            ? <NavLink to="/leadbreakdown" className="btn btn-primary text-nowrap me-2">
                                View Breakdown
                            </NavLink>
                            : null
                        }
                        <NavLink to="/leads" className="btn btn-primary text-nowrap">
                            View Overview
                        </NavLink> 
                    </div>
                }
            </div>
        </>
    );
};
