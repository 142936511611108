import React, { useEffect, useState } from 'react';
import { ReviewCardsOrderProperty, IReviewCardsOrderProperty } from '../../interfaces/review_cards';
import { DataRows } from '../../interfaces/common';
import * as ReviewCardsApi from '../../api/review_cards';
import DataTable, { DataTableSortOrder } from '../../components/common/data_table';
import _ from 'lodash';
import { LoadingSpinner } from '../../components/common/loading_spinner';

interface IProps {
    data: any;
}

export const OrderHistoryDetails = ({data}: IProps) => {

    const [busyLoadingSubOrders, setBusyLoadingSubOrders] = useState<boolean>();
    const [subOrders, setSubOrders] = useState<ReviewCardsOrderProperty[]>([]);

    const {PostalCardsOrderID} = data;

    const getSubOrders = async (orderID: number): Promise<DataRows<ReviewCardsOrderProperty>> => {
        return await ReviewCardsApi.getOrder(orderID, {
            SortField: 'Name',
            SortOrder: DataTableSortOrder.Ascending
        });
    };

    useEffect(() => {
        if (PostalCardsOrderID) {
            setBusyLoadingSubOrders(true);
            (async () => {
                try {
                    const resp = await getSubOrders(PostalCardsOrderID);
                    const subOrderData = _.get(resp, 'data', []);
                    setSubOrders(subOrderData);
                } catch (e) {
                    console.error(e);
                } finally {
                    setBusyLoadingSubOrders(false);
                }
            })();
        } else {
            setSubOrders(null);
        }
    }, [PostalCardsOrderID]);

    const PropertyNameElement = function statelessFunctionComponentClass(props: any) {
        const formatAddress = (property: IReviewCardsOrderProperty) => {
            return [
                property.Address1,
                property.Address2,
                property.Address3,
                property.City,
                property.RegionCode,
                property.PostCode
            ].reduce((total: string, value: string) => {
                return value && value.length > 0 ? `${total}, ${value}` : total;
            });
        };

        return (
            <div className="my-1">
                <strong>
                    {props.row.PropertyName}
                </strong>
                <small className="d-block lh-sm">
                    {formatAddress(props.row)}
                </small>
            </div>
        );
    };

    const subOrdersColumns = [
        {
            name: 'Property',
            selector: (row: IReviewCardsOrderProperty) => row.PropertyName,
            sortable: true,
            sortField: 'PropertyName',
            width: '300px',
            cell: (row: IReviewCardsOrderProperty) => <PropertyNameElement row={row}/>
        }, {
            name: 'Recipient First Name',
            selector: (row: IReviewCardsOrderProperty) => row.RecipientFirstName,
            sortable: true,
            sortField: 'RecipientFirstName',
            width: '240px'
        }, {
            name: 'Recipient Last Name',
            selector: (row: IReviewCardsOrderProperty) => row.RecipientLastName,
            sortable: true,
            sortField: 'RecipientLastName',
            width: '240px'
        }, {
            name: 'Cards',
            selector: (row: IReviewCardsOrderProperty) => row.CardsQuantity,
            sortable: true,
            sortField: 'CardsQuantity',
            width: '120px',
            cell: (row: IReviewCardsOrderProperty) => {
                return row.CardsQuantity ?
                    <i className="fa fa-solid fa-check"></i>
                    :
                    null;
            }
        }, {
            name: 'Stands',
            selector: (row: IReviewCardsOrderProperty) => row.StandsQuantity,
            sortable: true,
            sortField: 'StandsQuantity',
            width: '120px',
            cell: (row: IReviewCardsOrderProperty) => {
                return row.StandsQuantity ?
                    <i className="fa fa-solid fa-check"></i>
                    :
                    null;
            }
        }
    ];

    return (
        <div className="p-4 review-cards-sub-orders-dt">
            {busyLoadingSubOrders ? <LoadingSpinner show={true}/> : null}
            <div className="mb-2"><b>Order Number:</b> {PostalCardsOrderID}</div>
            <DataTable
                data={subOrders}
                columns={subOrdersColumns}
                pagination={false}
                initialRowsPerPage={10}
                defaultSortFieldId={1}
                fixedHeader={true}
                noDataComponent={<span></span>}
            />
        </div>
    );
};
