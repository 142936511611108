import React, { FC, InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    name: string;
    inline?: boolean;
    description?: ReactNode;
}

type FieldRequired = { isRequired: (fieldName: string) => boolean };

const FormInput: FC<Props> = (props) => {
    const {
        name,
        id,
        label,
        className,
        inline = true,
        description,
        ...rest
    } = props;

    return (
        <ConnectForm>
            {({
                register,
                formState,
                isRequired
            }: UseFormReturn & FieldRequired) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];

                const errorMessage: ReactElement = (
                    <small className="text-danger position-absolute">
                        {fieldError?.message}
                    </small>
                );

                const inputClassName: string = cx(
                    'form-control',
                    {
                        'border border-danger': fieldError
                    },
                    className
                );

                const inputId: string = id || name;

                const inputWrapperClassName = cx({
                    'mt-3': label,
                    row: inline
                });

                const labelClassName = cx('fw-label fs-5', {
                    'col-sm-4 col-lg-3 d-flex justify-content-center font-weight-bold m-0':
                        inline,
                    'mb-1': !inline
                });

                return (
                    <div
                        className={inputWrapperClassName}
                        style={fieldError ? { marginBottom: '30px' } : {}}
                    >
                        {label && (
                            <div
                                className={cx(
                                    'd-flex flex-column',
                                    labelClassName
                                )}
                            >
                                <label htmlFor={inputId}>
                                    {label}
                                    {isRequired(name) && '*'}
                                </label>
                                {description && (
                                    <span className="fw-normal fst-italic fs-6 lh-sm text-muted">
                                        {description}
                                    </span>
                                )}
                            </div>
                        )}
                        <div
                            className={cx(
                                'position-relative',
                                { 'col-sm-8 col-lg-9': inline && label },
                                { 'col-sm-12': inline && !label }
                            )}
                        >
                            <input
                                id={inputId}
                                className={inputClassName}
                                {...register?.(name)}
                                {...rest}
                            />

                            {fieldError && errorMessage}
                        </div>
                    </div>
                );
            }}
        </ConnectForm>
    );
};

export { FormInput };
