import React, {
    ChangeEvent,
    FC,
    forwardRef,
    ForwardRefRenderFunction,
    InputHTMLAttributes,
    ReactElement
} from 'react';
import { ConnectForm } from '../form/form';
import cx from 'classnames';
import { UseFormReturn } from 'react-hook-form';

type RadioOption = {
    value: string;
    label: string;
};

interface GroupProps {
    groupLabel?: string;
    name: string;
    options?: RadioOption[];
    inline?: boolean;
    inlineOptions?: boolean;
}

interface RadioButtonProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    value: string;
    label: string;
    name: string;
}

const FormRadioGroup: FC<GroupProps> = (props) => {
    const {
        name,
        options,
        groupLabel,
        inline = true,
        inlineOptions = true
    } = props;
    inline;

    return (
        <ConnectForm>
            {({ register, formState, watch, setValue }: UseFormReturn) => {
                const fieldError: { message?: string } =
                    formState?.errors?.[name];
                const errorMessage: ReactElement = (
                    <small className="text-danger">{fieldError?.message}</small>
                );

                const optionsWrapperClassName = cx(
                    'position-relative d-flex',
                    { 'col-sm-8 col-lg-9': inline && groupLabel },
                    { 'col-sm-12': inline && !groupLabel },
                    {
                        'gap-8 flex-row': inlineOptions,
                        'gap-2 flex-column': !inlineOptions
                    }
                );

                const handleChange = ({
                    target
                }: ChangeEvent<HTMLInputElement>) => {
                    setValue(name, target?.value);
                };

                const inputWrapperClassName = cx({
                    'mt-3': groupLabel,
                    row: inlineOptions
                });

                const labelClassName = cx('fw-label fs-5', {
                    'col-sm-4 col-lg-3 d-flex align-items-center m-0':
                        inlineOptions,
                    'mb-1': !inlineOptions
                });

                return (
                    <div
                        className={inputWrapperClassName}
                        style={fieldError ? { marginBottom: '30px' } : {}}
                    >
                        {groupLabel && (
                            <div className={labelClassName}>{groupLabel}</div>
                        )}
                        <div className={optionsWrapperClassName}>
                            {options.map((option) => {
                                return (
                                    <RadioButtonWithRefs
                                        checked={option.value === watch(name)}
                                        value={option.value}
                                        label={option.label}
                                        key={option.value}
                                        className="me-2"
                                        {...register(name)}
                                        onChange={handleChange}
                                    />
                                );
                            })}
                        </div>
                        {fieldError && errorMessage}
                    </div>
                );
            }}
        </ConnectForm>
    );
};

const RadioButton: ForwardRefRenderFunction<any, RadioButtonProps> = (
    props,
    ref
) => {
    const { id, label: labelText, className, value, ...rest } = props;

    const inputId: string = id || value;
    const inputClassName: string = cx('form-radio', className);

    return (
        <label
            htmlFor={inputId}
            style={{ height: '37px' }} // same as text input height
            className={cx(
                'kt-radio kt-radio--brand m-0 d-flex align-items-center'
            )}
        >
            <input
                type="radio"
                id={inputId}
                className={inputClassName}
                value={value}
                ref={ref}
                {...rest}
            />
            <span />
            {labelText}
        </label>
    );
};

const RadioButtonWithRefs = forwardRef(RadioButton);

export { FormRadioGroup };
