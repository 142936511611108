import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { ISessionState } from '../../interfaces/session';
import { getTestUsers } from '../../api/login';

import _ from 'lodash';

interface IProps {
    handleSessionSelect: any;
}
const HeaderSessionSelect = ({handleSessionSelect}:IProps) => {
    const [options, setOptions] = useState<any[]>([]);

    const sessionState: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    const { Session } = sessionState || {};

    useEffect(() => {

        const fetchUsers = async() => {
            const resp = await getTestUsers();
            const users = _.get(resp, 'data.Users');

            const options = users.map((user: any) => ({
                label: `${user.FirstName} ${user.LastName}`,
                value: { email: user.EmailAddress }
            }));

            setOptions(options);
        };

        fetchUsers();
    }, []);

    const renderSessionSelect = () => {
    
        const getCurSessionSelectOption = () => {

            const { EmailAddress } = Session || {};

            if(!EmailAddress) {
                return null;
            }

            const curOption = options.find((item: any) => (
                item.value.email === EmailAddress));

            return curOption;
        };
        return (
            <>
                <Select
                    id="SessionSelect"
                    options={options}
                    name="SessionSelect"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={getCurSessionSelectOption()}
                    onChange={(option: any) => handleSessionSelect(option.value)}
                />
            </>
        );
    };

    return renderSessionSelect();
};

export default HeaderSessionSelect;
