import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { 
    IPropertyServiceExtras,
    IPropertyServiceExtrasHeader,
    IPropertyExtrasPayloadItem,
    IPropertyService,
    IPropertyServiceCategory,
    IPropertyServiceCategoryType,
    IPropertyServicesPayloadItem,
    IPropertyServiceResource,
    IUpdatePropertyServicesPayload,
    IPropertyServiceCustomExtra,
    IPropertyCustomExtrasPayloadItem
} from '../../interfaces/service';
import { 
    getPropertyServiceCustomExtras,
    getPropertyServiceExtras,
    getPropertyServices,
    updatePropertyServices
} from '../../api/service';

import { ISelectOption } from '../../interfaces/form';
import { LoadingSpinner } from '../common/loading_spinner';
import { Toast, ToastType, showToast } from '../../utils/toast';
import { isDefined } from '../../utils/common';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

interface IProps {
    nhIDs: number[];
    orgNHID: number;
    selNHID: number;
}

const PropertyServiceForm = ({ nhIDs, orgNHID, selNHID }: IProps) => {
    const [ busy, setBusy ] = useState<boolean>(false);
    const [ serviceData, setServiceData ] = useState<IPropertyService[]>();
    const [ serviceCategoryTypes, setServiceCategoryTypes ] = useState<IPropertyServiceCategoryType[]>();
    const [ serviceSelectedValueMap, setServiceSelectedValueMap ] = useState<Map<number, boolean | null>>();
    const [ serviceExtraInitialOptionMap, setServiceExtraInitialOptionMap ] = useState<Map<number, number>>();
    const [ serviceExtraHeaders, setServiceExtraHeaders ] = useState<IPropertyServiceExtrasHeader[]>();
    const [ serviceExtraOptionsMap, setServiceExtraOptionsMap ] = useState<Map<number, ISelectOption[]>>();
    const [ serviceExtraSelectedOptionMap, setServiceExtraSelectedOptionMap ] = useState<Map<number, ISelectOption>>();
    const [ serviceExtrasPayload, setServiceExtrasPayload ] = useState<IPropertyExtrasPayloadItem[]>([]);
    const [ servicePayload, setServicePayload ] = useState<IPropertyServicesPayloadItem[]>([]);

    const [ serviceCustomExtraData, setServiceCustomExtraData ] = useState<IPropertyServiceCustomExtra[]>([]);
    const [ serviceCustomExtraHeaderMap, setServiceCustomExtraHeaderMap ] = useState<Map<number, IPropertyCustomExtrasPayloadItem>>();
    const [ serviceCustomExtraPayload, setServiceCustomExtraPayload ] = useState<IPropertyCustomExtrasPayloadItem[]>([]);

    const [ includedInProfileCompleteness, setIncludedInProfileCompleteness ] = useState<boolean | null>(null);

    useEffect(() => initForm(), [ selNHID ]);

    useEffect(() => {  
        if (!serviceExtraSelectedOptionMap) {
            return;
        }

        buildServiceExtraPayload();
    }, [serviceExtraSelectedOptionMap]);

    useEffect(() => {  
        if (!serviceData) {
            return;
        }

        const modifiedServiceData = isDefined(includedInProfileCompleteness) 
            ? serviceData.filter((service: IPropertyService) => 
                service.IncludedInProfileCompleteness === includedInProfileCompleteness
            )
            : serviceData;

        buildServiceOptions(modifiedServiceData);
    }, [serviceData, includedInProfileCompleteness]);

    useEffect(() => {  
        if (!serviceCustomExtraData) {
            return;
        }

        buildServiceCustomExtraHeaderMap(serviceCustomExtraData);
    }, [serviceCustomExtraData]);

    useEffect(() => {  
        if (!serviceCustomExtraHeaderMap) {
            return;
        }
        
        buildServiceCustomExtraPayload();
    }, [serviceCustomExtraHeaderMap]);

    const initForm = () => {
        setServiceExtrasPayload([]);          
        setServicePayload([]);   
        fetchServiceOptionsForProperty();    
    };

    const fetchServiceOptionsForProperty = async () => {
        const [
            services,
            serviceExtras,
            serviceCustomExtras
        ]: [
            IPropertyService[],
            IPropertyServiceExtras[],
            IPropertyServiceCustomExtra[]
        ] = await Promise.all([
            getPropertyServices(selNHID),
            getPropertyServiceExtras(selNHID),
            getPropertyServiceCustomExtras(selNHID)
        ]);
        
        if (services) {
            setServiceData(services);
        }
        if (serviceExtras) {
            buildServiceExtraOptions(serviceExtras);
        }
        if (serviceCustomExtras) {
            setServiceCustomExtraData(serviceCustomExtras);
        }
    };

    const buildServiceCustomExtraHeaderMap = (serviceCustomExtras: IPropertyServiceCustomExtra[]) => {
        const serviceCustomExtraHeadersMap = new Map<number, IPropertyCustomExtrasPayloadItem>;

        serviceCustomExtras
            .filter((item: IPropertyServiceCustomExtra) => item.Description)
            .forEach(({ ResourceCustomExtraHeaderID, Description }: IPropertyServiceCustomExtra) => 
                serviceCustomExtraHeadersMap.set(ResourceCustomExtraHeaderID, {
                    ResourceCustomExtraHeaderID,
                    Description
            })
        );

        setServiceCustomExtraHeaderMap(serviceCustomExtraHeadersMap);
    };

    const buildServiceExtraOptions = async (serviceExtras: IPropertyServiceExtras[]) => {

        const serviceExtraHeadersMap = new Map<number, IPropertyServiceExtrasHeader>;
        const serviceExtrasOptionsMap = new Map<number, ISelectOption[]>;
        const serviceExtraSelectedOptionMap = new Map<number, ISelectOption>;
        const serviceExtraInitialOptionMap = new Map<number, number>;

        serviceExtras.forEach(({
            ResourceExtraHeaderID,
            ResourceExtraHeaderName,
            ResourceExtraOptionID,
            ResourceExtraOptionName,
            IsActive
        }: IPropertyServiceExtras) => {
            if (!serviceExtraHeadersMap.has(ResourceExtraHeaderID)) {
                serviceExtraHeadersMap.set(ResourceExtraHeaderID, {
                    ResourceExtraHeaderID,
                    ResourceExtraHeaderName
                });
            }

            if (!serviceExtrasOptionsMap.has(ResourceExtraHeaderID)) {
                serviceExtrasOptionsMap.set(ResourceExtraHeaderID, []);
            }

            const option: ISelectOption = {
                label: ResourceExtraOptionName,
                value: { ResourceExtraHeaderID, ResourceExtraOptionID }
            };
            
            if (IsActive) {
                serviceExtraSelectedOptionMap.set(ResourceExtraHeaderID, option);
                serviceExtraInitialOptionMap.set(ResourceExtraHeaderID, ResourceExtraOptionID);
            }

            serviceExtrasOptionsMap
                .get(ResourceExtraHeaderID)
                .push({
                    label: ResourceExtraOptionName,
                    value: { ResourceExtraHeaderID, ResourceExtraOptionID }
                });
        });

        const serviceExtraHeaderArr: IPropertyServiceExtrasHeader[] = [...serviceExtraHeadersMap]
            .map(([, ResourceExtraHeader ]): IPropertyServiceExtrasHeader => ResourceExtraHeader
        );

        setServiceExtraHeaders(serviceExtraHeaderArr);
        setServiceExtraOptionsMap(serviceExtrasOptionsMap);
        setServiceExtraSelectedOptionMap(serviceExtraSelectedOptionMap);
        setServiceExtraInitialOptionMap(serviceExtraInitialOptionMap);
    };

    const buildServiceOptions = async (services: IPropertyService[]) => {

        const serviceCategoryTypesMap = new Map<number, IPropertyServiceCategoryType>;
        const serviceSelectedValueMap = new Map<number, boolean | null>;

        services.forEach(({
            ResourceCategoryTypeID,
            ResourceCategoryTypeName,
            ResourceCategoryID,
            ResourceCategoryName,
            ResourceID,
            ResourceName,
            IsActive,
            IncludedInProfileCompleteness
        }: IPropertyService) => {
            if (!serviceCategoryTypesMap.has(ResourceCategoryTypeID)) {
                serviceCategoryTypesMap.set(ResourceCategoryTypeID, {
                    ResourceCategoryTypeID,
                    ResourceCategoryTypeName,
                    CategoryMap: new Map<number, IPropertyServiceCategory>
                });
            }

            const categoryMap = serviceCategoryTypesMap.get(ResourceCategoryTypeID).CategoryMap;

            if (!categoryMap.has(ResourceCategoryID)) {
                categoryMap.set(ResourceCategoryID, {
                    ResourceCategoryID,
                    ResourceCategoryName,
                    Resources: []
                });
            }

            categoryMap.get(ResourceCategoryID).Resources.push({
                ResourceID,
                ResourceName,
                IsActive,
                IncludedInProfileCompleteness
            });

            serviceSelectedValueMap.set(ResourceID, IsActive);
        });

        const serviceCategoryTypesArr: IPropertyServiceCategoryType[] = [...serviceCategoryTypesMap]
            .map(([, ResourceCategoryType]): IPropertyServiceCategoryType => ResourceCategoryType
        );

        setServiceCategoryTypes(serviceCategoryTypesArr);
        setServiceSelectedValueMap(serviceSelectedValueMap);
    };


    const handleServiceExtraChanged = (
        resourceExtraHeaderID: number,
        option: ISelectOption
    ) => {
        const modifiedSelectedOptionMap = new Map(serviceExtraSelectedOptionMap);

        modifiedSelectedOptionMap.set(resourceExtraHeaderID, option);
        setServiceExtraSelectedOptionMap(modifiedSelectedOptionMap);
    };

    const handleServiceExtraRemoved = (resourceExtraHeaderID: number) => {
        const modifiedSelectedOptionMap = new Map(serviceExtraSelectedOptionMap);

        modifiedSelectedOptionMap.delete(resourceExtraHeaderID);
        setServiceExtraSelectedOptionMap(modifiedSelectedOptionMap);
    };

    const handleServiceCustomExtraChanged = (
        resourceCustomExtraHeaderID: number,
        text: string
    ) => {
        const modifiedCustomExtraHeaderMap = new Map(serviceCustomExtraHeaderMap);

        const description = text?.trim().length ? text : null;

        if (!description) {
            const existingService = serviceCustomExtraData.find((item: IPropertyServiceCustomExtra) => 
                item.ResourceCustomExtraHeaderID === resourceCustomExtraHeaderID
            );

            if (!existingService) {
                modifiedCustomExtraHeaderMap.delete(resourceCustomExtraHeaderID);
                setServiceCustomExtraHeaderMap(modifiedCustomExtraHeaderMap);
                
                return;
            }
        }

        modifiedCustomExtraHeaderMap.set(resourceCustomExtraHeaderID, {
            ResourceCustomExtraHeaderID: resourceCustomExtraHeaderID,
            Description: description
        });

        setServiceCustomExtraHeaderMap(modifiedCustomExtraHeaderMap);
    };

    const handleServiceChanged = (
        resourceID: number,
        currentStatus: boolean | null,
        status: boolean | null
    ) => {
        const modifiedServiceSelectedValueMap = new Map(serviceSelectedValueMap);

        modifiedServiceSelectedValueMap.set(resourceID, status);
        setServiceSelectedValueMap(modifiedServiceSelectedValueMap);

        buildServicePayload(resourceID, currentStatus, status);
    };  

    const buildServiceExtraPayload = () => { 
        const serviceExtrasPayload: IPropertyExtrasPayloadItem[] = [];

        serviceExtraSelectedOptionMap.forEach((option: ISelectOption) => {
            serviceExtrasPayload.push(option.value);
        });

        setServiceExtrasPayload(serviceExtrasPayload);
    };

    const buildServiceCustomExtraPayload = () => { 
        const serviceCustomExtraPayload: IPropertyCustomExtrasPayloadItem[] = [];

        serviceCustomExtraHeaderMap.forEach((item: IPropertyCustomExtrasPayloadItem) => {
            serviceCustomExtraPayload.push(item);
        });

        setServiceCustomExtraPayload(serviceCustomExtraPayload);
    };

    const buildServicePayload = (
        resourceID: number,
        currentStatus: boolean | null,
        status: boolean | null
    ) => {
        const modifiedServicePayload = [ ...servicePayload ];

        const existingndex = modifiedServicePayload.findIndex((resource: IPropertyServicesPayloadItem) => 
            resource.ResourceID === resourceID
        );

        if (existingndex > -1) {
            modifiedServicePayload.splice(existingndex, 1);
        }

        if (
            status !== currentStatus &&
            (currentStatus !== null || status !== null)
        ) {
            modifiedServicePayload.push({
                ResourceID: resourceID,
                Status: status
            });
        }

        setServicePayload(modifiedServicePayload);
    };

    const handleSubmit = async () => {
        const payload: IUpdatePropertyServicesPayload = {};

        if (serviceCustomExtrasUpdated) {
            payload.ServiceCustomExtras = serviceCustomExtraPayload;
        }

        if (serviceExtrasUpdated) {
            payload.ServiceExtras = serviceExtrasPayload;
        }

        if (servicePayload.length) {
            payload.Services = servicePayload;
        }

        if ((Object.keys(payload).length)) {
            setBusy(true);

            try {
                await updatePropertyServices(selNHID, payload);
                initForm();
                
                window.scrollTo(0, 0);

                showToast('Services & Amenities saved successfully. Please allow up to 60 minutes for the changes to show on the website.', ToastType.Success);
            } catch(e) {
                showToast('An error occurred saving Services & Amenities', ToastType.Error);
                console.log(e);
            } finally {
                setBusy(false);
                updateProfileCompleteness();
            }
        }
    };

    const serviceExtrasUpdated = ((): boolean => {
        if (!serviceExtraInitialOptionMap) {
            return false;
        }

        if (serviceExtraInitialOptionMap.size !== serviceExtrasPayload.length) {
            return true;
        }

        let dataChanged = false;
        for (const payloadItem of serviceExtrasPayload) {
            const { ResourceExtraHeaderID, ResourceExtraOptionID } = payloadItem;

            if (serviceExtraInitialOptionMap.get(ResourceExtraHeaderID) !== ResourceExtraOptionID) {
                dataChanged = true;
                break;
            }
        }

        return dataChanged;
    })();

    const serviceCustomExtrasUpdated = ((): boolean => {
        if (!serviceCustomExtraPayload) {
            return false;
        }

        let dataChanged = false;
        for (const payloadItem of serviceCustomExtraPayload) {
            const { ResourceCustomExtraHeaderID, Description } = payloadItem;

            const existingService = serviceCustomExtraData.find((item: IPropertyServiceCustomExtra) => 
                item.ResourceCustomExtraHeaderID === ResourceCustomExtraHeaderID
            );

            if (existingService.Description !== Description) {
                dataChanged = true;
                break;
            }
        }

        return dataChanged;
    })();

    const renderServiceExtraSection = ({
        ResourceExtraHeaderID,
        ResourceExtraHeaderName
    }: IPropertyServiceExtrasHeader) => {
        const key = `service-extra-header-${ResourceExtraHeaderID}`;

        return (
            <div key={key}>
                <label htmlFor={key} className="long">{ ResourceExtraHeaderName }</label>
                <div>
                    <Select
                        id={key}
                        onChange={(option: any) => handleServiceExtraChanged(ResourceExtraHeaderID, option)}
                        options={serviceExtraOptionsMap.get(ResourceExtraHeaderID)}
                        name={key}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={serviceExtraSelectedOptionMap.get(ResourceExtraHeaderID) || null}
                    />
                    <span onClick={() => handleServiceExtraRemoved(ResourceExtraHeaderID)}>
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </span>
                </div>
            </div>
        );
    };

    const renderServiceCustomExtraSection = ({
        ResourceCustomExtraHeaderID,
        ResourceCustomExtraHeaderName
    }: IPropertyServiceCustomExtra) => {
        const key = `service-extra-custom-header-${ResourceCustomExtraHeaderID}`;
        const value = serviceCustomExtraHeaderMap.get(ResourceCustomExtraHeaderID)?.Description || '';

        return (
            <div key={key}>
                <label htmlFor={key} className="long">{ ResourceCustomExtraHeaderName }</label>
                <div>
                    <input
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleServiceCustomExtraChanged(
                            ResourceCustomExtraHeaderID, 
                            e.target.value
                        )}
                        id={key}
                        className="form-control"
                        name={key}
                        maxLength={500}
                        value={value}
                    />
                    <span onClick={() => handleServiceCustomExtraChanged(ResourceCustomExtraHeaderID, null)}>
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </span>
                </div>
            </div>
        );
    };


    const renderServiceSection = ({
        ResourceID,
        ResourceName,
        IsActive,
        IncludedInProfileCompleteness
    }: IPropertyServiceResource) => {
        const key = `service-${ResourceID}`;

        return (
            <div key={key}>
                <label className="long">{ ResourceName }</label>
                <div>
                    <label className="long">
                        <input 
                            type="radio" 
                            name={key} 
                            checked={serviceSelectedValueMap.get(ResourceID) === true} 
                            onChange={() => handleServiceChanged(
                                ResourceID, 
                                IsActive, 
                                true
                            )}
                        />
                        <span>Yes</span>
                    </label>
                    <label className="long">
                        <input 
                            type="radio" 
                            name={key} 
                            checked={serviceSelectedValueMap.get(ResourceID) === false}
                            onChange={() => handleServiceChanged(
                                ResourceID, 
                                IsActive, 
                                false
                            )}
                        />
                        <span>No</span>
                    </label>
                    { IncludedInProfileCompleteness ? <div><span className="badge badge-secondary signpost">Included in Profile Completeness</span></div> : null }
                </div>
            </div>    
        );
    };

    const renderServiceCategorySection = ({
        ResourceCategoryID,
        ResourceCategoryName,
        Resources
    }: IPropertyServiceCategory) => {
        const key = `service-category-${ResourceCategoryID}`;

        return (
            <div key={key}>
                <h4>{ ResourceCategoryName }</h4>
                {
                    Resources.map((resource: IPropertyServiceResource) => ( 
                        renderServiceSection(resource)
                    ))
                }
        
            </div>            
        );
    };
    
    const renderServiceCategoryTypeSection = ({
        ResourceCategoryTypeID,
        ResourceCategoryTypeName,
        CategoryMap
    }: IPropertyServiceCategoryType) => {
        const key = `service-category-type-${ResourceCategoryTypeID}`;

        const serviceCategoryArr: IPropertyServiceCategory[] = [...CategoryMap]
            .map(([, ResourceCategory]): IPropertyServiceCategory => ResourceCategory
        );

        return (
            <div 
                className="card" 
                data-card="Form" 
                data-columns="1"
                key={key}
            >
                <div className="card-header">
                    <h4>
                        { ResourceCategoryTypeName }
                    </h4>
                </div>
                <div className="card-body">
                    <div className="card-info">
                        {
                            serviceCategoryArr.map((category: IPropertyServiceCategory) => ( 
                                renderServiceCategorySection(category)
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    };
    
    const renderLoading = () => <LoadingSpinner show={true} />;

    if (!serviceExtraHeaders || !serviceCategoryTypes || !serviceCustomExtraData) {
        return renderLoading();
    }

    const submitDisabled = (!serviceExtrasUpdated && !servicePayload.length && !serviceCustomExtrasUpdated) || busy;

    return (
        <>  
            { busy ? renderLoading() : null }

            <Toast></Toast>

            <button
                disabled={submitDisabled}
                className={`btn btn-primary btn-fixed-bottom shadow ${ submitDisabled ? 'disabled' : ''}`}
                type="button"
                onClick={handleSubmit}
            >
                Update
            </button>

            <div className="card" data-card="Form" data-columns="1">
                <div className="card-header">
                    <h4>
                        Misc Services
                    </h4>             
                </div>
                <div className="card-body">
                    <div className="card-info">
                        <div>
                            { 
                                serviceExtraHeaders.map((serviceExtraHeader: IPropertyServiceExtrasHeader) => 
                                    renderServiceExtraSection(serviceExtraHeader)
                                )
                            }
                            { 
                                serviceCustomExtraData.map((serviceCustomExtraHeader: IPropertyServiceCustomExtra) => 
                                    renderServiceCustomExtraSection(serviceCustomExtraHeader)
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                serviceCategoryTypes.map((categoryType: IPropertyServiceCategoryType) =>
                    renderServiceCategoryTypeSection(categoryType)
                )
            }
        </>      
    );
};

export default PropertyServiceForm;