import React from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import PageTitle from '../../components/common/page_title';
import { IMenuItem, IMenuState } from '../../interfaces/menu';
import { ISessionProperty, ISessionState } from '../../interfaces/session';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import { get } from 'lodash';
import MobileSelectMenu from '../../components/menu/mobile_select_menu';
import BreadcrumbMenu from '../../components/menu/breadcrumb_menu';
import { findMenuItem } from '../../components/menu/common';

interface IProps {
    component: any;
    componentProps?: any;
    menuPath?: string;
    bannerComponent?: any;
}

const PageTemplate = ({component, componentProps, menuPath, bannerComponent}: IProps) => {
    const { Menu }: IMenuState = useSelector(
        ({ MenuState }: any) => MenuState
    );

    const { Session, Org, Properties }: ISessionState = useSelector(
        ({ SessionState }: any) => SessionState
    );

    let nhIDs: number[] | null;
    let orgNHID: number | null;

    if (Org) {
        orgNHID = Org.NHID;
        nhIDs = null;
    } else {
        if (Properties) {
            orgNHID = null;
            nhIDs = Properties.map((item: ISessionProperty) => item.NHID);
        }
    }

    const currentPage: IMenuItem = findMenuItem(Menu, menuPath, location.pathname);
    const canViewTestFeatures = get(Session, 'Perms.CanViewTestFeatures', false);

    if (!currentPage || (currentPage.IsTestFeature && !canViewTestFeatures)) {
        return;
    }
    
    const renderSupportLink = () => {
        return (
            <a 
                onClick={() => window.frames[0].document.getElementById('doc360-button').click()}
                className="fw-bold support-link"
            >
                Learn more in the Support Center
            </a>
        );
    };

    const renderMeta = () => {
        const title = `${currentPage.PageTitle} | NursingHomes.com Portal `;

        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ title }</title>
                <meta name="description" content={ title } />
            </Helmet>
        );
    };

    const renderComponent = (): any => {
        if (!Session) {
            return null;
        }

        const Component = component;

        const props = { 
            ...(componentProps || {}),
            nhIDs,
            orgNHID
        };

        return (
            <Component {...props}/>
        );
    };

    const renderBannerComponent = () => {
        if (!bannerComponent) {
            return null;
        }

        const BannerComponent = bannerComponent;

        const props = { 
            nhIDs,
            orgNHID
        };

        return (
            <div className="page-banner">
                <BannerComponent {...props} />
            </div>
        );
    };

    const renderComponentWithHeader = () => {
        const { HeaderTitle, HeaderText, SupportArticleButtonShow } = currentPage;

        return (
            <>
                <BreadcrumbMenu menuPath={menuPath} />
                <div className="kt-portlet">
                    { renderBannerComponent() }

                    <div className="kt-portlet__head">
                        <div className="d-flex justify-content-between">
                            <PageTitle title={HeaderTitle}/>
                            <div className="d-none d-md-block">
                                {
                                    currentPage?.Slug === 'review-score' ?
                                        <Link to="/review-score-simulator">
                                            <div className="btn btn-sm btn-primary mb-4" >
                                                Review Score Simulator
                                            </div>
                                        </Link>: null
                                }
                                {
                                    currentPage?.Slug === 'review-score-simulator' ?
                                        <Link to="/review-score">
                                            <div className="btn btn-sm btn-primary mb-4" >
                                                Review Score Breakdown
                                            </div>
                                        </Link>: null
                                }
                            </div>
                        </div>
                        <p className="m-0">
                            { HeaderText ? parse(HeaderText) : null }
                            { SupportArticleButtonShow ? renderSupportLink() : null }
                        </p>
                    </div>
                    <div className="kt-portlet__body">
                        { renderComponent() }
                    </div>
                </div> 
            </>
        );
    };

    return (
        <>
            <div id='page_template_top'>
                { !Session ? <LoadingSpinner show={true}/> : null }
                { renderMeta() }
                <div className="d-flex justify-content-center d-lg-none">
                    <MobileSelectMenu menuPath={menuPath} />
                </div>
                { currentPage.HeaderShow ? renderComponentWithHeader() : renderComponent() } 
            </div>
        </>
    );
};

export default PageTemplate;
 