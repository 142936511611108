import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '../../components/common/loading_spinner';
import UploadWidget from '../../components/cloudinary/upload_widget';
import * as assetApi from '../../api/assets';
import { 
    ICloudinaryData,
    IAssetUploadLog,
    NHAssetType,
    IAssetPayLoad,
    IPortalPropertyPhotoAsset,
    IPortalPropertyTagObj,
    IPortalPropertyPhotoObj,
    IPortalPropertyTagModalData
} from '../../interfaces/asset';
import { IMessageType } from '../../interfaces/common';
import { ITag } from '../../interfaces/tag';
import Swal from 'sweetalert2';
import { updateProfileCompleteness } from '../../utils/profile_completeness';

interface IProps {
    selectedNHID: number | null;
    propertyPhotos: IPortalPropertyPhotoAsset[];
    setPropertyPhotos: (photos: IPortalPropertyPhotoAsset[]) => void;
    showPhotoUploadForm: boolean;
    setShowPhotoUploadForm: (showForm: boolean) => void;
    photoTags: ITag[];
    busyLoadingPhotos: boolean;
    setBusyLoadingPhotos: (busyState: boolean) => void;
    setPhotoUploadMessages: (messages: IMessageType[]) => void;
}

const PhotoUploadForm = ({
    selectedNHID,
    propertyPhotos,
    setPropertyPhotos,
    showPhotoUploadForm,
    setShowPhotoUploadForm,
    photoTags,
    busyLoadingPhotos,
    setBusyLoadingPhotos,
    setPhotoUploadMessages
}: IProps) => {

    const [selectedTag, setSelectedTag] = useState<IPortalPropertyTagModalData>(null);
    const OthersTagID = 10;

    useEffect(() => {
        setSelectedTag(null);
    }, [showPhotoUploadForm]);

    const handleSelectedTag = (tag: IPortalPropertyTagModalData)  => {
        setSelectedTag(tag);
    };

    const handleAssetUpload = async(cloudinaryData: ICloudinaryData): Promise<any> => {
        const assetPayLoad: IAssetPayLoad = { NHID: selectedNHID, AssetType: NHAssetType.Photos, TagID:selectedTag.TagID, ...cloudinaryData };
        const assetID = await assetApi.createAsset(assetPayLoad);

        updateProfileCompleteness();

        return {
            AssetID: assetID,
            TransformedAssetUrl: cloudinaryData.TransformedAssetUrl,
            Sequence: cloudinaryData.Sequence,
            FileName: cloudinaryData.TransformedAssetUrl.match(/([^/]+)(?=\.\w+$)/)[0]
        };
    };
    
    const handleShowAssetUploadResult = (assetUploadLog : IAssetUploadLog[]) => {
        let hasPhotosUploaded = false;
        const photoObj:IPortalPropertyPhotoObj[] = [];
        const photoUploadMessages: IMessageType[] = [];
        for (const item of assetUploadLog) {
            if(item.Type === 'success') {
                hasPhotosUploaded = true;
                break;
            }
        }

        for (const item of assetUploadLog) {
            photoObj.push({
                AssetID: item.AssetDetail.AssetID,
                FileName: item.AssetDetail.FileName,
                PhotoSequence:  item.AssetDetail.Sequence,
                TagID: selectedTag.TagID,
                TagName: selectedTag.TagName,
                Title: null,
                TransformedAssetUrl: item.AssetDetail.TransformedAssetUrl
            });
        }

        if( !hasPhotosUploaded ) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error occured while uploading photos, please try again.'
            });
        } else {
            try {
                setBusyLoadingPhotos(true);
                const updatedPropertyPhotos: IPortalPropertyPhotoAsset[] = propertyPhotos.map((obj: IPortalPropertyPhotoAsset) => {
                    if(obj.NHID === selectedNHID) {
                        //if the destination tag not exists in the propert photos tag array
                        obj.Photo = obj.Photo.concat(photoObj);
                        const isDestinationTagExists = obj.Tag.find((currentTag:IPortalPropertyTagObj )=> currentTag.TagID === selectedTag.TagID);
                        if(!isDestinationTagExists) {
                            const tagObj:IPortalPropertyTagObj  = {
                                AssetTagID: 0,
                                TagID: selectedTag.TagID, 
                                TagName: selectedTag.TagName, 
                                TagSequence: selectedTag.TagSequence,
                                IsTagPublishWebsite: selectedTag.IsTagPublishWebsite,
                                IsTagPublishPortal: selectedTag.IsTagPublishWebsite,
                                IsTagPublishCMS: selectedTag.IsTagPublishCMS,
                                Photo: photoObj
                            };
                            let selectedTagIndex = obj.Tag.findIndex((tagItem) => selectedTag.TagSequence < tagItem.TagSequence);
                            selectedTagIndex = selectedTagIndex !==  -1 ? selectedTagIndex : selectedTag.TagSequence;
                            obj.Tag = [...obj.Tag.slice(0, selectedTagIndex), tagObj, ...obj.Tag.slice(selectedTagIndex)];
                            return obj;
                        }
                        obj.Tag = obj.Tag.filter((currentTag:IPortalPropertyTagObj) => {
                            if(currentTag.TagID === selectedTag.TagID && isDestinationTagExists) {
                                photoObj.forEach((photoItem:IPortalPropertyPhotoObj)=>currentTag.Photo.push(photoItem));
                                return currentTag;
                            } else {
                                return currentTag;
                            }
                        });
                        return obj;
                    }
                    return obj;
                });
                photoUploadMessages.push({type: 'info', message:'Your Photo(s) have successfully been uploaded, they will take up to 60 minutes to show on nursinghomes.com'});
                if(selectedTag.TagID === OthersTagID) {
                    photoUploadMessages.push({type: 'error', message:'Please change the tag of the Photos tagged with \'Other\', if there is a more relevant tag to use. This provides a much better user experience on the website'});
                }
                setShowPhotoUploadForm(false);
                setPropertyPhotos(updatedPropertyPhotos);
                setPhotoUploadMessages([]);
                setPhotoUploadMessages(photoUploadMessages);
                updateProfileCompleteness();
                
            } catch (err) {
                Swal.fire({
                    title: 'Failed to update tag for the photo',
                    text: 'Unknown error',
                    icon: 'error'
                });
            } finally {
                setBusyLoadingPhotos(false);
            }
        }
    };

    const renderPhotoUploadForm = () => {
        const property = propertyPhotos[0];
        return (
            <div className="card" data-card="Media Upload">
                <div className="card-header">
                    <h4>
                        Upload New Photos <small>(in 2 easy steps)</small>
                    </h4>
                </div>
                <div className="card-body">
                    <div className="card-info">
                        <ul>
                            <li>
                                <header>
                                    <span>
                                        1
                                    </span>
                                    <h4>
                                        Select a Relevant Tag
                                    </h4>
                                </header>
                                <div>
                                    <ul>
                                        {
                                           photoTags.map((tag:ITag) => {
                                                return (
                                                    tag.IsTagPublishWebsite === true ?
                                                        <li 
                                                            key={`tag-${tag.TagID}`}
                                                            className={
                                                                selectedTag?.TagID === tag.TagID ? 
                                                                    'selected' 
                                                                : 
                                                                    ''
                                                            } 
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleSelectedTag({
                                                                    TagID: tag.TagID,
                                                                    TagName: tag.TagName,
                                                                    TagSequence: 0,
                                                                    IsTagPublishWebsite: tag.IsTagPublishWebsite,
                                                                    IsTagPublishPortal: tag.IsTagPublishWebsite,
                                                                    IsTagPublishCMS:tag.IsTagPublishCMS,
                                                                    AssetID: 0
                                                                });
                                                            }}
                                                        >
                                                            {tag.TagName}
                                                        </li>
                                                    :
                                                        ''
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="msg msg-error">
                                    If you prefer to select the relevant tag(s) after uploading your photos, select the tag 'Other' and then change the tag after uploading. This is recommended when bulk uploading photos which require different tags.
                                </div>
                            </li>
                            <li className="mt-5">
                                <header>
                                    <span>
                                        2
                                    </span>
                                    <h4>
                                        Choose Photo(s) to Upload
                                    </h4>
                                </header>
                                <div>
                                    <div>
                                        <UploadWidget 
                                            nHID = {selectedNHID}
                                            fileName={property.Property.Slug}
                                            assetTypeName = 'Select Photos to Upload' 
                                            assetType = {NHAssetType.Photos}
                                            onAssetUpload = {handleAssetUpload}
                                            showAssetUploadResult = {handleShowAssetUploadResult}
                                            selectedTagID = {selectedTag?.TagID}
                                        />
                                    </div>
                                    <p>
                                        Please note only JPEG (jpeg/jpg), PNG, GIF &amp; WebP files accepted. Maximum file size 40mb.
                                    </p>
                                    <p>
                                        Large photo files can take up to 5 minutes to upload on slower connections. If in doubt please refresh the page after a couple of minutes.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="card-info-box">
                        <h5>
                            Uploading Photos - Tips &amp; Terms
                        </h5>
                        <p>
                            You can upload an unlimited number of Photos, we recommend you upload at least 1 photo for each tag that is applicable to this Facility.
                        </p>
                        <p>
                            We prefer that you do not upload stock photos, but if you do, please make sure that you have permission for the photos to be used on third party websites.
                        </p>
                        <p>
                            Please do not upload logos, award logos, accreditation certificates or any other images that are not photographs (including photos containing a prominent accreditation). Also please do not upload a photo with a logo, award logo, image or text overlaid on the photo.
                        </p>
                        <p>
                            NursingHomes.com reserve the right to remove any photos we deem unsuitable.
                        </p>
                        <p>
                            <strong>By pressing Upload button, I/we agree to the terms & conditions set out on <a href="https://www.nursinghomes.com/terms" target="_blank">www.nursinghomes.com/terms</a></strong>
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            { busyLoadingPhotos ? <LoadingSpinner show={true} /> : null }
            { showPhotoUploadForm ? renderPhotoUploadForm() : null }
        </>
    );
};

export default PhotoUploadForm;